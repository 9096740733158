import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { ElementNavigationModule } from 'shared/layout/element_navigation/element_navigation.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { GroupPortfolioEntryRoutingModule } from './group_portfolio-routing.module';
import { GroupPortfolioEntryComponent } from './group_portfolio.component';

@NgModule({
  imports: [
    CommonModule,
    GroupPortfolioEntryRoutingModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    AttachmentsModule,
    ElementNavigationModule
  ],
  exports: [
    GroupPortfolioEntryComponent
  ],
  declarations: [GroupPortfolioEntryComponent]
})
export class GroupPortfolioEntryModule { }
