<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-left">
    <div class="searchbox" *ngIf="module?.search && (categories || magazines)">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <ng-container *ngIf="categories?.length">
      <div class="menu">
        <div class="menu">
          <div class="head">
            <div translate>newspaper.title</div>
          </div>
          <a *ngFor="let cat of categories | filter : searchText"
             [routerLink]="['/g', globals.getCode(), 'newspaper_category', cat.toLowerCase()]">
            <div [innerHtml]="cat"></div>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="magazines?.length">
      <div class="magazine" *ngFor="let mag of magazines | filter : searchText" oncontextmenu="return false;">
        <div class="name">{{mag.magazine}}</div>
        <img [src]="mag.cover_url" [routerLink]="['/g', globals.getCode(), 'newspaper', mag.id_magazine_publication]" [alt]="mag.magazine" *ngIf="!mag.file_url">
        <img [src]="mag.cover_url" (click)="open(mag.file_url)" [alt]="mag.magazine" *ngIf="mag.file_url">
      </div>
    </ng-container>
  </div>
</div>

<app-backlink></app-backlink>
