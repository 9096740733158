import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-legal',
  templateUrl: './legal.component.html'
})
export class WidgetLegalComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  business: Business;
  version: string;

  constructor(public globals: Globals,
              public businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.version = environment.version;
    });
  }

}
