import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { MenuModule } from 'common/menu/menu.module';
import { NavbarModule } from 'common/navbar/navbar.module';
import { ConfirmModule } from '../../confirm/confirm.module';
import { PmsBaseDirective } from '../base/base.directive';
import { PmsCiCoBaseDirective } from '../base/cico_base.directive';
import { PmsCheckInConfirmModule } from '../shared/confirm/confirm.module';
import { PmsFoliosModule } from '../shared/folios/folios.module';
import { PmsFooterModule } from '../shared/footer/footer.module';
import { PmsLoginModule } from '../shared/login/login.module';
import { PmsOverlayModule } from '../shared/overlay/overlay.module';
import { PmsSignatureModule } from '../shared/signature/signature.module';
import { PmsSimpleFooterModule } from '../shared/simple-footer/simple-footer.module';
import { StartModule } from '../shared/start/start.module';
import { PmsCheckInRoutingModule } from './check_in-routing.module';
import { PmsCheckInComponent } from './check_in.component';
import { PmsCiGeneralModule } from './general/general.module';
import { PmsTravelInfoModule } from './travel_info/travel_info.module';
import { StepsIndicatorModule } from '../shared/steps-indicator/steps-indicator.module';
import { MainTitleModule } from '../shared/main-title/main-title.module';
import { GuestIndexModule } from '../shared/guest-index/guest-index.module';
import { InfoScreenModule } from '../shared/info-screen/info-screen.module';
import { SuccessScreenModule } from '../shared/success-screen/success-screen.module';
import { TypographyModule } from 'common/typography/typography.module';

@NgModule({
  imports: [
    CommonModule,
    StartModule,
    PmsCheckInRoutingModule,
    ConfirmModule,
    PmsCiGeneralModule,
    MatStepperModule,
    PmsFoliosModule,
    PmsCheckInConfirmModule,
    PmsFooterModule,
    PmsSimpleFooterModule,
    PmsOverlayModule,
    LoaderModule,
    PmsLoginModule,
    PmsTravelInfoModule,
    PmsSignatureModule,
    NavbarModule,
    MenuModule,
    TranslateModule,
    StepsIndicatorModule,
    MainTitleModule,
    GuestIndexModule,
    InfoScreenModule,
    SuccessScreenModule,
    TypographyModule
  ],
  exports: [
    PmsCheckInComponent
  ],
  declarations: [PmsCheckInComponent],
  providers: [
    PmsBaseDirective,
    PmsCiCoBaseDirective
  ]
})
export class PmsCheckInModule {}
