import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-upsell-gallery',
  templateUrl: './upsell-gallery.component.html',
  styleUrls: ['./upsell-gallery.component.scss']
})
export class UpsellGalleryComponent {
  @Input() currentPrice = 0;
  @Input() upsellItems: any[] = [];
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(public cicoService: PmsCiCoService) {
  }

  selectItem(item: any) {
    this.itemSelected.emit(item);
  }
}
