import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { GroupBusinessService } from '../group_business.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-widget-group-business-item',
  templateUrl: './group_business_item.component.html',
  styleUrls: ['./group_business_item.component.scss']
})

@Injectable()
export class WidgetGroupBusinessItemComponent implements OnInit {
  business: any;

  constructor(public globals: Globals,
              private groupBusinessService: GroupBusinessService,
              private modalService: ModalService) { }


  ngOnInit(): void {
    this.groupBusinessService.groupBusiness.pipe(take(1)).subscribe(business => {
      this.business = business;
    });
  }

  openUrl(url) {
    this.globals.openUrl(url);
    this.close();
  }

  close() {
    this.modalService.close();
  }

}
