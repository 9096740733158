import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventModuleService } from '../../event.service';

@Injectable({providedIn: 'root'})
export class CalendarService {
  calendarSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  calendar: Observable<any> = this.calendarSubj.asObservable();

  constructor(private eventService: EventModuleService) { }

  getCalendar(id): Observable<Object> {
    return this.eventService.getEntry(id);
  }
}
