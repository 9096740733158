import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetSuggestionsModule } from 'widgets/suggestions/suggestions.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { WeatherRoutingModule } from './weather-routing.module';
import { WeatherComponent } from './weather.component';

@NgModule({
  imports: [
    CommonModule,
    WeatherRoutingModule,
    ModuleTitleModule,
    WidgetSuggestionsModule
  ],
  exports: [
    WeatherComponent
  ],
  declarations: [WeatherComponent]
})
export class WeatherModule { }
