export class MapProtection {
  cookie: boolean;
  setting: boolean;
  background: string;

  constructor(raw_json: any) {
    this.cookie = raw_json?.cookie;
    this.setting = raw_json?.setting;
    this.background = raw_json?.background || '/assets/images/static_map.jpg';
  }

  unprotected(): boolean {
    return !this.cookie && !this.setting;
  }
}
