import { ApplicationRef, ComponentRef, Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { AppComponent } from '../../app.component';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[tooltipInfo]'
})
export class TooltipDirective {

  @Input() tooltipInfo = '';
  private componentRef: ComponentRef<any> = null;

  constructor(
    private viewRef: ViewContainerRef,
    private elementRef: ElementRef,
    private appRef: ApplicationRef
  ) {}

  @HostListener('click')
  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.componentRef === null) {
      this.viewRef = (this.appRef.components[0].instance as AppComponent).viewRef;
      this.componentRef = this.viewRef.createComponent(TooltipComponent);
      document.getElementById('tooltip-wrapper').addEventListener('mouseleave', (e) => this.onMouseLeave(e));
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltipInfo;
      const {left, right, top} = this.elementRef.nativeElement.getBoundingClientRect();
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = top + 7;
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e): void {
    if (e.relatedTarget?.id !== 'tooltip-wrapper' && e.relatedTarget?.id !== 'tooltip' && e.relatedTarget !== this.elementRef.nativeElement) {
      this.destroy();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(_event) {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

}
