import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherModule } from 'modules/weather/weather.module';
import { WidgetWeatherRoutingModule } from './weather-routing.module';
import { WidgetWeatherComponent } from './weather.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetWeatherRoutingModule,
    WeatherModule
  ],
  exports: [
    WidgetWeatherComponent
  ],
  declarations: [WidgetWeatherComponent]
})
export class WidgetWeatherModule { }
