import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-verification-box-group',
  templateUrl: './verification-box-group.component.html',
  styleUrls: ['./verification-box-group.component.scss']
  })

export class VerificationBoxGroupComponent implements OnInit {

  @Input() options: { text: string, icon: string, type: string }[];
  @Input('ngModel') selItem: boolean = Boolean(false);
  @Output('ngModelChange') selItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  selectionChange(option: any) {
    this.selItem = option;
    this.selItemChange.emit(this.selItem);
  }

  isSelected(option): boolean {
    return option === this.selItem;
  }
}
