<div class="camera-container animation-out">
  <div *ngIf="imageUpload || qr" class="video-wrapper">
    <video [hidden]="hideVideo" playsInline muted></video>
    <canvas [hidden]="hideCapture"></canvas>
    <button *ngIf="loadingFinished" class="close-button" (click)="scanAbort()"><i class="fas fa-xmark"></i></button>
    <button *ngIf="loadingFinished && imageUpload && !captured" class="capture-button" (click)="capture()"><i class="fas fa-camera"></i></button>
    <button *ngIf="loadingFinished && !captured && availableDevices?.length > 1" class="change-camera-button" [disabled]="cameraChanging" (click)="switchCamera()"><i class="fas fa-rotate"></i></button>
  </div>

  <ng-template #qr>
    <div [class.hidden]="!cameraSet">
      <zxing-scanner (scanSuccess)="onScanSuccess($event)" (camerasNotFound)="noCamera()" (autostarted)="useDefinedCamera()" (camerasFound)="onCamerasFound($event)" [(device)]="currentDevice"></zxing-scanner>
      <button *ngIf="loadingFinished" class="close-button" (click)="scanAbort()"><i class="fas fa-xmark"></i></button>
      <button *ngIf="loadingFinished && !captured && availableDevices?.length > 1" class="change-camera-button" [disabled]="cameraChanging" (click)="switchCamera()"><i class="fas fa-rotate"></i></button>
    </div>
  </ng-template>

  <div *ngIf="!loadingFinished" class="spinner">
    <i class="fas fa-spinner"></i>
  </div>
</div>
