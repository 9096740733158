<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="module.medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <div class="searchbox" *ngIf="module?.search && entries">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <div class="menu entries" *ngIf="entries?.length">
      <a *ngFor="let item of entries | filter : searchText" [routerLink]="['/g', globals.getCode(), 'wifi', item.id]">
        <div>{{item.ssid}}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
  </div>
</div>
