<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="module.medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left" *ngIf="entries?.length">

    <div class="searchbox" *ngIf="module?.search && entries">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <div class="menu" *ngFor="let month of entries">
      <div class="head">
        <div><i class="far fa-calendar-alt"></i></div>
        <div [innerHtml]="month.title"></div>
        <div></div>
      </div>
      <a *ngFor="let item of month.entries | filter : searchText" [routerLink]="['/g', globals.getCode(), 'event', item.id]">
        <div><span [innerHtml]="item.day"></span> <small [innerHtml]="item.weekday"></small></div>
        <div class="two-lines">
          <i class="ad-icon" *ngIf="item.advert"></i>{{item.name}}
          <small>{{item.dates.from}} - {{item.dates.till}} | {{item.location}}</small>
        </div>
        <div><i class="fas fa-angle-right icon-next"></i></div>
      </a>
    </div>

  </div>
</div>
