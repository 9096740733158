import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetPushRoutingModule } from './push-routing.module';
import { WidgetPushComponent } from './push.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetPushRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetPushComponent
  ],
  declarations: [WidgetPushComponent]
})
export class WidgetPushModule { }
