import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[lengthValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: LengthValidator,
      multi: true
    }
  ]
})

export class LengthValidator {
  validator: ValidatorFn;

  @Input('lengthValidator') options: any;

  constructor() {
    this.validator = this.LengthValidator();
  }

  validate(c: FormControl) {
    if (!this.options.disabled) {
      return this.validator(c);
    }
  }

  LengthValidator(): ValidatorFn {
    return (c: FormControl) => {

      const min = this.options.min;
      const max = this.options.max;

      if (c.value) {
        const minValid = (typeof min === 'undefined' || c.value.length >= min);
        const maxValid = (typeof max === 'undefined' || c.value.length - 1 <= max);

        if (minValid && maxValid) {
          return null;
        } else {
          return {
            LengthValidator: {min: !minValid, max: !maxValid}
          };
        }
      }
    };
  }
}
