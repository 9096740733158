import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Business } from 'models/business';
import { CookieSetting } from 'models/cookie_setting';

import { Guest } from 'models/guest';
import { Place } from 'models/place';
import { ApiService } from 'api_service';
import { BusinessService } from './business.service';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';
import { StyleService } from './style.service';

@Injectable({providedIn: 'root'})
export class GuestService {
  guest: Guest;
  place: Place;

  private subject: BehaviorSubject<Guest> = new BehaviorSubject<Guest>(null);
  currentGuest: Observable<Guest> = this.subject.asObservable();

  private placeSubject: BehaviorSubject<Place> = new BehaviorSubject<Place>(null);
  currentPlace: Observable<Place> = this.placeSubject.asObservable();

  cookieSubj: BehaviorSubject<any> = new BehaviorSubject<CookieSetting>(null);
  cookie: Observable<any> = this.cookieSubj.asObservable();

  stepsSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  steps: Observable<any> = this.stepsSubj.asObservable();

  loginRequiredSubj: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  loginRequired: Observable<any> = this.loginRequiredSubj.asObservable();

  url: string;

  constructor(private api: ApiService,
              private businessService: BusinessService,
              private cookieService: CookieService,
              private routerService: RouterService,
              private styleService: StyleService,
              private _guest: Guest,
              private storageService: StorageService) {

    this.routerService.getCurrentCode().subscribe(code => {
      if (code) {
        this.storageService.getItem('current_guest', code).then(guest => {
          this.guest = this._guest;
          this.guest.setGuest(guest);
          this.subject.next(this.guest);
        }).catch(() => {});
      }
    });
  }

  setGuest(guest: Guest) {
    this.guest = guest;
    this.subject.next(guest);
    this.loginRequiredSubj.next(this.guest.place.cico_login);
  }

  setEmail(email: string) {
    this.update('email', email);
  }

  setName(name: string) {
    this.update('name', name);
  }

  setSmartFeedback() {
    this.update('smart_feedback', false);
  }

  setPlace(data: any) {
    const place = new Place(data?.place || data);
    if (!this.guest.place.equal(place)) {
      this.update('place', place);
      this.styleService.loadManifest(this.guest);
      this.subject.next(this.guest);
      this.placeSubject.next(this.guest.place);
    }
    this.loginRequiredSubj.next(this.guest.place.cico_login);
  }

  setJourney(data: any) {
    const place = this.guest.place;
    if (place.number !== data.number || place.journey !== data.journey) {
      this.guest.place.number = data.number;
      this.guest.place.journey = data.journey;
      this.guest.save();
      this.subject.next(this.guest);
    }
  }

  getEmail() {
    return this.guest.email;
  }

  getName() {
    return this.guest.name;
  }

  getToken() {
    return this.guest && this.guest.token ? this.guest.token : undefined;
  }

  getCode() {
    return this.guest && this.guest.code ? this.guest.code : '';
  }

  getLocale(): string {
    return this.guest ? this.guest.locale : 'en';
  }

  setLocale(lang: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.api.get('languages/' + lang + '/edit').subscribe((data: any) => {
        const locale = data.locale;
        this.guest.locale = locale;
        this.guest.save();
        resolve(locale);
      }, () => {
        resolve(this.guest.locale);
      });
    });
  }

  redirectFallback(url, params, cryptcode = null) {
    const urlCryptcode = this.getCryptcode(url);
    cryptcode = urlCryptcode ? urlCryptcode : cryptcode;
    window.location.href = `/${cryptcode}${params}`;
  }

  getCryptcode(url): string {
    const old_kiosk = url.match(/\/g\/[a-z]{4}\/pms_kiosk\/(mobile|terminal|hardware_terminal)\/([a-z0-9]{6,})/);
    const new_kiosk = url.match(/\/g\/[a-z]{4}\/pms_kiosk\/([a-z0-9]{6,})/);
    if (old_kiosk && old_kiosk[2]) {
      return old_kiosk[2];
    } else if (new_kiosk && new_kiosk[1]) {
      return new_kiosk[1];
    }
  }

  getCookies(): CookieSetting {
    const rawCookie = this.cookieService.get(environment.cookieKey);
    if (rawCookie.length) {
      this.guest.cookies = new CookieSetting(JSON.parse(rawCookie));
      this.guest.save();
    }
    return this.guest.cookies;
  }

  setCookies(setting: any) {
    this.businessService.current_business.pipe(filter(Boolean), take(1)).subscribe((business: Business) => {
      if (!this.guest.cookies) {
        this.guest.cookies = new CookieSetting({});
      }
      for (const [key, value] of Object.entries(setting)) {
        this.guest.cookies[key] = value;
      }
      this.guest.cookies.timestamp = Date.now();
      this.guest.save();
      this.cookieSubj.next(this.guest.cookies);
      this.cookieService.set(environment.cookieKey, JSON.stringify(this.guest.cookies), 365, '/g/' + business.code);
    });
  }

  update(field: string, value: any) {
    this.guest[field] = value;
    this.guest.save();
  }
}
