import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginProvider } from '../interfaces/LoginProvider';
import { SocialUser } from '../models/SocialUser';

export interface AuthItem {
  id: string;
  provider: LoginProvider;
}

export class ServiceConfig {
  providers: Map<string, LoginProvider> = new Map<string, LoginProvider>();
  autoLogin: boolean;

  constructor(providers: AuthItem[], autoLogin: boolean) {
    this.autoLogin = autoLogin;
    for (let i = 0; i < providers.length; i++) {
      const element = providers[i];
      this.providers.set(element.id, element.provider);
    }
  }
}

@Injectable({providedIn: 'root'})
export class AuthService {
  private static readonly LOGIN_PROVIDER_NOT_FOUND = 'Login payment not found';
  private providers: Map<string, LoginProvider>;
  private _authState: BehaviorSubject<SocialUser> = new BehaviorSubject(null);
  private _user: SocialUser = null;

  get authState(): Observable<SocialUser> {
    return this._authState.asObservable();
  }

  constructor(config: ServiceConfig) {
    this.providers = config.providers;
    if (config.autoLogin) {
      this.providers.forEach((provider: LoginProvider, key: string) => {
        if (provider) {
          provider.initialize().then((user: SocialUser) => {
            user.provider = key;
            this._user = user;
            this._authState.next(user);
          }).catch(() => {
            // this._authState.next(null);
          });
        }
      });
    }
  }

  signIn(providerId: string): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        if (providerObject.isInitialize) {
          providerObject.signIn().then((user: SocialUser) => {
            user.provider = providerId;
            resolve(user);

            this._user = user;
            this._authState.next(user);
          }).catch(() => {});
        } else {
          providerObject.initialize().then((user: SocialUser) => {
            if (user) {
              user.provider = providerId;
              resolve(user);
              this._authState.next(user);
            } else {
              providerObject.signIn().then((_user: SocialUser) => {
                _user.provider = providerId;
                resolve(_user);
                this._user = _user;
                this._authState.next(_user);
              }).catch(() => {});
            }
          }).catch(() => {
            // this._authState.next(null);
          });
        }
      } else {
        reject(AuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  signOut(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this._user?.provider) {
        const providerId = this._user.provider;
        const providerObject = this.providers.get(providerId);
        providerObject.signOut().then(() => {
          this._user = null;
          this._authState.next(null);
          resolve();
        }).catch(() => {
          this._authState.next(null);
        });
      } else {
        reject(AuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  shareMessage(providerId: string, url: string, icon?: string): Promise<void> {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        if (providerObject.isInitialize) {
          providerObject.isLoggedIn().then(loggedIn => {
            if (loggedIn) {
              providerObject.shareMessage(url, icon).then(() => {
                resolve();
              }).catch(() => {});
            } else {
              providerObject.signIn().then(() => {
                providerObject.isLoggedIn().then(_loggedIn => {
                  if (_loggedIn) {
                    providerObject.shareMessage(url, icon).then(() => {
                      resolve();
                    }).catch(() => {});
                  }
                }).catch(() => {});
              }).catch(() => {});
            }
          }).catch(() => {});
        } else {
          providerObject.initialize().then(() => {
            self.shareMessage(providerId, url, icon);
          }).catch(() => {
            // this._authState.next(null);
          });
        }
      } else {
        reject(AuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  getUser(providerId: string): Promise<SocialUser> {
    return new Promise((resolve, _reject) => {
      const providerObject = this.providers.get(providerId);
      providerObject.getUser().then(user => {
        resolve(user);
      }).catch(() => {});
    });
  }
}
