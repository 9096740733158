import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-country-box-group',
  templateUrl: './country-box-group.component.html',
  styleUrls: ['./country-box-group.component.scss']
})
export class CountryBoxGroupComponent implements OnInit {

  @Input() options: any [];
  @Input('ngModel') selOption: any;
  @Input() model: any;
  @Input() key: string;
  @Input() name: string;
  @Input() label: string;
  @Input() errorLabel: string;
  @Input() text: string;
  @Input() group = 'category';
  @Input() required = false;
  @Input() showIcons = false;
  @Input() submited: boolean;
  @Input() authRequired: string;

  @Output() optionValue = new EventEmitter();
  @Output('ngModelChange') selOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('allCountries', {static: true}) allCountriesElement: ElementRef;

  allVisible = false;
  top5Countries: any[] = [];
  allCountries: any[] = [];
  searchValue: string;
  idnow: boolean;
  settings: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    public cicoService: PmsCiCoService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.top5Countries = this.options.slice(0, 5);
    this.top5Countries.push({name: this.translateService.instant('misc.more'), flag: null});
    this.allCountries = this.options.slice(0, 9);

    if (!this.errorLabel) {
      this.errorLabel = 'validation.required';
    }
    if (!this.key) {
      this.key = this.name;
    }

    if (this.model[this.key]) {
      const foundObject = this.options.find(obj => obj.value === this.model[this.key]);
      if (!this.top5Countries.includes(foundObject)) {
        this.top5Countries.splice(4, 1, foundObject);
      }
    }

    this.cdRef.detectChanges();
  }

  selectionChange(option: any) {
    this.selOption = option;
    this.model[this.key] = this.selOption.value;
    this.cicoService.setPassportVisa();

    if (option.flag) {
      this.allVisible = false;
    } else {
      this.allVisible = true;
      this.searchValue = '';
      this.searchValueChange(this.searchValue);
    }

    if (!this.top5Countries.includes(option)) {
      this.top5Countries.splice(4, 1, option);
    }

    this.cdRef.detectChanges();
    this.optionValue.emit(this.model[this.key]);
  }

  isSelected(option: any): boolean {
    if (!this.allVisible && !option?.value) {
      return false;
    }
    return this.model[this.key] === option.value;
  }

  valueChange() {
    this.cdRef.detectChanges();
    this.optionValue.emit(this.model[this.key]);
  }

  searchValueChange(value) {
    this.allCountries = this.cicoService.orderCountriesInSearch(this.options, this.searchValue);
  }

  scroll() {
    const iconElements = this.elementRef.nativeElement.querySelectorAll('.icon-wrapper');

    if (iconElements.length >= 1) {
      const secondIconElement = iconElements[0];
      const rect = secondIconElement.getBoundingClientRect();
      const targetScrollPosition = window.scrollY + rect.top - 180;

      window.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth'
      });
    }
  }
}
