<app-module-title [module]="module" [hideDescription]="true"></app-module-title>

<div class="container">
  <div class="container-left">

    <div class="searchbox" *ngIf="module?.search && menu_items">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <ng-container *ngIf="menu_items?.length">
      <div class="menu">
        <div class="menu">
          <div class="head">
            <div translate>misc.entries</div>
          </div>
          <a *ngFor="let item of menu_items | filter : searchText" (click)="open(item.link)" class="link">
            <div [innerHtml]="item.name"></div>
          </a>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<app-backlink></app-backlink>
