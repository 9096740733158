import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { ComplaintModuleService } from './complaint.service';

@Component({
  selector: 'app-complaint-module',
  templateUrl: './complaint.component.html'
})

export class ComplaintComponent implements OnInit {
  module: Module;
  cart: string[];

  constructor(private menuService: ComplaintModuleService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('complaint', false).then((mod) => {this.module = mod; }).catch(() => {});
  }

}
