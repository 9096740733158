import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MainTitleRoutingModule } from './main-title-routing.module';
import { MainTitleComponent } from './main-title.component';

@NgModule({
  declarations: [
    MainTitleComponent,
  ],
  exports: [
    MainTitleComponent
  ],
  imports: [
    CommonModule,
    MainTitleRoutingModule,
    TranslateModule
  ]
})
export class MainTitleModule {}
