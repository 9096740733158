import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-restriction',
  templateUrl: './restriction.component.html'
})
export class RestrictionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;

  constructor(private globals: Globals,
              private businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
