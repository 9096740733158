import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipeRoutingModule } from './filter-routing.pipe.module';
import { FilterPipe } from './filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FilterPipeRoutingModule
  ],
  exports: [
    FilterPipe
  ],
  declarations: [FilterPipe]
})
export class FilterPipeModule { }
