import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class WifiService {

  constructor(private api: ApiService) {}

  getWifi(id): Observable<Object> {
    if (id === undefined) {
      id = '';
    }
    return this.api.get('wifi/' + id);
  }

}
