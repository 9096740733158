import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BacklinkRoutingModule } from './backlink-routing.module';
import { BacklinkComponent } from './backlink.component';

@NgModule({
  imports: [
    CommonModule,
    BacklinkRoutingModule,
    TranslateModule
  ],
  exports: [
    BacklinkComponent
  ],
  declarations: [BacklinkComponent]
})
export class BacklinkModule { }
