import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { interval, Observable, Subscription } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { A2hsService } from 'services/a2hs.service';
import { BusinessService } from 'services/business.service';
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  open: boolean;
  items: any;
  item: any;

  constructor(private api: ApiService,
              public globals: Globals,
              private footerService: FooterService,
              private businessService: BusinessService,
              private a2hsService: A2hsService) {}

  ngOnInit() {
    this.subscriptions.add(this.footerService.openObservable.subscribe((open: boolean) => {
      this.subscriptions.add(interval(25).pipe(takeWhile(() => document.getElementById('navigation') !== null), take(1)).subscribe(() => {
        const navigation = document.getElementById('navigation');
        this.open = open;
        open ? navigation.classList.add('open') : navigation.classList.remove('open');
        window.scrollTo(0, 0);
      }));
    }));

    this.subscriptions.add(this.businessService.currentLocale.subscribe(() => {
      this.getFooterItem().subscribe((success: any) => {
        this.items = success.items;
        this.changeFooter();
      }, () => {});
    }));
  }

  execute(item) {
    if (item['module'] === 'app') {
      this.getApp();
    } else if (item['type'] === 'module') {
      this.globals.navigate(item['module'], 'replaced');
    }
  }

  getApp() {
    this.a2hsService.openModal();
  }

  toggleMenu() {
    this.footerService.openSubj.next(!this.open);
  }

  getFooterItem(): Observable<Object> {
    return this.api.get('footer');
  }

  changeFooter() {
    if (this.items) {
      if (this.items.length > 1) {
        this.item = this.showPWA(this.a2hsService.installableSubject.value);
        this.subscriptions.add(this.a2hsService.installable.subscribe((installable) => {
          this.item = this.showPWA(installable);
        }));
      } else {
        this.item = this.items[0];
      }
    }
  }

  showPWA(show: boolean): boolean {
    return show ? this.items[0] : this.items[1];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

