import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberValidator } from './number.validator';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NumberValidator
  ],
  declarations: [NumberValidator]
})
export class NumberValidatorModule { }
