<div class="input-container button-group">
  <app-description-label [description]="description" [tooltip]="tooltip" [required]="required"></app-description-label>
  <div class="buttons-container">
    <button *ngFor="let option of options" (click)="selectOption(option[1])" [class.main-bg]="isOptionSelected(option[1])" [disabled]="disabled" type="button">
      {{ option[0] | translate }}
    </button>
  </div>

  <label class="error-label" [class.visible]="(submitted && (selectedOption === undefined ||selectedOption === null)) && required">
    <i class="fa-solid fa-circle-xmark"></i> <span>{{ errorMsg ? errorMsg : 'validation.required' | translate }}</span>
  </label>
</div>
