import { Component, Input } from '@angular/core';
import { Business } from 'models/business';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-upsell-item',
  templateUrl: './upsell-item.component.html',
  styleUrls: ['./upsell-item.component.scss']
})
export class UpsellItemComponent {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() price: string | 0;
  @Input() currency: any;
  @Input() currentPrice = 0;
  @Input() selected: boolean;
  business: Business;

  constructor(private businessService: BusinessService) {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }
}
