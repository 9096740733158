<ng-container [ngSwitch]="display">
  <ng-container *ngSwitchCase="'desktop'">
    <input matInput type="text"
        [matDatepicker]="id"
        id = "datepicker"
        [placeholder]="placeholder"
        [max]="maxDate"
        [min]="minDate"
        [name]="key + '_' + name"
        [value]="value"
        [(ngModel)]="model[key]"
        [disabled]="disabled"
        [required]="required"
        (click)="id.open()"
        (change)="test($event)"
        (blur)="remove_timestamp()"
        [datevalidator]="{ min: minDate, max: maxDate, required: required }"
        #date="ngModel"
        datevalidator>
    <mat-datepicker-toggle matSuffix [for]="id"></mat-datepicker-toggle>
    <mat-datepicker [startView]="startView" #id></mat-datepicker>
  </ng-container>

  <ng-container *ngSwitchCase="'mobile'">
    <input id="mobile_datepicker"
        type="date"
        [name]="key + '_' + name"
        [max]="maxDate"
        [min]="minDate"
        [disabled]="disabled"
        [required]="required"
        [(ngModel)]="model[key]"
        [ngModel]="model[key] | date: 'yyyy-MM-dd'"
        (blur)="validate(unavailableDays)"
        [datevalidator]="{ min: minDate, max: maxDate, required: required }"
        #date="ngModel"
        datevalidator>
  </ng-container>

  <ng-container *ngSwitchCase="'picker'">
    <div *ngIf="start_with_day">
      <span translate>misc.day</span><span translate>misc.month</span><span translate>misc.year</span>
    </div>
    <div *ngIf="!start_with_day">
      <span translate>misc.year</span><span translate>misc.month</span><span translate>misc.day</span>
    </div>
    <input [id]="name"
        type="text"
        [placeholder]="placeholder || helper || model[key]"
        [disabled]="disabled"
        [required]="required"
        [max]="maxDate"
        [min]="minDate"
        [name]="key + '_' + name"
        [ngModel]="helper" (ngModelChange)="remover($event)"
        (change)="change($event)"
        (blur)="blur()"
        [datevalidator]="{min: minDate, max: maxDate, required: required}"
        #date="ngModel"
        datevalidator/>
  </ng-container>
</ng-container>

<label translate class="error">{{errorLabel}}</label>
<label class="error" *ngIf="hint">{{'validation.date_format' | translate: {placeholder: placeholder} }}</label>
