import { Component, OnInit, KeyValueDiffers, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Business } from 'models/business';
import { Field } from 'models/field';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { PmsModType } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';
import { PmsReservation } from 'models/pms/pms_reservation';
import { PmsPaymentService } from '../shared/payment/payment.service';

@Component({
  selector: 'app-pms-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})

export class PmsReservationComponent extends PmsCiCoBaseDirective implements OnInit, OnDestroy {

  @Input() hideLogout = false;
  @Input() hideActions = false;

  subscriptions: Subscription = new Subscription();
  module: Module;
  business: Business;
  res: PmsReservation;
  medias: [];
  settings: any;
  all_reservations: any;
  booking_data: any;
  currentReservation: string;
  lastReservation: boolean;
  index = 0;
  hasFolios: boolean;
  translate: boolean;
  payment_possible = true;

  swiperConfig: any = {
    spaceBetween: 10,
    mousewheel: true,
    initialSlide: this.index,
    centeredSlides: false,
    preventClicks: true,
    slideToClickedSlide: true,
    pagination: false,
    breakpoints: {
      // when window width is >= 480px
      300: {slidesPerView: 1.2},
      274: {slidesPerView: 1.1},
      360: {slidesPerView: 1.35},
      400: {slidesPerView: 1.6},
      480: {slidesPerView: 1.7},
      500: {slidesPerView: 1.9},
      545: {slidesPerView: 1.9},
      600: {slidesPerView: 2},
      630: {slidesPerView: 2},
      670: {slidesPerView: 2},
      690: {slidesPerView: 2.5},
      705: {slidesPerView: 2.5},
      714: {slidesPerView: 1.8},
      1080: {slidesPerView: 2.3},
      1280: {slidesPerView: 2.7},
    }
  };

  constructor(public globals: Globals,
              public cicoService: PmsCiCoService,
              private pmsService: PmsService,
              protected guestService: GuestService,
              protected differs: KeyValueDiffers,
              protected storageService: StorageService,
              protected route: ActivatedRoute,
              protected paymentService: PmsPaymentService
  ) {
    super(cicoService, guestService, differs, storageService, globals, null, paymentService, route);
  }

  ngOnInit() {
    super.ngOnInit(PmsModType.reservation);
    this.cicoService.logUnload = false;

    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean)).subscribe((data: GenericData) => {
      this.data = data;
      this.business = this.data.business;
      this.res = this.data.incident.reservation;
      this.module = this.data.module;

      this.medias = this.res?.medias;
      this.settings = this.module?.settings;
      this.all_reservations = this.res?.all_reservations;

      this.booking_data = this.res?.booking_data;
      this.payment_possible = this.settings.payment && !this.business?.usePms(['infor']);
      this.hasFolios = true;

      this.setIndex();
      this.lastReservation = this.all_reservations?.slice(-1)[0]?.reservation_id === this.res.reservation_id;
    }));
  }

  getReservation(uuid) {
    if (this.currentReservation !== uuid || this.data?.incident?.reservation?.uuid !== uuid) {
      this.currentReservation = uuid;
      this.cicoService.fetchData(uuid, PmsModType.reservation);
    }
  }

  reservationChanged(uuid: any) {
    this.hasFolios = false;
    this.subscriptions.add(this.pmsService.changeReservation(uuid).subscribe(() => {
      this.getReservation(uuid);
      this.setIndex();
    }, () => { }));
  }

  setIndex() {
    const index = this.all_reservations?.findIndex(res => res.reservation_id === this.res.reservation_id);
    if (this.index !== index) {
      this.index = index;
    }
  }

  logout() {
    this.pmsService.logout().subscribe(() => {
      if (!this.globals.place.wizard) {
        this.globals.navigate('home').then(() => { this.globals.navigate('pms_reservation'); });
      } else {
        this.loginRequired = true;
        this.data = null;
        this.uuid = null;
        this.cicoService.dataSubj.next(null);
        this.cicoService.disableNextButton(false);
        this.guestService.loginRequiredSubj.next(true);
        window.scrollTo(0, 0);
      }
    }, () => { });
  }

  field_for(kind): Field {
    return this.cicoService.field_for(kind);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
