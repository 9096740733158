import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetSuggestionsRoutingModule } from './suggestions-routing.module';
import { WidgetSuggestionsComponent } from './suggestions.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetSuggestionsRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetSuggestionsComponent
  ],
  declarations: [WidgetSuggestionsComponent]
})
export class WidgetSuggestionsModule { }
