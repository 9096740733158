import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';


@Component({
  selector: 'app-goldkey',
  templateUrl: './goldkey.component.html'
})
export class GoldkeyComponent implements OnInit {
  module: Module;

  constructor(private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('newspaper', false).then((mod) => {this.module = mod; }).catch(() => {});
  }
}
