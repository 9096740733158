import { Component, OnDestroy, OnInit } from '@angular/core';
import { Business } from 'models/business';
import { CookieSetting } from 'models/cookie_setting';
import { Guest } from 'models/guest';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-cookie-info',
  styleUrls: ['cookie_info.component.scss'],
  templateUrl: './cookie_info.component.html'
})

export class CookieInfoComponent implements OnDestroy, OnInit {
  subscriptions: Subscription = new Subscription();
  business: Business;
  guest: Guest;
  setting: CookieSetting;
  configure: boolean;

  types: Array<string>;
  info = {'technical': false, 'analytics': false};

  constructor(private modalServie: ModalService,
              private businessService: BusinessService,
              private guestService: GuestService) {
  }

  ngOnInit() {
    this.types = Object.keys(this.info);

    this.subscriptions.add(this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.subscriptions.add(this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
        this.guest = guest;
        this.setting = this.guest.cookies || new CookieSetting({});
      }));
    }));
  }

  toggle(type) {
    this.info[type] = !this.info[type];
  }

  acceptAll() {
    this.setting = new CookieSetting({analytics: true});
    this.save();
  }

  save() {
    this.guestService.setCookies(this.setting);
    this.modalServie.close();
  }

  days(days): string {
    return moment().add(days, 'days').from(moment(), true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
