import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CusInfo } from 'models/pms/pms_reservation';
import { filter, take } from 'rxjs/operators';
import { AddressType, FormFieldKeys, UserActionType, Step, TravelPurpose } from 'pms_enums';
import { GenericCheckIn } from 'pms_models/generic_check_in';
import { GenericData } from 'pms_models/generic_data';
import { Globals } from 'base';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { PmsCiCoService } from 'cico_service';
import { PmsGuest } from 'models/pms/pms_guest';
import { fadeInAnimation } from 'app/route-animations';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-pms-ci-travel-info',
  templateUrl: './travel_info.component.html',
  styleUrls: ['./travel_info.component.scss'],
  animations: [fadeInAnimation]
})
export class PmsTravelInfoComponent extends PmsBaseDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() submited: boolean = false;

  protected readonly TravelPurpose = TravelPurpose;

  numbersFellows = [];
  transportation: boolean;
  toggleCityTax: boolean;
  travel_purpose_lock: boolean = false;
  hasFellows = null;
  fellow_types = [];
  fields = {};
  fieldsLoaded: boolean;
  minDepartureDate: string;
  maxFellows: number = 10;
  totalGuestCount: number;
  nightsCount: number;
  reservationCusInfo: CusInfo;

  subscriptions: Subscription = new Subscription();
  module: Module;

  rightSide = false;
  rule: any;

  loaded: boolean = false;

  constructor(public globals: Globals,
              public cicoService: PmsCiCoService,
              protected stepperService: StepperService,
              private _cdr: ChangeDetectorRef) {
    super(cicoService, Step.reservation, undefined, stepperService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.cicoService.setShowFooter(true);
    this.rule = this.cicoService.rule;

    this.cicoService.cusSelectedProduct = this.rule?.products?.[0]?.product ?? null;
    this.cicoService.productsBooking.toBeBooked = false;

    this.subscriptions.add(this.cicoService.navigation.subscribe((type) => {
      if (type === UserActionType.next) {
        this.globals.posthogSetCapture({active: true});
      }
    }));

    this.loadCus(this.cicoService.cusLoadedSubj.value);
    if (!this.loaded) {
      this.subscriptions.add(this.cicoService.cusLoaded.subscribe((loaded: boolean) => {
        this.loadCus(loaded);
      }));
    }

    this.numbersFellows = this.countSelection(10);

    this.subscriptions.add(this.cicoService.fieldsUpdated.pipe(filter(Boolean)).subscribe(() => {
      this.setFields();
      this._cdr.detectChanges();
    }));
  }

  loadCus(loaded) {
    this.rule = this.cicoService.rule;
    this.loaded = loaded;
    this.cicoService.preventNext = !loaded;
    if (this.loaded && this.rule) {
      this.globals.posthogStartRecord();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.cicoService.cusLoaded.pipe(filter(Boolean)).subscribe(() => {
      setTimeout(() => {
        const autoSkipUntilStep = this.cicoService.autoSkipUntilStep;
        if (autoSkipUntilStep && autoSkipUntilStep !== Step.reservation) {
          this.stepperService.nextStep();
          if (this.cicoService.rule?.products) {
            // If external payment is successful or failed, system should redirect to invoice page if cus is there.
            if (autoSkipUntilStep === Step.invoice && this.cicoService.activeStep.key === Step.reservation && this.cicoService.activeStep.totalSteps > 1) {
              this.stepperService.nextStep();
            }
            this.cicoService.setAutoSkipUntilStep(undefined);
          }
        } else if (autoSkipUntilStep === Step.reservation) {
          this.cicoService.setAutoSkipUntilStep(undefined);
        }
      });
    }));
  }

  protected fetchData() {
    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
      this.setFields();
      this.invoiceAddressPosition();

      this.totalGuestCount = this.data.incident.reservation.guestsCount();
      this.nightsCount = this.data.incident.reservation.nightsCount();
      this.reservationCusInfo = this.data.incident.reservation.cusInfos();
      this.fellows = data.blank;
      this.fellow_types = this.data.module.settings.fellows_kinds;
      this.transportation = ['ch'].includes(this.data.business.address.country);
      this.toggleCityTax = this.data.module.settings.city_tax_toggle;
      this.travel_purpose_lock = this.data.module.settings.travel_purpose_lock && (data.incident.reservation.travel_purpose?.length || 0) > 0;
      this.hasFellows = (this.data.incident.reservation.adults + this.data.incident.reservation.children) > 0;

      if (this.data.blank) {
        this.loaded = true;
        this.setMinDeparture();
        this.cicoService.hideBackButton(true);
      }

      if (!this.transportation && this.field_for('car_licence')) {
        const transp = this.data.incident.reservation.car_licence;
        (<GenericCheckIn>this.data.incident).transportation = transp ? 'private' : 'public';
      }
      if (!this.data.incident.reservation.travel_purpose && this.fields['travel_purpose'] && this.data.incident.reservation?.address?.address_type === AddressType.company) {
        this.data.incident.reservation.travel_purpose = TravelPurpose.business_trip;
        this.checkCityTax();
      }

      if (this.ownInvoiceAddress) {
        this.cicoService.setAddressName();
      } else {
        const navSub = this.cicoService.navigation.subscribe(type => {
          if (type === UserActionType.next) {
            this.cicoService.setAddressName();
            navSub?.unsubscribe();
          }
        });
      }
    }));
  }

  setFields() {
    [['reservation_infos', 'booking'], ['reservation_infos', 'arrival'], ['reservation_infos', 'departure'], ['reservation_infos', 'unit'], ['reservation_infos', 'total_price'], ['reservation_infos', 'time_of_arrival'], ['reservation_infos', 'time_of_departure'], ['coming_from', null], ['destination', null], ['border_crossing', null], ['border_crossing_date', null], ['car_licence', null], ['fellows', null], ['marketing', null], ['travel_purpose', null]].forEach((entry) => {
      this.fields[entry[1] ? entry[1] : entry[0]] = this.field_for(entry[0], entry[1]);
    });
    this.fieldsLoaded = true;
  }

  checkFields() {
    this.cicoService.updatedFields();
  }

  checkCityTax() {
    if (this.toggleCityTax) {
      const remove = this.data.incident.reservation.travel_purpose === TravelPurpose.business_trip;
      this.subscriptions.add(this.cicoService.pmsService.setCityTax(remove).subscribe(() => {
        this.cicoService.folioUpdateSubj.next({cus: false});
      }));
    }
  }

  fellowsCheck(value) {
    if (!value) {
      this.removeFellows();
    }
  }

  fellowsQty(kind) {
    if (!this.hasFellows) {
      this.removeFellows();
      return;
    }
    const quantity = Math.min(Number(this.data.incident.reservation[kind]), this.maxFellows);
    if (kind === 'adults') {
      this.changeFellows(quantity, this.data.incident.reservation.related_guests, FormFieldKeys.adultFellows);
    } else {
      this.changeFellows(quantity, this.data.incident.reservation.children_guests, FormFieldKeys.childrenFellows);
    }
  }

  private changeFellows(qty: number, list: any[], formField: string) {
    // Find the current user step related to guest processing.
    const userStep = this.cicoService.userSteps.find(step => step.key === Step.guests);
    if (!userStep) {
      return;
    }

    while (qty !== null && list.length !== qty) {
      if (list.length > qty) {
        list.pop();
        userStep.totalSteps--;
      } else if (list.length < qty) {
        list.push(new PmsGuest({ group: formField}));
        userStep.totalSteps++;
      }
    }
  }


  removeFellows() {
    this.data.incident.reservation.adults = 0;
    this.data.incident.reservation.children = 0;
    this.changeFellows(0, this.data.incident.reservation.related_guests, FormFieldKeys.adultFellows);
    this.changeFellows(0, this.data.incident.reservation.children_guests, FormFieldKeys.childrenFellows);
  }

  setMinDeparture(): void {
    const arrivalDate = this.data.incident.reservation?.arrival;
    this.minDepartureDate = this.globals.today(1, arrivalDate);
  }

  countSelection(max: number) {
    const arr: { name: string, value: number }[] = [];
    for (let i = 0; i <= max; i++) {
      arr.push({name: i.toString(), value: i});
    }
    return arr;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
