import { Link } from './link';

export class Advert {
  gid: string;
  id: number;
  name: string;
  description: string;
  link: Link;
  image: string;

  constructor(raw_json: any) {
    this.gid = raw_json.gid;
    this.id = raw_json.id;
    this.name = raw_json.name;
    this.description = raw_json.description;
    this.link = raw_json.link || undefined;
    this.image = raw_json.media ? raw_json.media.image : undefined;
  }
}
