import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetGroupImageRoutingModule } from './group_image-routing.module';
import { WidgetGroupImageComponent } from './group_image.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetGroupImageRoutingModule,
  ],
  exports: [
    WidgetGroupImageComponent
  ],
  declarations: [WidgetGroupImageComponent]
})
export class WidgetGroupImageModule { }
