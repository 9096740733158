import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WidgetAdvertRoutingModule } from './advert-routing.module';
import { WidgetAdvertComponent } from './advert.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetAdvertRoutingModule,
    TranslateModule,
    SwiperModule
  ],
  exports: [
    WidgetAdvertComponent
  ],
  declarations: [WidgetAdvertComponent]
})
export class WidgetAdvertModule { }
