<div class="page-headline">
  <h2 translate>external_link.title</h2>
</div>

<div class="container">
  <div class="container-right" *ngIf="business">
    <app-swiper [items]="medias" *ngIf="medias?.length"></app-swiper>
    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <p>{{ 'external_link.description' | translate:business }}</p>
    <p [innerHtml]="click_here" *ngIf="link"></p>
  </div>
</div>

<nav role="navigation" class="element-navigation">
  <div class="navigation-container clearfix">
    <div class="nav-btn invisible"><a></a></div>
    <div class="nav-btn">
      <a translate (click)="globals.goBack()">footer.back</a>
    </div>
    <div class="nav-btn invisible"><a></a></div>
  </div>
</nav>
