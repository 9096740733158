import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class ModuleService {

  constructor(private api: ApiService) { }

  getModuleFromApi(mod, reservation_uuid = null): Observable<Object> {
    const query = mod?.startsWith('pms_check_') && reservation_uuid?.length ? '?uuid=' + reservation_uuid : '';
    return this.api.silentGet('module/' + mod + query);
  }
}
