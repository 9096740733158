export class UserOption {
  type: string;
  name: string;
  icon: string;

  constructor(raw_json: any) {
    this.type = raw_json.type;
    this.name = raw_json.name;
    this.icon = raw_json.icon;
  }
}
