import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WishListAllRoutingModule } from './all-routing.module';
import { WishListAllComponent } from './all.component';

@NgModule({
  imports: [
    CommonModule,
    WishListAllRoutingModule,
    WidgetAdvertModule,
    SharedSwiperModule,
    ModuleTitleModule,
    FormsModule,
    TranslateModule,
    NgPipesModule,
    FilterPipeModule
  ],
  exports: [
    WishListAllComponent
  ],
  declarations: [WishListAllComponent],
  providers: [
    ServiceAllDirective
  ]
})
export class WishListAllModule { }
