export class List {

  id: string;

  constructor(raw_json: any) {
    this.id = raw_json.id;
  }

}


