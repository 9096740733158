import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SendCancelComponent } from './send_cancel.component';


const routes: Routes = [
  { path: '', component: SendCancelComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SendCancelRoutingModule { }
