import { animate, AnimationBuilder, style } from '@angular/animations';
import { OnInit, Directive, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';

@Directive()
export class CiCoFooterBaseDirective implements OnInit, OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  private footerHeight: number;
  protected keyboardOpen: boolean = false;
  protected footerEl: HTMLElement;

  constructor(protected globals: Globals,
              protected cicoService: PmsCiCoService,
              protected animationBuilder: AnimationBuilder) {}

  ngOnInit() {
    if (this.enabled()) {
      this.subscribeToKeyboardChange();
      this.setupResizeEvent();
      this.setupScrollEvent();
      this.setupTouchMove();
    }
  }

  enabled(): boolean {
    if (window.visualViewport) {
      return this.globals.isIos() || (this.globals.place?.wizard && this.globals.isTouch())
    } else {
      return false;
    }
  }

  subscribeToKeyboardChange() {
    this.subscriptions.add(this.cicoService.keyboardOpenSubj.subscribe(keyboardOpen => {
      if (keyboardOpen !== this.keyboardOpen) {
        console.log("open?", keyboardOpen)
        this.keyboardOpen = keyboardOpen;
        if (!this.keyboardOpen) {
          setTimeout(() => {
            // checks if it's still not open, as user clicks on other input, it don't need to be marked as closed
            if (this.keyboardOpen === keyboardOpen) {
              this.handleKeyboardVisibility(this.keyboardOpen);
            }
          }, 10)
        } else {
          this.handleKeyboardVisibility(this.keyboardOpen);
        }
      }
    }));
  }

  setupResizeEvent() {
    this.subscriptions.add(fromEvent(visualViewport, 'resize').pipe(tap(() => {
      if (this.keyboardOpen) {
        this.setFooter();
        this.footerEl.style.visibility = 'hidden';
      }
    }), debounceTime(200)).subscribe(() => {
      if (this.keyboardOpen) {
        this.handleKeyboardVisibility(this.keyboardOpen);
      }
    }));
  }

  setupScrollEvent() {
    this.subscriptions.add(fromEvent(window, 'scroll').pipe(tap(() => {
      if (this.keyboardOpen) {
        this.setFooter();
        this.footerEl.style.visibility = 'hidden';
      }
    }), debounceTime(200)).subscribe(() => {
      if (this.keyboardOpen) {
        this.handleKeyboardVisibility(this.keyboardOpen);
      }
    }));
  }

  setupTouchMove() {
    this.subscriptions.add(fromEvent(window, 'touchmove').pipe(tap(() => {
      if (this.keyboardOpen) {
        this.setFooter();
        this.footerEl.style.visibility = 'hidden';
      }
    }), debounceTime(200)).subscribe(() => {
      if (this.keyboardOpen) {
        this.handleKeyboardVisibility(this.keyboardOpen);
      }
    }));
  }

  handleKeyboardVisibility(isVisible: boolean): void {
    this.setFooter();

    if (this.footerEl && window.visualViewport) {
      this.footerHeight = this.footerHeight ? this.footerHeight : this.footerEl.clientHeight;
      setTimeout(() => {
        this.footerEl.style.position = 'fixed';
        this.footerEl.style.visibility = 'visible';
        if (isVisible) {
          let height = window.visualViewport.height + window.visualViewport.offsetTop - this.footerHeight;
          this.footerEl.style.top = height + 'px';
        } else {
          this.footerEl.style.removeProperty('top');
        }
        //this.fadeIn(this.footerEl);
      }, 50);
    }
  }

  fadeIn(el: HTMLElement) {
    try {
      this.animationBuilder?.build([
        style({opacity: 0}),
        animate('500ms ease-in', style({opacity: 1}))
      ]).create(el).play();
    } catch {}
  }

  setFooter() {
    this.footerEl = document.querySelector('footer');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
