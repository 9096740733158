import { Component } from '@angular/core';
import { Globals } from 'base';
import { filter, take } from 'rxjs/operators';
import { Guest } from '../../_models/guest';
import { GuestService } from '../../_services/guest.service';

@Component({
  selector: 'app-widget-push',
  templateUrl: './push.component.html'
})

export class WidgetPushComponent {
  visible = false;

  constructor(public globals: Globals,
              private guestService: GuestService) {
    this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
      this.visible = guest.can_subscribe && ('PushManager' in window)
    })
  }
}
