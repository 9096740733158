import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-form-send-cancel',
  templateUrl: './send_cancel.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SendCancelComponent {
  legal: any;

  @Input() confirm: string;
  @Input() redirect: string;
  @Input() service: PmsCiCoService;
  @Input() status: boolean;
  @Input() disabled: boolean;

  constructor(public globals: Globals,
              private modal: ModalService,
              private router: Router,
              private businessService: BusinessService) {
    this.globals.init_send_cancel_btn();
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.legal = business.legal;
    });
  }

  close() {
    if (this.modal.isShowing) {
      this.modal.close();
    } else {
      if (this.service && this.globals.kiosk()) {
        this.service.idleSubj.next(true);
      }
      if (this.redirect) {
        this.globals.navigate(this.redirect);
      } else {
        this.router.navigate(['/g/', this.globals.getCode()]);
      }
    }
  }
}
