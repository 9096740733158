import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ComplaintListParentRoutingModule } from './parent-routing.module';
import { ComplaintListParentComponent } from './parent.component';

@NgModule({
  imports: [
    CommonModule,
    ComplaintListParentRoutingModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    TranslateModule,
    FilterPipeModule,
    BacklinkModule,
    NgPipesModule,
    FormsModule
  ],
  exports: [
    ComplaintListParentComponent
  ],
  declarations: [ComplaintListParentComponent],
  providers: [
    ServiceParentDirective
  ]
})
export class ComplaintListParentModule { }
