import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaFilterPipeRoutingModule } from './mediaFilter-routing.pipe.module';
import { MediaFilterPipe } from './mediaFilter.pipe';

@NgModule({
  imports: [
    CommonModule,
    MediaFilterPipeRoutingModule
  ],
  exports: [
    MediaFilterPipe
  ],
  declarations: [MediaFilterPipe]
})
export class MediaFilterPipeModule { }
