import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LegalModule } from '../legal/legal.module';
import { SendCancelRoutingModule } from './send_cancel-routing.module';
import { SendCancelComponent } from './send_cancel.component';


@NgModule({
  imports: [
    CommonModule,
    SendCancelRoutingModule,
    LegalModule,
    TranslateModule
  ],
  exports: [
    SendCancelComponent
  ],
  declarations: [SendCancelComponent]
})
export class SendCancelModule { }
