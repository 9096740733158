<div id="activate" class="in" (click)="toggle()">
 <i class="fas fa-caret-left fa-2x in"></i>
 <i class="fas fa-caret-right fa-2x out"></i>
</div>

<section id="sidebar" class="removed" #container>
  <div class="scroll">
    <ng-container #widgets></ng-container>
  </div>
</section>
