import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetHeaderComponent } from './header.component';

const routes: Routes = [
  { path: '', component: WidgetHeaderComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetHeaderRoutingModule { }
