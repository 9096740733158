import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmPage } from 'models/confirm_page';
import { DomModal } from 'models/dom_modal';
import { DomModalNewsletter } from 'models/dom_modal_newsletter';
import { GenericService } from 'models/generic_service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { AvailableComponent } from 'modules/shared/forms/available/available.component';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class ServiceService {

  confirmPageSubj: BehaviorSubject<ConfirmPage> = new BehaviorSubject<ConfirmPage>(null);
  confirmPage: Observable<ConfirmPage> = this.confirmPageSubj.asObservable();

  constructor(private modalService: ModalService,
              private router: Router,
              private globals: Globals,
              private api: ApiService) {
  }

  newService(): GenericService {
    const service = new GenericService();
    service.email = this.globals.getEmail();
    service.name = this.globals.getName();

    return service;
  }

  openModal(title: string, description: string, newsletter: DomModalNewsletter = null,
            redirect: boolean = true, instant: boolean = true): void {
    const body = new DomModal();
    body.title = title;
    body.description = description;
    body.newsletter = newsletter;
    body.redirect = redirect;
    this.modalService.open(body, instant);
  }

  openConfirmPage(response, redirect = true) {
    const confirmPage = <ConfirmPage>response['confirm_page'];
    switch (confirmPage.mode) {
      case 'mod':
        const commands = ['/g/', this.globals.getCode()];
        if (confirmPage.module !== 'home') {
          commands.push(confirmPage.module);
        }
        this.globals.alert('success', this.globals.translate('service.toast'));
        this.router.navigate(commands, {replaceUrl: true});
        break;
      case 'article':
        this.confirmPageSubj.next(confirmPage);
        this.router.navigate(['/g/', this.globals.getCode(), 'confirm'], {replaceUrl: true});
        break;
      case 'flat':
        confirmPage.title = this.globals.translate('misc.thank_you');
        confirmPage.body = response['message'];
        confirmPage.newsletter = response['newsletter'];
        this.confirmPageSubj.next(confirmPage);
        this.router.navigate(['/g/', this.globals.getCode(), 'confirm'], {replaceUrl: true});
        break;
      default:
        this.openModal('misc.thank_you', response['message'], response['newsletter'], redirect);
        break;
    }
  }

  openTimetable() {
    const body = new DomModal();
    body.title = this.globals.translate('service_time.out_of_time');
    body.redirect = false;
    body.component = AvailableComponent;
    this.modalService.open(body);
  }

  send_service(self, module) {
    this.globals.markAllControlsTouched(self.serviceForm);
    const available = module === 'menu' ? !self.disabled : self.module.available;
    if (self.serviceForm?.valid && available) {
      this.globals.sending();
      this.api.post('service', {
        module: module,
        params: self.service
      }).subscribe(success => {
          this.globals.setEmail(self.service.email);
          this.globals.setGuestName(self.service.name);
          this.openConfirmPage(success);
          this.globals.init_send_cancel_btn();
        }, error => {
          if (self.offline) {
            self.serviceService.openModal('misc.thank_you', 'bgSync.description_submitted');
          }
          self.error_text = this.globals.formError(error);
          this.globals.init_send_cancel_btn();
        }
      );
    }
  }
}
