import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class GroupPortfolioService {

  constructor(private api: ApiService) {}

  getMenu(): Observable<Object> {
    return this.api.get('group_portfolio');
  }

  getPortfolio(id: string): Observable<Object> {
    return this.api.get('group_portfolio/' + id);
  }


}
