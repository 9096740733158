
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { EventConstants } from 'global_enums';
import { Field } from 'models/field';
import { PmsGuest } from 'models/pms/pms_guest';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { FormFieldKeys, Step } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { filter, take } from 'rxjs/operators';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-pms-ci-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})

export class PmsCiGeneralComponent extends PmsBaseDirective implements OnInit, OnDestroy {
  idnow: boolean;
  formFields: any;
  formsCount = 0;
  showOverlay: boolean;
  persons: PmsGuest[] = [];

  guestList: PmsGuest[] = [];
  guests: PmsGuest[] = [];
  fields: Field[] = [];

  constructor(public globals: Globals,
              public pmsService: PmsService,
              protected cicoService: PmsCiCoService,
              protected stepperService: StepperService,
              protected readonly eventService: EventAggregatorService,
              protected route: ActivatedRoute,
            ) {
    super(cicoService, Step.guests, route, stepperService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.start();

    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
      this.combineAllGuests();
      this.initializeGeneralForms();
    }));

    // Subscribe on each 'next' and 'previous' action.
    this.subscriptions.add(this.eventService.getEvent(EventConstants.moveToNextOrPreviousPage).subscribe(() => {
      this.initializeGeneralForms();
    }));
  }

  /**
   * Initializes general forms based on the active step in the stepper.
   * It sets up the active stepper, determines the currently active guests based on the stepper's step,
   * and fetches the corresponding field configuration for those guests.
   */
  private initializeGeneralForms() {
    this.guests = [];
    this.fields = [];

    const isLastStepOfGuest = this.cicoService.activeStep.totalSteps > 1 && 
                              this.cicoService.activeStep.currentStep === this.cicoService.activeStep.totalSteps;

    // If it's the last step and there are children guests, handle them specifically.
    if (isLastStepOfGuest && this.data.incident.reservation.children_guests.length > 0) {
      this.guests = [...this.data.incident.reservation.children_guests];
    } else {
      // For all other cases, including last step with related guests or any step before the last.
      this.guests.push(this.guestList[this.cicoService.activeStep.currentStep - 1]);
    }

    // Get the field configuration for the active guests.
    this.fields = this.guests.map(field => this.getField(field.group));

    // If the active step is the primary guest, the auto skip is enabled and there are more steps in guests.
    // Or If External payment service is failed or successfull then redirect to the next step. Skip until the invoice is there is no cus.
    if ((this.cicoService.autoSkipUntilStep && this.cicoService.activeStep.totalSteps > 1) || this.cicoService.autoSkipUntilStep === Step.invoice) {
      this.autoNextProcess(Step.guests);
    }
  }

  /**
  * Combines all guests into a single array for easier management.
  * This method initializes the array with the primary guest, and conditionally
  * adds related guests and children guests if they exist.
  */
  private combineAllGuests() {
    // Start by including the primary guest in the guests array.
    const primaryGuests = [this.data.incident.reservation.primary_guest];
    this.guestList = [...primaryGuests];

    // Conditionally add related guests to the allGuests array if they are present.
    if (this.data.incident.reservation.related_guests) {
      this.guestList = [...this.guestList, ...this.data.incident.reservation.related_guests];
    }
  }

  /**
  * Retrieves the field configuration for a given guest group.
  * Uses a mapping of group names to field configurations to fetch the appropriate settings.
  *
  * @param group The group name of the guests (e.g., 'primary_guest', 'adult_fellows', 'children_fellows').
  * @returns The field configuration for the specified group, or null if the group is not recognized.
  */
  private getField(group: string) {
    const fieldMap = {
      [FormFieldKeys.primaryGuest]: this.formFields.primaryGuest,
      [FormFieldKeys.adultFellows]: this.formFields.adultFellows,
      [FormFieldKeys.childrenFellows]: this.formFields.childFellows
    };

    return fieldMap[group] || null;  // Return the configuration or null if the group isn't found.
  }


  protected fetchData() {
    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;

      this.formsCount = this.persons?.length;
      this.persons = this.data.incident.reservation.related_guests;

      this.cicoService.setSteps(this.data);

      this.formFields = {
        primaryGuest: this.field_for('primary_guest'),
        adultFellows: this.field_for('fellows', 'adult_fellows'),
        childFellows: this.field_for('fellows', 'children_fellows')
      };
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}