import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventEntryComponent } from './entry/entry.component';
import { EventComponent } from './event.component';


const routes: Routes = [
  { path: '', component: EventComponent },
  { path: ':id', component: EventEntryComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }
