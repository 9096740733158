import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import * as moment from 'moment';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';

@Component({
  selector: 'app-form-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class DatesComponent implements OnInit {
  @Input() service: GenericService;
  @Input() module: Module;
  @Input() select_all_days = false;

  @ViewChild('bool_helper', {static: true}) bool_helper: NgModel;

  dates_list = [];
  select: boolean;

  constructor() {}

  ngOnInit() {
    this.dates_list = this.module.settings.dates.map((date, index) => {
      return {
        view: moment(date['date']).format('ddd, L'),
        date: moment(date['date']).format('DD.MM.Y'),
        index: index,
        available: date['available']
      };
    });
  }

  choose(idx) {
    if (this.dates_list[idx]['available']) {
      this.date(idx).classList.toggle('selected')
      this.serviceDates();
    }
  }

  check(event) {
    const selectAll = event.target.checked
    this.dates_list.forEach(date => {
      const field = this.date(date.index)
      selectAll && this.dates_list[date.index]['available'] ? field.classList.add('selected') : field.classList.remove('selected')
    });
    this.serviceDates();
  }

  serviceDates() {
    this.service.dates = []
    document.querySelectorAll('div.selected').forEach((date: any) => { this.service.dates.push(this.dates_list[date.dataset.index].date) })
    this.service.bool_helper = this.service.dates.length > 0;
    this.select = this.service.dates.length === this.dates_list.length;
  }

  date(index): any {
    return document.querySelector('div[data-index="'+index+'"]');
  }
}
