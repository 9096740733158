import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { Business } from 'models/business';
import { OverlayAction } from 'pms_enums';
import { GenericOverlay } from 'pms_models/generic_overlay';
import { Subscription } from 'rxjs';
import { Globals } from 'base';

@Component({
  selector: 'app-pms-cico-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class PmsOverlayComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() business: Business;
  @Input() content: GenericOverlay;
  @Input() closeable: boolean;
  @Input() icon: string = 'fa-solid fa-file-circle-check';
  overlayAction = OverlayAction;

  @ViewChild('dynamicContent', {read: ViewContainerRef, static: false})
  dynamicContent: ViewContainerRef;

  constructor(public cicoService: PmsCiCoService,
              private resolver: ComponentFactoryResolver,
              private changeDetector: ChangeDetectorRef,
              private globals: Globals) {
  }

  ngAfterViewInit() {
    if (!this.content?.with_back) {
      document.getElementById('container')?.classList?.add('overlay');
    }
    this.cicoService.overlayOpenSubj.next(true);
    if (this.content?.component) {
      const factory = this.resolver.resolveComponentFactory(this.content?.component);
      const view = this.dynamicContent.createComponent(factory);

      Object.keys(this.content?.inputParams).forEach(key => {
        view.instance[key] = this.content?.inputParams[key];
      });
      this.closeable = this.content?.inputParams['close'];
      this.changeDetector.detectChanges();
    }
  }

  onClick(action: OverlayAction, guard: boolean = false) {
    this.cicoService.cardLost = null;
    this.cicoService.closeOverlay(action, guard);
    this.cicoService.overlayOpenSubj.next(false);
  }

  ngOnDestroy(): void {
    this.globals.removeOverlayClass();
    this.changeDetector.detach();
    this.subscriptions.unsubscribe();
    this.cicoService.overlayOpenSubj.next(false);
  }
}
