<div class="popup" [hidden]="!signatureField" *ngIf="data">
  <h2 #signatureTitle id="signature-title" class="title main-text" [innerHTML]="signatureField.name"></h2>
  <p [innerHTML]="signatureField.description" *ngIf="signatureField.description?.length"></p>
  <signature-pad #signaturePad [options]="SIG_PAD_OPTS" (onEndEvent)="validate()"></signature-pad>
  <div class="bottom">
    <div class="grid">
      <p class="info" translate>service.check_in.signature.description</p>

      <div class="reset" [class.disabled]="!touched" (click)="resetSignature()">
        <i class="fas fa-arrow-rotate-right reset-icon"></i>
        <a class="reset-text main-text" translate>misc.reset</a>
      </div>
    </div>
  </div>
  <input name="signature" class="hidden-field" #signature="ngModel" [(ngModel)]="data.incident.reservation.signature" [required]="signatureField.required">

  <app-form-legal [text]="'privacy_policy'" *ngIf="legal && !legal.terms"></app-form-legal>
  <app-form-legal [text]="'terms'" *ngIf="legal?.terms"></app-form-legal>

  <div class="buttons-container">
    <button type="button" class="btn-outline" (click)="cancelDialog()" translate>misc.close</button>
    <button type="button" class="btn-filled" (click)="submit()" [disabled]="!valid || disabled" translate>{{confirmName | translate}}</button>
  </div>
</div>
