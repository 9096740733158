export class FieldValue {
  id: number;
  value: string;
  valid: boolean;
  kind: string;
  check_out_if: string;

  constructor(raw_json: any) {
    this.id = raw_json?.id;
    this.value = raw_json?.value;
    this.kind = raw_json?.kind;
    this.check_out_if = raw_json?.check_out_if;
  }
}
