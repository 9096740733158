import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WidgetLogoModule } from 'widgets/logo/logo.module';
import { SearchModule } from '../search/search.module';
import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderRoutingModule,
    WidgetLogoModule,
    TranslateModule,
    SearchModule
  ],
  exports: [
    HeaderComponent
  ],
  declarations: [HeaderComponent],
  providers: [
    TranslateService
  ]
})
export class HeaderModule { }
