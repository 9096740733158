import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { PoiModuleService } from '../../poi.service';

@Component({
  selector: 'app-poi-list-all',
  templateUrl: './all.component.html',
  styleUrls: ['../parent/parent.component.scss']
})

export class PoiListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'poi';

  showMap = false;
  distances = false;

  constructor(public globals: Globals,
              protected businessService: BusinessService,
              private poiService: PoiModuleService) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.poiService).then(() => {
      this.showMap = this.module.settings.show_map;
      this.distances = this.module.settings.distances;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
