import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { CouponListAllRoutingModule } from './all-routing.module';
import { CouponListAllComponent } from './all.component';

@NgModule({
  imports: [
    CommonModule,
    CouponListAllRoutingModule,
    FormsModule,
    WidgetAdvertModule,
    SharedSwiperModule,
    ModuleTitleModule,
    TranslateModule,
    AttachmentsModule,
    FilterPipeModule,
    NgPipesModule
  ],
  exports: [
    CouponListAllComponent
  ],
  declarations: [CouponListAllComponent],
  providers: [
    ServiceAllDirective
  ]
})
export class CouponListAllModule { }
