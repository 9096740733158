import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ElementNavigationRoutingModule } from './element_navigation-routing.module';
import { ElementNavigationComponent } from './element_navigation.component';

@NgModule({
  imports: [
    CommonModule,
    ElementNavigationRoutingModule,
    TranslateModule
  ],
  exports: [
    ElementNavigationComponent
  ],
  declarations: [ElementNavigationComponent]
})
export class ElementNavigationModule { }
