import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { NewsletterFormModule } from './form/form.module';
import { NewsletterRoutingModule } from './newsletter-routing.module';
import { NewsletterComponent } from './newsletter.component';

@NgModule({
  imports: [
    CommonModule,
    NewsletterRoutingModule,
    NewsletterFormModule,
    ModuleTitleModule,
    SharedSwiperModule,
    WidgetAdvertModule
  ],
  exports: [
    NewsletterComponent
  ],
  declarations: [NewsletterComponent]
})
export class NewsletterModule { }
