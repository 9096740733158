import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LengthValidator } from './length.validator';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    LengthValidator
  ],
  declarations: [LengthValidator]
})
export class LengthValidatorModule { }
