import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetA2hsComponent } from './a2hs_widget.component';

const routes: Routes = [
  { path: '', component: WidgetA2hsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetA2hsRoutingModule { }
