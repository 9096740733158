<div class="input-container" [ngClass]="{ 'valid': inputValue?.length > 0 && input.valid, 'invalid': (input.dirty && input.invalid) || (submitted && input.invalid), 'disabled': disabled}">
  <app-description-label [for]="inputId" [description]="description" [tooltip]="tooltip" [required]="required && !disabled"></app-description-label>

  <div class="input-wrapper">
    <input type="time" class="redesign" [class.empty]="!inputValue" [id]="inputId" #input="ngModel" [name]="name" [(ngModel)]="inputValue" (ngModelChange)="valueChange()" [placeholder]="placeholder || description" [autocomplete]="autocomplete" [required]="required" [disabled]="disabled" [attr.data-cy]="name" (focus)="changeKeyboardState(true)" (blur)="changeKeyboardState(false)" [inputValidator]="{ required: required }">
    <i class="fa-solid fa-check checkmark-icon"></i>
    <i class="fa-solid fa-lock locked-icon"></i>
  </div>

  <label class="error-label" [class.visible]="(input.dirty && input.invalid || (submitted && input.invalid))">
    <i class="fa-solid fa-circle-xmark"></i>
    <span>{{ input.errors?.chars || errorMsg | translate }} </span>
  </label>
</div>
