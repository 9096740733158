import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-pms-terminal-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})

export class PmsKioskTimerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  show = false;

  constructor(public cicoService: PmsCiCoService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.cicoService.inactivity.subscribe(state => {
      setTimeout(() => {
        this.show = state;
      });
    }));
  }

  closeOverlay() {
    this.show = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
