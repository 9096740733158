<ng-container *ngIf="init && redesign">
  <div *ngFor="let field of fields">
    <ng-container *ngIf="modelFor(field.id)">
      <ng-container [ngSwitch]="field.kind">
        <app-date-input  *ngSwitchCase="'date'" [name]="'date_' + field.id" [(date)]="modelFor(field.id).value" [required]="field.required" [placeholder]="field.name" [description]="field.name" [tooltip]="field.description" [validation]="field.validation" [submitted]="submited"></app-date-input>

        <div *ngSwitchCase="'textarea'">
          <app-textarea ngDefaultControl [name]="'textarea_' + field.id" [(ngModel)]="modelFor(field.id).value" #textarea="ngModel" [description]="field.name" [placeholder]="field.name" [required]="field.required" [tooltip]="field.description" [submited]="submited"></app-textarea>
        </div>

        <div *ngSwitchCase="'polar'">
          <app-button-group ngDefaultControl [name]="'polar_' + field.id" [id]="'option' + field.id" [(ngModel)]="modelFor(field.id).value" [description]="field.name" [required]="field.required" [options]="[['misc.no_string', false], ['misc.yes_string', true]]" [tooltip]="field.description" [submitted]="submited"></app-button-group>
        </div>

        <div *ngSwitchCase="'checkbox'">
          <app-checkbox ngDefaultControl [name]="'check_field_' + field.id" [(ngModel)]="modelFor(field.id).value" [description]="field.description" [tooltip]="field.description" [option]="field.name" [required]="field.required" [submitted]="submited"></app-checkbox>
        </div>

        <ng-container *ngSwitchCase="'email'">
          <app-basic-input ngDefaultControl [name]="'email_' + field.id" [type]="'text'" [required]="field.required" [(ngModel)]="modelFor(field.id).value" [description]="field.name" [placeholder]="field.name" [tooltip]="field.description" [validation]="'email'" [submited]="submited"></app-basic-input>
        </ng-container>

        <ng-container *ngSwitchCase="'phone'">
          <app-phone-input ngDefaultControl [name]="'phone_' + field.id" [description]="field.name" [countries]="data?.countries" [(phoneNumber)]="modelFor(field.id).value" [tooltip]="field.description" [required]="field.required" [validation]="field.validation" [submitted]="submited"></app-phone-input>
        </ng-container>

        <div *ngSwitchCase="'payment'">
          <app-form-select ngDefaultControl [name]="'payment_' + field.id" [model]="modelFor(field.id)" [key]="'value'" [options]="field.options" [required]="field.required" *ngIf="field.options" [label]="field.name" [errorLabel]="'validation.required'" [tooltip]="field.description" [showIcons]="false" [submited]="submited" [redesign]="true"></app-form-select>
        </div>

        <div *ngSwitchCase="'time'">
          <app-time-input ngDefaultControl [name]="'time_' + field.id" [(ngModel)]="modelFor(field.id).value" [description]="field.name" [required]="field.required" [submitted]="submited"></app-time-input>
        </div>

        <ng-container *ngSwitchDefault>
          <app-basic-input ngDefaultControl [name]="'text_' + field.id" [type]="field.kind" [required]="field.required" #text="ngModel" [(ngModel)]="modelFor(field.id).value" [placeholder]="field.name" [description]="field.name" [tooltip]="field.description" [submited]="submited"></app-basic-input>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="init && !redesign">
  <div class="form-box" *ngFor="let field of fields">
    <ng-container *ngIf="modelFor(field.id)">
      <label *ngIf="field.kind !== 'checkbox'">{{field.name}}</label>
      <small translate *ngIf="field.kind !== 'checkbox' && field.description?.length">{{field.description}}</small>
      <ng-container [ngSwitch]="field.kind">
        <app-datepicker *ngSwitchCase="'date'" ngDefaultControl [name]="field.id.toString()" [key]="'value'" [model]="modelFor(field.id)" [field]="field" [startView]="'month'" [required]="field.required" [placeholder]="field.name" [description]="field.name" [tooltip]="field.description" [submited]="submited"></app-datepicker>

        <ng-container *ngSwitchCase="'textarea'">
          <textarea [name]="field.id.toString()" [required]="field.required" #textarea="ngModel" [(ngModel)]="modelFor(field.id).value" [placeholder]="field.name"></textarea>
          <label translate class="error">validation.required</label>
        </ng-container>

        <div class="open-dropdown" *ngSwitchCase="'polar'">
          <div class="radio-container" *ngFor="let option of ['yes', 'no']">
            <input class="custom-radio" type="radio" [name]="option + field.id" [id]="option + field.id" [(ngModel)]="modelFor(field.id).value" [required]="field.required" [value]="option === 'yes'">
            <label [for]="option + field.id">{{'misc.' + option + '_string' | translate}}</label>
            <label translate class="error" *ngIf="option === 'no'">validation.required</label>
          </div>
        </div>

        <div *ngSwitchCase="'checkbox'" class="checkboxgroup">
          <div class="checkbox">
            <input type="checkbox" [name]="'check_field_' + field.id" [id]="'check_field_' + field.id" [(ngModel)]="modelFor(field.id).value" class="option-input checkbox" [required]="field.required">
            <label [innerHtml]="field.name" [for]="'check_field_' + field.id"></label>
            <small translate *ngIf="field.description?.length">{{field.description}}</small>
            <label translate class="error">validation.required</label>
          </div>
        </div>

        <ng-container *ngSwitchCase="'email'">
          <input *ngSwitchCase="'email'" [name]="'email_' + field.id" [type]="field.kind" [required]="field.required" #bla="ngModel" [(ngModel)]="modelFor(field.id).value" [placeholder]="field.name" emailvalidator>
          <label translate class="error">validation.email</label>
        </ng-container>

        <app-form-phone-field *ngSwitchCase="'phone'" [model]="modelFor(field.id)" [key]="'value'" [field]="field"></app-form-phone-field>

        <div *ngSwitchCase="'payment'">
          <app-form-select [model]="modelFor(field.id)" [name]="field.id.toString()" [key]="'value'" [options]="field.options" [required]="field.required" *ngIf="field.options"></app-form-select>
        </div>

        <ng-container *ngSwitchDefault>
          <input [name]="'text_' + field.id" [type]="field.kind" [required]="field.required" min="0" max="254" [lengthValidator]="{min: 0, max: 254}" #text="ngModel" [(ngModel)]="modelFor(field.id).value" [placeholder]="field.name">
          <label translate class="error" *ngIf="text.errors?.required">validation.required</label>
          <label class="error" *ngIf="text.errors?.LengthValidator">{{ 'validation.maxLength' | translate:{length: 254} }}</label>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
