import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { BasketRoutingModule } from './basket-routing.module';
import { BasketComponent } from './basket.component';

@NgModule({
  imports: [
    CommonModule,
    BasketRoutingModule,
    ModuleTitleModule,
    NgPipesModule,
    FormsModule,
    TranslateModule,
    DatePickerModule,
    FormSelectModule,
    FormFieldsModule,
    FormPlaceModule,
    FormPhoneFieldModule,
    SendCancelModule,
    EmailValidatorModule
  ],
  exports: [
    BasketComponent
  ],
  declarations: [BasketComponent]
})
export class BasketModule { }
