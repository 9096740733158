import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormPhoneFieldComponent } from './phone_field.component';


const routes: Routes = [
  { path: '', component: FormPhoneFieldComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormPhoneFieldRoutingModule { }
