import { Component } from '@angular/core';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./../../error/error.component.scss']
})
export class OfflineComponent {

  constructor() { }

}
