import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsReservationModule } from '../../reservation/reservation.module';
import { PmsKioskReservationComponent } from './reservation.component';
import { PmsSimpleFooterModule } from '../../shared/simple-footer/simple-footer.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    PmsReservationModule,
    PmsSimpleFooterModule
  ],
  exports: [
    PmsKioskReservationComponent
  ],
  declarations: [PmsKioskReservationComponent]
})
export class PmsKioskReservationModule {}
