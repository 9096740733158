import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from '../../common/menu/menu.module';
import { ShopListAllModule } from './list/all/all.module';
import { ShopListParentModule } from './list/parent/parent.module';
import { ShopRoutingModule } from './shop-routing.module';
import { ShopComponent } from './shop.component';

@NgModule({
  imports: [
    CommonModule,
    ShopRoutingModule,
    ShopListAllModule,
    ShopListParentModule,
    MenuModule
  ],
  exports: [
    ShopComponent
  ],
  declarations: [ShopComponent]
})
export class ShopModule { }
