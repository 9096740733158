import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmailValidator } from './email.validator';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    EmailValidator
  ],
  declarations: [EmailValidator]
})
export class EmailValidatorModule { }
