import { Component, Input } from '@angular/core';
import { Globals } from 'base';

@Component({
  selector: 'app-backlink',
  templateUrl: './backlink.component.html'
})
export class BacklinkComponent {
  @Input() visibleMobile: boolean;

  constructor(public globals: Globals) { }

}
