import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LegalModule } from '../../../shared/forms/legal/legal.module';
import { PmsSimpleFooterRoutingModule } from './simple-footer-routing.module';
import { PmsSimpleFooterComponent } from './simple-footer.component';

@NgModule({
  imports: [
    CommonModule,
    PmsSimpleFooterRoutingModule,
    TranslateModule,
    LegalModule
  ],
  exports: [
    PmsSimpleFooterComponent
  ],
  declarations: [PmsSimpleFooterComponent]
})
export class PmsSimpleFooterModule { }
