import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllocatorComponent } from './allocator.component';


const routes: Routes = [
  { path: '', component: AllocatorComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AllocatorRoutingModule { }
