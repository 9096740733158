import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModuleModule } from 'modules/menu/menu.module';
import { BackgroundModule } from '../background/background.module';
import { MenuRoutingModule } from './menu-routing.module';
import { MenuComponent } from './menu.component';
import { WidgetAddressModule } from 'widgets/address/address.module';
import { WidgetGroupModule } from 'widgets/group/group.module';
import { WidgetGroupBusinessModule } from 'widgets/group_business/group_business.module';
import { WidgetGroupImageModule } from 'widgets/group_image/group_image.module';
import { WidgetLegalModule } from 'widgets/legal/legal.module';
import { WidgetLogoModule } from 'widgets/logo/logo.module';
import { WidgetPushModule } from 'widgets/push/push.module';
import { WidgetQrCodeModule } from 'widgets/qr_code/qr_code.module';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { WidgetWeatherModule } from 'widgets/weather/weather.module';

@NgModule({
  imports: [
    CommonModule,
    MenuRoutingModule,
    MenuModuleModule,
    BackgroundModule,
    TranslateModule,
    WidgetLogoModule,
    WidgetAddressModule,
    WidgetGroupModule,
    WidgetGroupBusinessModule,
    WidgetGroupImageModule,
    WidgetLegalModule,
    WidgetPushModule,
    WidgetQrCodeModule,
    WidgetSocialModule,
    WidgetWeatherModule,
  ],
  exports: [
    MenuComponent,
    TranslateModule
  ],
  declarations: [MenuComponent]
})
export class MenuModule { }
