import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'base';
import { File } from 'models/file';
import { ElementNavigationService } from 'shared/layout/element_navigation/element_navigation.service';
import { GroupPortfolioService } from '../group_portfolio.service';

@Component({
  selector: 'app-group-portfolio-entry',
  templateUrl: './group_portfolio.component.html'
})
export class GroupPortfolioEntryComponent implements OnInit {
  SWIPE_ACTION = {LEFT: 'swipeleft', RIGHT: 'swiperight'};
  item: any;
  showRight: boolean;
  trigger = true;
  files: File[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private portfolio: GroupPortfolioService,
              private elementNav: ElementNavigationService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.backlink = ['parent'];
    this.route.params.subscribe(params => {
      this.trigger = true;
      const id = params['id'];
      this.globals.setModule('group_portfolio');
      this.showRight = false;

      this.portfolio.getPortfolio(id).subscribe(
        success => {
          this.item = success['entry'];
          this.globals.page = this.item['gid'];
          this.files = this.item['attachments'];

          this.showRight = this.item.medias && this.item.medias.length > 0;
        }, () => {}
      );
    });
  }

  swipe(event: any) {
    if (![event.target?.className, event.target?.parentElement?.className].filter(slide => slide?.includes('swipe')).length) {
      if (!event.type) {
        event.type = this.SWIPE_ACTION.RIGHT;
      }
      if (event.type === this.SWIPE_ACTION.RIGHT) {
        this.elementNav.next(this.item);
      }
      if (event.type === this.SWIPE_ACTION.LEFT) {
        this.elementNav.previous(this.item);
      }
      this.trigger = false;
    }
  }
}
