<ng-container *ngIf="usable; else warning">
  <em translate>service.payment.credit_card.use</em>

  <div class="card" *ngIf="!loading">
    <img [src]="'assets/images/credit_card/' + card.brand + '.png'" [alt]="card.brand" />
    <span class="number" *ngIf="card.number">{{card.number}}</span>
    <span class="expire" *ngIf="card.expiry_month && card.expiry_year">{{(card.expiry_month < 10 ? '0' + card.expiry_month : card.expiry_month) + '/' + card.expiry_year}}</span>
    <span class="expire" *ngIf="!card.expiry_month || !card.expiry_year">**/**</span>
    <span class="holder" *ngIf="card.holder">{{card.holder}}</span>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</ng-container>

<ng-template #warning>
  <em *ngIf="payment.available_amount">{{'service.payment.too_less_authorized' | translate: { pre_auth_balance_with_currency: folio.pre_auth_balance_with_currency, pre_auth_date: payment.authorized_at, authorization_days: payment.expire_days } }}</em>
</ng-template>

<div class="btn-grid">
  <button class="btn-filled small-width" (click)="action()" [disabled]="loading">{{'service.payment.pay_now' | translate: folio}}</button>
  <button class="btn-outline small-width" (click)="pay()" [disabled]="loading" *ngIf="usable">{{'service.payment.credit_card.other' | translate}}</button>
  <button class="btn-outline small-width" translate (click)="close()">misc.back</button>
</div>

<p *ngIf="usable && isPreAuth">{{payment.pre_auth_info}}</p>
