import { Injectable } from '@angular/core';
import { IAggregateEvent, AggregateEvent, AggregateEventItem } from './event';

/**
 * Service for inter-component communication throughout the application.
 * Uses dependency injection to raise an event with a payload.
 * Note: All subscriptions should be unsubscribed in the ngOnDestroy() method of the subscribing component.
 */
@Injectable({
  providedIn: 'root',
})
export class EventAggregatorService {

    // Array to store event items with their associated keys
    private changeEvents: AggregateEventItem[] = [];

    /**
     * Retrieves or creates an event associated with the given key.
     * @param key The key identifying the event.
     * @returns The event associated with the key.
     */
    public getEvent<T>(key: string): IAggregateEvent<T> {
      // Check if an event with the specified key already exists
      const match = this.changeEvents.find(e => e.key === key);
      
      // If an event with the key exists, return it
      if (match != null && match !== undefined) {
        return match.event;
      }

      // If no event with the key exists, create a new one
      const eventItem = new AggregateEventItem();
      eventItem.key = key;
      eventItem.event = new AggregateEvent<T>();

      // Store the newly created event in the array
      this.changeEvents.push(eventItem);
      
      // Return the newly created event
      return eventItem.event;
    }
}
