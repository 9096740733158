import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediaFilter'
})

export class MediaFilterPipe implements PipeTransform {
  transform(medias: any[], searchType: string): any[] {
    if (!medias) { return []; }
    if (!searchType) { return medias; }


    return medias.filter(media => {
      return media.media.type === searchType;
    });
  }
}
