import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';

@Component({
  selector: 'app-wish-module',
  templateUrl: './wish.component.html'
})

export class WishComponent implements OnInit {
  module: Module;
  cart: string[];

  constructor(private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('wish', false).then((mod) => {this.module = mod; }).catch(() => {});
  }

}
