import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class GroupBusinessService {
  groupBusinessSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  groupBusiness: Observable<any> = this.groupBusinessSubj.asObservable();

  constructor(private api: ApiService) { }

  getGroupBusiness(id): Observable<Object> {
    return this.api.get('widgets/group_business/' + id);
  }
}
