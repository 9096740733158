import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MediaFilterPipeModule } from 'pipes/mediaFilter.pipe.module';
import { SwiperRoutingModule } from './swiper-routing.module';
import { SwiperComponent } from './swiper.component';

@NgModule({
  imports: [
    CommonModule,
    SwiperRoutingModule,
    MediaFilterPipeModule,
    SwiperModule,
  ],
  exports: [
    SwiperComponent
  ],
  declarations: [SwiperComponent]
})
export class SharedSwiperModule { }
