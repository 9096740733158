import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../../../../common/loader/loader.module';
import { FilterPipeModule } from '../../../../pipes/filter.pipe.module';
import { PmsPinCodesRoutingModule } from './pin_codes-routing.module';
import { PmsPinCodesComponent } from './pin_codes.component';

@NgModule({
  imports: [
    CommonModule,
    PmsPinCodesRoutingModule,
    FormsModule,
    TranslateModule,
    LoaderModule,
    FilterPipeModule
  ],
  exports: [
    PmsPinCodesComponent
  ],
  declarations: [PmsPinCodesComponent]
})
export class PmsPinCodesModule { }
