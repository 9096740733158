<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"> 0"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">

    <nav role="navigation" class="element-navigation">
      <div class=" navigation-container clearfix">
        <div class="nav-btn">
          <a translate [class.disabled]="getMonth() == 0" (click)="change('previous')">misc.back</a>
        </div>
        <div class="nav-btn">
          <a translate (click)="change('current')">misc.current</a>
        </div>
        <div class="nav-btn">
          <a translate [class.disabled]="getMonth() == 11" (click)="change('next')">misc.next</a>
        </div>
      </div>
    </nav>

    <div class="menu calendar" *ngIf="calendar">
      <div class="head">
        <div><i class="far fa-calendar-alt"></i></div>
        <div [innerHtml]="calendar.title"></div>
      </div>
      <a *ngFor="let day of calendar.days" [class]="day.class">
        <div>
          <span [innerHtml]="day.day"></span> <small [innerHtml]="day.weekday"></small>
        </div>
        <div>
          <span class="event" *ngFor="let event of day.entries" (click)="open(event.id)">
            <i class="ad-icon" *ngIf="event.advert"></i><i class="fas fa-home" *ngIf="event.inhouse"></i> {{event.name}}
          </span>
        </div>
      </a>
    </div>

  </div>
</div>
