export class Link {

  gid: string;
  url: string;
  url_target: string;

  constructor(raw_json: any) {
    this.gid = raw_json.gid;
    this.url = raw_json.url;
    this.url_target = raw_json.url_target;
  }

}


