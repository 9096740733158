import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAddressRoutingModule } from './address-routing.module';
import { WidgetAddressComponent } from './address.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetAddressRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetAddressComponent
  ],
  declarations: [WidgetAddressComponent]
})
export class WidgetAddressModule { }
