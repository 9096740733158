import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileModule } from '../file/file.module';
import { AttachmentsModule } from '../layout/attachments/attachments.module';
import { BacklinkModule } from '../layout/backlink/backlink.module';
import { FilesRoutingModule } from './files-routing.module';
import { FilesComponent } from './files.component';

@NgModule({
  imports: [
    CommonModule,
    FilesRoutingModule,
    AttachmentsModule,
    BacklinkModule,
    FileModule
  ],
  exports: [
    FilesComponent
  ],
  declarations: [FilesComponent]
})
export class FilesModule { }
