import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuModuleComponent } from './menu.component';


const routes: Routes = [
  { path: '', component: MenuModuleComponent ,
    children: [
      { path: ':id', loadChildren: () => import('./entry/entry.module').then(m => m.MenuEntryModule) },
      { path: ':id/:cart', loadChildren: () => import('./entry/entry.module').then(m => m.MenuEntryModule) },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuModuleRoutingModule { }
