import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { PmsCiCoService } from '../../../../modules/pms/cico.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: [
    '../input-styles.scss',
    './textarea.component.scss'
  ]
})
export class TextareaComponent implements OnInit, OnChanges {

  @ViewChild('input') input;
  @Input('ngModel') inputValue: string;
  @Input() type: 'text' | 'number' = 'text';
  @Input() required: boolean;
  @Input() description!: string;
  @Input() placeholder!: string;
  @Input() name: string;
  @Input() tooltip?: string;
  @Input() disabled: boolean;
  @Input() submited: boolean;

  @Output('ngModelChange') inputValueChange: EventEmitter<string> = new EventEmitter<string>();

  inputId: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private cicoService: PmsCiCoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submited?.firstChange) {
      this._cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder ? this.placeholder : this.name;
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
  }

  valueChange() {
    this.inputValueChange.emit(this.inputValue);
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }
}
