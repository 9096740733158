import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PushNotificationService } from 'services/push_notification.service';

@Component({
  selector: 'app-push-form',
  templateUrl: './form.component.html'
})

export class PushFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  showHint: boolean;

  constructor(private pushNotificationService: PushNotificationService) { }

  ngOnInit() {
    this.subscriptions.add(this.pushNotificationService.notificationState.pipe(filter(Boolean)).subscribe(state => {
      this.showHint = state !== 'granted';
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
