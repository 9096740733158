<div class="uploader">
  <input class="hidden-field" [id]="'image_' + key + '_' + id" [name]="'image_' + key + '_' + id" [(ngModel)]="model[key]" [required]="required" #fileInput="ngModel">
  <input type="file" [id]="'image_helper_' + key + '_' + id" [name]="'image_helper_' + key" [accept]="accept" (change)="onFileChange($event)" *ngIf="!webcam">
  <label [for]="'image_helper_' + key + '_' + id">
    <img id="file-image" [src]="(model[key].startsWith('data:image') ? model[key] : 'assets/images/pdf.png') | safe" alt="Preview" *ngIf="model[key]">
    <span (click)="webcam ? startCapture() : null" *ngIf="!model[key] && !loading">{{(webcam ? 'service.image.webcam' : 'service.image.caption') | translate}}</span>
    <app-loader [small]="true" *ngIf="loading && !model[key]"></app-loader>
  </label>
  <div class="bottom">
    <div *ngIf="!model[key]"><a (click)="startCapture()" [hidden]="model[key]"><i class="fas fa-camera"></i> {{(webcam ? 'service.check_in.terminal.webcam.start' : 'service.check_in.terminal.webcam.take') | translate}}</a></div>
    <div *ngIf="model[key]"><a (click)="resetImage()" [hidden]="!model[key]" translate>misc.reset</a></div>
    <div *ngIf="removable"><a class="remove-btn link" (click)="delete.emit()" translate>misc.remove</a></div>
  </div>
</div>
