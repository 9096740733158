<div class="info-screen">
  <svg viewBox="0 0 278 278" fill="none">
    <path class="main-stroke" d="M139 272.5C65.3938 272.5 5.5 212.606 5.5 139C5.5 65.3938 65.3938 5.5 139 5.5C212.606 5.5 272.5 65.3938 272.5 139C272.5 212.606 212.606 272.5 139 272.5Z" fill="white" stroke-width="11"/>
    <path class="main-fill" d="M126.843 179.374C123.284 182.887 118.48 184.848 113.481 184.83C108.481 184.813 103.691 182.817 100.158 179.28L68.6799 145.888C63.4757 140.684 64.7868 133.563 70.1803 128.547C75.2899 123.627 83.2683 125.628 88.1887 130.548L113.5 155.718L174.345 94.8734C179.454 89.7636 187.687 89.7636 192.797 94.8734L193.175 95.2519C198.285 100.362 198.285 108.689 193.081 113.799L126.843 179.374Z"/>
  </svg>
  <span class="message" [innerHTML]="safeHtml"></span>
  <div class="room-container" *ngIf="highlightedText">
    <div class="room-number">
      <span translate>pms_door.encoder.room</span>&nbsp;
      <span *ngIf="highlightedText" class="main-text" translate>{{ highlightedText }}</span>
    </div>
  </div>
</div>
