import { Component, Input } from '@angular/core';
import { UserActionType } from 'pms_enums';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-pms-kiosk-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: []
})

export class PmsKioskReservationComponent {

  @Input() reservation: any;

  constructor(
    public cicoService: PmsCiCoService
  ) {}

  back() {
    this.cicoService.navigate(UserActionType.cancel);
    this.cicoService.setProcess(null);
  }
}
