import { Directive, forwardRef, OnDestroy } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { ApiService } from 'api_service';
import { OfflineService } from 'services/offline.service';

@Directive({
  selector: '[placevalidator][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => PlaceValidator),
      multi: true
    }
  ]
})
export class PlaceValidator implements Validator, OnDestroy {
  subscriptions: Subscription = new Subscription();
  offline: boolean;

  constructor(private api: ApiService,
              private offlineService: OfflineService) {
    this.subscriptions.add(this.offlineService.offline.subscribe(offline => {
      this.offline = offline;
    }));
  }

  validate(c: AbstractControl) {
    if (c.value?.split('.')?.pop()?.length) {
      return this.placeValidator(c.value);
    } else {
      return of(null);
    }
  }

  placeValidator(input) {
    return new Promise((resolve, reject) => {
      if (!this.offline) {
        this.getPlace(input).subscribe(
          success => {
            if (!success['place']['valid']) {
              resolve({
                placevalidator: {
                  valid: false
                }
              });
            } else {
              resolve(null);
            }
          }, () => {
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  getPlace(id): Observable<Object> {
    return this.api.get('place/' + id);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
