import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { SuggestionsService } from 'widgets/suggestions/suggestions.service';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { EventModuleService } from '../../event.service';

@Component({
  selector: 'app-event-list-parent',
  templateUrl: './parent.component.html'
})

export class EventListParentComponent extends ServiceParentDirective implements OnInit, OnDestroy {
  mod = 'event';

  constructor(public globals: Globals,
              protected route: ActivatedRoute,
              protected businessService: BusinessService,
              protected sanitizer: DomSanitizer,
              private eventService: EventModuleService) {
    super(globals, route, businessService, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit(this.eventService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
