import { Api } from './api';
import { Article } from './article';
import { Field } from './field';
import { File } from './file';
import { Link } from './link';
import { List } from './list';
import { Timetable } from './timetable';

export class Module {
  available: boolean;
  type: string;
  name: string;
  description: string;
  icon: string;
  image: string;
  info: string;
  list_mode: string;
  orderable: boolean;
  task_module: boolean;
  pretty_mode: string;
  pretty_view_mode: string;
  room_validation: string;
  settings: any;
  link: Link;
  list: List;
  article: Article;
  files: File[] = [];
  fields: Field[] = [];
  attachments: any;
  timetable: Timetable;
  module_api: Api;
  moods: string[];
  medias: any;
  gps_radius: number;
  search: boolean;

  constructor(mod?: any) {
    this.available = mod?.available;
    this.type = mod?.type;
    this.name = mod?.name;
    this.description = mod?.description;
    this.icon = mod?.icon;
    this.image = mod?.media ? mod.media['image'] : null;
    this.info = mod?.info;
    this.list_mode = mod?.list_mode;
    this.orderable = mod?.orderable;
    this.task_module = mod?.task_module;
    this.pretty_mode = mod?.pretty_mode;
    this.pretty_view_mode = mod?.pretty_view_mode;
    this.room_validation = mod?.room_validation;
    this.settings = mod?.settings;
    this.link = mod?.link ? new Link(mod.link) : null;
    this.timetable = mod?.timetable ? new Timetable(mod.timetable) : null;
    this.article = mod?.article ? new Article(mod.article) : null;
    this.list = mod?.list ? new List(mod.list) : null;
    this.module_api = mod?.module_api ? new Api(mod.module_api) : null;
    this.files = mod?.pdf?.attachments;
    this.attachments = mod?.attachments;
    this.medias = mod?.medias;
    this.moods = mod?.moods;
    this.gps_radius = mod?.gps_radius;
    this.search = mod?.searchbox;

    mod?.fields?.forEach(field => {
      this.fields.push(new Field(field['field']));
    });
  }

  pmsType(): string {
    return this.type.replace('pms_', '');
  }

  copyData(data?: Partial<Module>) {
    Object.assign(this, data);
    return this;
  }

  field(name) {
    return this.fields.find(f => f.identifier === name);
  }

  usedFields(): Array<string> {
    return ['border_crossing', 'border_crossing_date', 'coming_from', 'destination', 'reservation_id', 'time_of_arrival', 'time_of_departure', 'travel_purpose'];
  }

  usedSubFields(): Array<string> {
    return ['booking', 'arrival', 'departure', 'unit', 'total_price'];
  }

  usableFields(useDefault = false): Array<Field> {
    const kind = useDefault ? '' : 'default';
    return this.fields.filter(field => field.group !== kind && !this.usedFields().includes(field.identifier));
  }

  service_disabled(): boolean {
    return this.timetable && !this.timetable.now || false;
  }
}
