import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuListParentComponent } from './parent.component';


const routes: Routes = [
  { path: '', component: MenuListParentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuListParentRoutingModule { }
