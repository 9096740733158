import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GroupSocialRoutingModule } from './group_social-routing.module';
import { GroupSocialComponent } from './group_social.component';

@NgModule({
  imports: [
    CommonModule,
    GroupSocialRoutingModule,
    TranslateModule
  ],
  exports: [
    GroupSocialComponent
  ],
  declarations: [GroupSocialComponent]
})
export class GroupSocialModule { }
