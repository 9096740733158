import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html'
})
export class NewsletterComponent implements OnInit {
  module: Module;
  rightSide = false;

  constructor(public globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('newsletter').then((mod) => {
      this.module = mod;
      this.rightSide = this.module.medias && this.module.medias.length > 0;
    }).catch(() => {});
  }

}
