import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgStringPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { BasketWidgetModule } from '../basket/widget/widget.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { MenuListAllComponent } from './list/all/all.component';
import { MenuListParentModule } from './list/parent/parent.module';
import { MenuModuleRoutingModule } from './menu-routing.module';
import { MenuModuleComponent } from './menu.component';
import { BackgroundModule } from 'common/background/background.module';

@NgModule({
  imports: [
    CommonModule,
    MenuModuleRoutingModule,
    TranslateModule,
    AttachmentsModule,
    SharedSwiperModule,
    BasketWidgetModule,
    WidgetAdvertModule,
    FormsModule,
    FilterPipeModule,
    NgStringPipesModule,
    BacklinkModule,
    ModuleTitleModule,
    BackgroundModule,
    MenuListParentModule
  ],
  exports: [
    MenuModuleComponent
  ],
  declarations: [MenuModuleComponent, MenuListAllComponent],
  providers: [
    ServiceAllDirective
  ]
})
export class MenuModuleModule { }
