import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FooterService {
  openSubj: BehaviorSubject<boolean> = new BehaviorSubject(null);
  openObservable: Observable<boolean> = this.openSubj.asObservable();

  constructor() {}
}
