import { PaymentProcess, PmsModType } from 'pms_enums';
import { Business } from '../business';
import { Module } from '../module';
import { GenericCheckIn } from './generic_check_in';
import { GenericCheckOut } from './generic_check_out';
import { PmsFolio, PmsFolioInfo } from './pms_folio';
import { PmsReservation } from './pms_reservation';
import { v4 as uuidv4 } from 'uuid';

export class GenericData {
  uuid: string;
  code: string;
  module: Module;
  business: Business;
  incident: GenericCheckIn | GenericCheckOut;
  reservation: PmsReservation;
  confirmEmail: string;
  countries: Array<any>;
  countriesNat: Array<any>;
  statesUs: Array<any>;
  statesCa: Array<any>;
  startTime: number;
  needsPassport: boolean;
  paymentProcess: PaymentProcess;
  folioInfo: PmsFolioInfo = new PmsFolioInfo();
  folioAddresses: Array<any> = [];
  preAuth: any;
  payment: boolean;
  done: boolean;
  authChosen: boolean;
  helper: any;
  step: string;
  readonly blank: boolean;

  constructor(data?: any) {
    this.uuid = data?.uuid || uuidv4();
    this.code = data?.business?.code;
    this.module = data?.module;
    this.business = data?.business;
    this.incident = data?.incident;
    this.confirmEmail = data?.confirmEmail;
    this.countries = GenericData.mapCountries(data?.countryData?.countries);
    this.countriesNat = GenericData.mapCountries(data?.countryData?.nationalities);
    this.statesUs = GenericData.mapCountries(data?.countryData?.states_us);
    this.statesCa = GenericData.mapCountries(data?.countryData?.states_ca);
    this.startTime = data?.startTime || Date.now();
    this.folioInfo = new PmsFolioInfo();
    this.paymentProcess = data?.paymentProcess || PaymentProcess.nothing;
    this.needsPassport = data?.incident?.reservation?.passport === undefined;
    this.authChosen = data?.authChosen;
    this.blank = !data?.incident?.reservation?.booking_id;
  }

  copyData(data: Partial<GenericData>) {
    Object.assign(this, data);
    return this;
  }

  isReservationModule(): boolean {
    return this.module?.type === PmsModType.reservation;
  }

  logable(): boolean {
    return this.incident?.reservation?.id && !this.isReservationModule();
  }

  setFolios(folios: PmsFolio[]) {
    this.incident.reservation.allFolios = folios;
    if (this.business.usePms('suite8')) {
      const list = folios.filter(folio => folio.valid())
      this.incident.reservation.folios = list.length ? list : new Array(folios[folios.length - 1]);
    } else {
      this.incident.reservation.folios = folios.filter(folio => folio.valid());
    }
    this.setFolioAdresses();
  }

  setFolioAdresses() {
    this.incident.reservation.folios.forEach(folio => {
      this.folioAddresses.push({number: folio.number, address: folio.address});
    });
  }

  static mapCountries(countries) {
    return countries?.map(country => {
      return {value: country.code, name: country.name, phone: country.phone, flag: country?.media?.flag};
    });
  }
}
