import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmsFolioComponent } from './folio.component';


const routes: Routes = [
  { path: '', component: PmsFolioComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PmsFolioRoutingModule { }
