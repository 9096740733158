<div class="input-container" [ngClass]="{ 'valid': !required || isOptionSelected(), 'invalid': required && !isOptionSelected() }">
  <div class="checkbox-container">
    <input #input="ngModel" [ngModel]="isOptionSelected()" type="checkbox" [id]="inputId" [name]="name" class="visually-hidden" [required]="required" [attr.data-cy]="name">
    <div class="checkbox-rd" [class.checked]="isOptionSelected()" (click)="changeOptionState()">
      <i class="fa-solid fa-check"></i>
    </div>
    <div class="text-container">
      <span (click)="changeOptionState()">{{ option }}</span>
      <span *ngIf="required" class="required-asterisk">*</span>
      <span *ngIf="tooltip" class="info-wrapper" [tooltipInfo]="tooltip"><i class="fa-solid fa-circle-info"></i></span>
    </div>
  </div>

  <label class="error-label" [class.visible]="submitted && required && !isOptionSelected()">
    <i class="fa-solid fa-circle-xmark"></i>
    <span translate>validation.required</span>
  </label>
</div>
