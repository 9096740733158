<div id="modal" (click)="hide()" *ngIf="a2hsService.visible"></div>
<div class="a2hs-bar" [ngClass]="a2hsService.classList" #container>
  <div class="inner" *ngIf="a2hsService.content">
    <h3 [innerHTML]="'a2hs.browser.' + a2hsService.text | translate"></h3>

    <button class="button main-bg" (click)="install()" translate *ngIf="a2hsService.a2hsBrowser">misc.add_android</button>

    <ul *ngIf="!a2hsService.a2hsBrowser">
      <li><div [innerHTML]="'a2hs.icons.' + a2hsService.browser + '.choose' | translate"></div> <div translate>misc.choose</div></li>
      <li *ngIf="a2hsService.browser === 'opera'"><div class="opera-overflow" translate >a2hs.add_to</div></li>
      <li><div [innerHTML]="'a2hs.icons.' + a2hsService.browser + '.homescreen' | translate"></div> <div [innerHTML]="'a2hs.text.' + a2hsService.browser | translate"></div></li>
    </ul>
    <button class="button ghost" (click)="hide()" translate>a2hs.remind_me_later</button>
  </div>
</div>
