import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { QuantityModule } from 'shared/forms/quantity/quantity.module';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { WishDirectRoutingModule } from './direct-routing.module';
import { WishDirectComponent } from './direct.component';

@NgModule({
  imports: [
    CommonModule,
    WishDirectRoutingModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    TranslateModule,
    ModuleTitleModule,
    FormsModule,
    FormSelectModule,
    QuantityModule,
    FormFieldsModule,
    FormPlaceModule,
    FormPhoneFieldModule,
    SendCancelModule,
    EmailValidatorModule
  ],
  exports: [
    WishDirectComponent
  ],
  declarations: [WishDirectComponent]
})
export class WishDirectModule { }
