import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LandingPage } from 'models/landingPage';
import { RouterService } from 'services/router.service';
import { StorageService } from 'services/storage.service';
import { StyleService } from 'services/style.service';
import { StraivService } from 'straiv/straiv.service';
import { LanguageService } from '../language/language.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})

export class LandingpageComponent implements OnInit {
  landingPage: LandingPage;
  content: SafeHtml;
  medias: [];

  constructor(private styleService: StyleService,
              private routerService: RouterService,
              private storageService: StorageService,
              private _landingPage: LandingPage,
              private sanitizer: DomSanitizer,
              private languageService: LanguageService,
              private straivService: StraivService) {
  }

  ngOnInit(): void {
    this.routerService.getCurrentCode().subscribe(code => {
      if (code) {
        this.storageService.getItem('current_landing', code).then(landingPage => {
          this.setLandingpage(landingPage);
        }).catch(() => {
          this.straivService.getLandingpPage(code).subscribe(landingPage => {
            this.setLandingpage(landingPage);
          });
        });
      }
    });
  }

  setLandingpage(landingPage) {
    this.landingPage = this._landingPage;
    this.landingPage.setLanding(landingPage);
    this.styleService.set_styles(this.landingPage);
    this.languageService.setLocale(this.landingPage.locale, false);
    this.medias = this.landingPage.article.medias;
    this.content = this.sanitizer.bypassSecurityTrustHtml(landingPage.article.content);
  }
}
