import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-address',
  templateUrl: './address.component.html'
})
export class WidgetAddressComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;

  constructor(private businessService: BusinessService,
              public globals: Globals) { }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
