import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class SuggestionsService {

  constructor(private api: ApiService) {}

  getWidget(): Observable<Object> {
    return this.api.get('widgets/suggestions');
  }

  getEntries(mod): Observable<Object> {
    return this.api.get('widgets/suggestions/' + mod);
  }

}
