import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { environment } from 'environments/environment';
import { LogType } from 'global_enums';
import { PmsService } from 'modules/pms/pms.service';
import { PmsPaymentService } from 'payment_service';
import { Observable, Subject, Subscription } from 'rxjs';
import Bugsnag from '@bugsnag/js';
import { filter, take } from 'rxjs/operators';
import { Guest } from 'models/guest';
import { GuestService } from '../guest.service';
import { StorageService } from '../storage.service';
import { EncoderService } from './encoder.service';
import { JourneyService } from './journey.service';
import { NavigationService } from './navigation.service';
import { TeaserService } from './teaser.service';
import { KioskService } from './wizard/kiosk.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

const WS_ENDPOINT = environment.cable_url;

@Injectable({providedIn: 'root'})
export class WebsocketService implements OnDestroy {

  tries = 0;
  subscriptions: Subscription = new Subscription();
  token: string;
  alreadyRequested = false;
  showConflict: boolean;
  currentStatus: string;
  socketConnected: WebSocketSubject<any>;

  statusSubj: Subject<any> = new Subject<string>();
  status: Observable<any> = this.statusSubj.asObservable();

  onlineSubj: Subject<any> = new Subject<boolean>();
  online: Observable<any> = this.onlineSubj.asObservable();

  constructor(private navigationService: NavigationService,
              private apiService: ApiService,
              private teaserService: TeaserService,
              private journeyService: JourneyService,
              private storageService: StorageService,
              private guestService: GuestService,
              private kioskService: KioskService,
              private paymentService: PmsPaymentService,
              private encoderService: EncoderService,
              private pmsService: PmsService,
              private globals: Globals) {
  }

  public init(): void {
    if (!this.socketConnected || this.socketConnected.closed) {
      this.connect().then((socket: WebSocketSubject<any>) => {
        this.socketConnected = socket;
        this.socketConnected.subscribe({
          next: (msg) => {
            if (msg.message?.topic) {
              this.receivedMessage(msg);
            }
          },
          error: (err) => {
            let wait = this.tries > 60 ? 5000 : 1000;
            setTimeout(() => {
              let message;
              if (err instanceof CloseEvent) {
                if (err.reason) {
                  message = `SocketCloseError: ${JSON.stringify({type: err.type, code: err.code, reason: err.reason})}`;
                }
              } else if (Number.isInteger(this.tries / 5)) {
                message = `SocketConnectionError: Can't be reestablished`;
              }
              if (message?.length) {
                Bugsnag.notify(new Error(message));
              }
              if (this.tries >= 150 && !this.taskModule()) {
                this.globals.reload();
              } else {
                this.init();
              }
            }, wait);
          }
        });
        this.sendMessage('subscribe');
      });
    }
  }

  private connect(): Promise<WebSocketSubject<any>> {
    return new Promise((resolve, _reject) => {
      this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
        const params = 'token=' + guest.token + '&place_id=' + guest.place?.id + '&guest_id=' + guest.id;
        const socket = webSocket({
          url: WS_ENDPOINT + '?' + params,
          openObserver: {
            next: () => {
              this.globals.log('Websocket connected', LogType.info, true);
              this.onlineSubj.next(true);
              this.tries = 0;
              this.connected();
            }
          },
          closeObserver: {
            next: () => {
              this.globals.log('Websocket disconnected', LogType.error, true);
              this.onlineSubj.next(false);
              this.socketConnected = undefined;
              this.tries++;
              this.init();
            }
          }
        });
        resolve(socket);
      });
    });
  }

  sendMessage(command: string, data?: any) {
    const ident = {command: command, identifier: JSON.stringify({channel: 'GuestappChannel'})};
    const message = {data: JSON.stringify(data || {})};
    const payload = {...ident, ...message};
    this.socketConnected?.next(payload);
  }

  receivedMessage(msg: any) {
    const message = msg.message;
    const data = typeof message?.data === 'string' ? JSON.parse(message.data) : message?.data;
    if (data || ['reload', 'conflict'].includes(message.topic)) {
      this.action(message.topic, data);
    }
  }

  checkLicense(): any {
    this.storageService.getItem('connected_at', this.globals.code).then(storage => {
      const connected_at = storage?.connected_at?.toString() || 'new';
      this.tellTheServer(connected_at);
    }).catch(() => {
      this.tellTheServer('new');
    });
  }

  disconnect() {
    this.socketConnected?.complete();
    this.socketConnected = undefined;
    this.subscriptions.unsubscribe();
  }

  tellTheServer(connected_at: string): any {
    this.apiService.get(`kiosk/${connected_at}`).subscribe((success: any) => {
      this.init();
      this.alreadyRequested = true;
      this.storageService.setItem('connected_at', {connected_at: success.connected_at, code: this.globals.getCode()});
      this.showConflict = false;
    }, (err) => {
      if (err.status !== 400) {
        if ((connected_at === 'new' && !this.alreadyRequested) || connected_at !== 'new') {
          this.showConflict = true;
        }
        this.alreadyRequested = false;
      }
    });
  }

  confirm() {
    this.apiService.delete('kiosk/conflict', {}).subscribe((success: any) => {
      this.init();
      const connected_at = success.connected_at.toString() || 'new';
      this.storageService.setItem('connected_at', {connected_at: connected_at, code: this.globals.getCode()});
      this.showConflict = false;
    }, () => {});
  }

  connected() {
    if (this.globals.place.wizard) {
      this.observeStatus();
      this.statusSubj.next('idle');
    }
  }

  taskModule(): boolean {
    return ['pms_check_in', 'pms_check_out', 'pms_door'].includes(this.globals._module?.type);
  }

  private action(topic: string, data: any) {
    try {
      if (this.globals.business) {
        switch (topic) {
          case 'navigation':
            this.navigationService.setNavigation(data);
            break;
          case 'teaser':
            this.teaserService.setTeaser(data);
            break;
          case 'journey':
            this.journeyService.setJourney(data);
            break;
          case 'place':
            this.guestService.setPlace(data);
            break;
          case 'business':
            this.globals.business.setBusiness(data.business).save();
            break;
          case 'network':
            this.globals.business.setIpModules(data).save();
            break;
          case 'wizard':
            this.kioskService.receiveData(data);
            break;
          case 'reload':
            this.globals.reload();
            break;
          case 'kill':
            this.disconnect();
            break;
          case 'conflict':
            this.kioskService.checkConflict(data, this.globals.business.code);
            break;
          case 'payment':
            this.paymentService.setPayment(data);
            this.statusSubj.next('in_use');
            break;
          case 'encoder':
            this.encoderService.receiveData(data);
            break;
          case 'passport_auth':
            this.pmsService.passportAuthSubj.next(data);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.globals.log(e, LogType.error);
    }
  }

  observeStatus() {
    this.subscriptions.add(this.globals.taskObservable.subscribe(taskModule => {
      this.statusSubj.next(taskModule ? 'in_use' : 'idle');
    }));

    this.subscriptions.add(this.status.subscribe((status: string) => {
      if (status !== this.currentStatus) {
        this.currentStatus = status;
        this.sendMessage('message', {topic: 'change_status', status: status});
      }
    }));
  }

  ngOnDestroy(): void {
    this.disconnect();
  }
}
