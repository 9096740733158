import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { MenuModuleService } from './menu.service';

@Component({
  selector: 'app-menu-module',
  templateUrl: './menu.component.html'
})

export class MenuModuleComponent implements OnInit {
  module: Module;
  cart: string[];
  disabled: boolean;

  constructor(private menuService: MenuModuleService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('menu', false).then((mod) => {
      this.module = mod;
      this.disabled = this.module.service_disabled();
    }).catch(() => {});
  }

}
