import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { ModalService } from 'common/modal/modal.service';
import { DomModal } from 'models/dom_modal';
import { Module } from 'models/module';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { NewsItemComponent } from './modal/news_item.component';
import { NewsService } from './news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  news: any[] = [];

  public slideConfig: any = {
    loop: true,
    autoplay: true,
    hideArrows: false,
    delay: 7000
  };
  done: boolean = false;

  constructor(private newsService: NewsService,
              private businessService: BusinessService,
              private globals: Globals,
              private cdr: ChangeDetectorRef,
              private modalService: ModalService) {}

  ngOnInit() {
    this.newsService.getModule().then((mod: Module) => {
      if (mod.settings && mod.settings.swiper_enabled) {
        this.slideConfig.delay = Number(mod.settings.swiper_trigger_value) * 1000;
      } else {
        this.slideConfig.autoplay = false;
      }

      this.subscriptions.add(this.businessService.currentLocale.subscribe(() => {
        this.subscriptions.add(this.newsService.getAllNews().subscribe((success: any) => {
          this.news = success.news.filter(n => n.state === 'active');
          this.changestatus();
          const highlight = success.news.find(n => n.highlight);
          this.changestatus();

          if (highlight && this.globals.guest?.popups()) {
            this.openNews(highlight.id);
          }

          if (this.news?.length === 1) {
            this.slideConfig.loop = false;
            this.slideConfig.hideArrows = true;
          } else {
            this.slideConfig.hideArrows = false;
          }
        }, () => {}));
      }));
    }).catch(() => {});
  }

  /*
  ! The event paramater, we don't need it any more
  ? We don't need the index variable any more because, We open the news by the real id directly.
   */
  openNews(id) {
    this.newsService.getNews(id).subscribe((newsEntry: any) => {
      this.newsService.newsEntrySubj.next(newsEntry);
      const body = new DomModal();
      const updateNews = newsEntry.entry.body?.includes('caches.delete');

      body.title = this.title(newsEntry.entry);
      body.redirect = false;
      body.update = updateNews;
      body.closeable = !updateNews;
      body.component = NewsItemComponent;
      this.modalService.open(body);
    });
  }

  title(entry) {
    return entry.advert ? `<i class="ad-icon"></i>${entry.title}` : entry.title;
  }

 changestatus() {
    of(true).pipe(delay(1000)).subscribe(() => {
      this.cdr.detectChanges()
      this.cdr.markForCheck()
      this.done = true;
    })
 }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
