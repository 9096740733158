import { Component, OnInit } from '@angular/core';
import { Timetable } from 'models/timetable';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';

@Component({
  selector: 'app-available',
  templateUrl: './available.component.html'
})

export class AvailableComponent implements OnInit {
  timetable: Timetable;

  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  constructor(public globals: Globals,
              private modalService: ModalService) { }

  ngOnInit(): void {
    this.timetable = this.globals._module.timetable;
  }

  hasAfternoon(day) {
    return day.afternoon_from?.length && day.afternoon_from !== '--:--' && day.afternoon_till?.length && day.afternoon_till !== '--:--';
  }

  close() {
    this.modalService.close();
  }
}
