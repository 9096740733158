<form (ngSubmit)="onSubmit()">
  <fieldset *ngIf="categories">
    <div class="form-box">
      <div class="checkbox" *ngFor="let item of categories">
        <input type="checkbox" class="option-input checkbox" id="extra_{{item.id}}" name="extra" [checked]="activated.includes(item.id)" [value]="item.id" (click)="check(item.id)" />
        <label for="extra_{{item.id}}">{{item.name}}</label>
      </div>
    </div>
  </fieldset>
  <app-form-send-cancel [confirm]="confirm_name" [status]="globals.send_cancel_disabled"></app-form-send-cancel>
  <p translate>push.info</p>
</form>
