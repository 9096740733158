<div *ngIf="folio">
  <div class="folio-container" [class.transparent-bg]="['create', 'select'].includes(cicoService.folioAddressStep) || !positions.length" [attr.data-folio]="folio.number">
    <div [class.folio-header]="cicoService.folioAddressStep =='folio'">
      <span class="address">
        <form #folioForm="ngForm" (ngSubmit)="saveAddres()">
          <app-pms-invoice-address-form [folio]="folio" [data]="data" [number]="number" [newDesign]="true" [staticAddress]="!collectAddress" (dataEvent)="receiveDataFromChild($event)"></app-pms-invoice-address-form>
        </form>
      </span>
      <span class="folio-count main-text" *ngIf="cicoService.folioAddressStep =='folio'"> {{ number+1 }} / {{ total }} </span>
    </div>
    <ng-container *ngIf="positions.length">
      <div *ngIf="cicoService.folioAddressStep =='folio'">
        <div class="list-header flexbox">
          <span translate>service.folios.services</span>
          <span translate>shopping_card.price</span>
        </div>

        <ng-container *ngFor="let spending of positions; let i = index">
          <div *ngIf="i < MIN_ITEMS || showAll" class="list-row flexbox">
            <div>
              <span class="description">{{ spending.name }}</span>
              <span class="date main-text">{{ spending.date | date }}</span>
            </div>
            <span class="price">{{ spending.gross | currency: business.currency }}</span>
          </div>
        </ng-container>

        <div class="expand-container" [class.hidden]="positions.length <= MIN_ITEMS" (click)="showAll = !showAll">
          <span class="expand-label" *ngIf="showAll">
             {{'service.folios.show_less' | translate}} <i class="fas fa-chevron-up main-text "></i>
          </span>
          <span class="expand-label" *ngIf="!showAll">
            {{'service.folios.show_all' | translate:{length: positions.length - MIN_ITEMS} }} <i class="fas fa-chevron-down main-text "></i>
          </span>
        </div>

        <div class="total-container">
          <div *ngFor="let vat of folio.vat" class="flexbox">
            <span *ngIf="!vat.pseudo">{{'shopping_card.vat' | translate}} {{vat.percent}}%:</span>
            <span *ngIf="vat.pseudo">{{'shopping_card.included_vat' | translate}}:</span>
            <span>{{vat.sum || 0 | currency: business.currency}}</span>
          </div>
          <div class="flexbox summary">
            <span translate>shopping_card.total_price_without_amount</span>
            <span>{{folio.sum || 0 | currency: business.currency}}</span>
          </div>
          <div class="flexbox summary" *ngIf="(folio.balance || 0) > 0">
            <span translate>shopping_card.unpaid_amount</span>
            <span>{{folio.balance | currency: business.currency}}</span>
          </div>
        </div>
      </div>

      <div class="buttons-container" *ngIf="cicoService.folioAddressStep == 'folio'">
        <a class="info" (click)="download($event)" [href]="folio.url" target="_blank" [class.invisible]="!folio.url?.length"> <img src="assets/images/pdf.svg" class="pdf" [alt]="'service.folios.download' | translate"> <span translate>service.folios.download</span> </a>

        <ng-container *ngIf="folio.check === 'paid' && (folio.balance === 0 || folio.existing_payments); else pendingOrFailed">
          <div class="info">
            {{'service.payment.paid' | translate}}
            <svg viewBox="0 0 278 278" fill="none">
              <path d="M138.938 272.375C65.3657 272.375 5.5 212.509 5.5 138.938C5.5 65.3657 65.3657 5.5 138.938 5.5C212.509 5.5 272.375 65.3657 272.375 138.938C272.375 212.509 212.509 272.375 138.938 272.375Z" fill="white" stroke="#00DFCA" stroke-width="11" />
              <path d="M126.786 179.294C123.229 182.804 118.427 184.765 113.43 184.747C108.432 184.729 103.645 182.735 100.113 179.199L68.6491 145.823C63.4472 140.621 64.7578 133.502 70.1488 128.49C75.2562 123.571 83.231 125.571 88.1491 130.49L113.449 155.648L174.266 94.8306C179.374 89.7231 187.603 89.7231 192.71 94.8306L193.089 95.209C198.196 100.316 198.196 108.64 192.994 113.747L126.786 179.294Z" fill="#00DFCA" />
            </svg>
          </div>
        </ng-container>

        <ng-template #pendingOrFailed>
          <div class="info" *ngIf="folio.check === 'pms_failed' || folio.check === 'pending'">
            <ng-container *ngIf="pendingDynamicMsg?.length && folio.check === 'pending'; else pmsFailedCase">
              {{pendingDynamicMsg}} <i class="fa fa-cog fa-spin fa-fw"></i>
            </ng-container>

            <ng-template #pmsFailedCase *ngIf="folio.check === 'pms_failed'">
              {{ui_messages.paid_pms_failed?.content}} <i class="fas fa-times text-error"></i>
            </ng-template>
          </div>
        </ng-template>

        <div class="payment" *ngIf="!cicoService.addressChange && cicoService.folioAddressStep == 'folio' && showPayButton()">
          <ng-container *ngIf="showHints">
            <p class="pay-info" [innerHtml]="ui_messages.pay_to_continue?.content" *ngIf="folio.payment === 'required'"></p>
          </ng-container>
          <button class="btn-filled" [class.small-width]="true" (click)="openPayment($event)" [disabled]="!validStaticAddress()">{{'service.payment.pay_now' | translate: folio}}</button>
        </div>
      </div>
    </ng-container>
  </div>

  <app-pms-simple-footer *ngIf="cicoService.folioAddressStep !== 'folio'" [nextBtnText]="'misc.save'" [showBack]="true" [showNext]="cicoService.folioAddressStep =='create'" [disableBack]="false" [disableNext]="!validStaticAddress()" (prevClicked)="back()" (nextClicked)="saveAddres()"></app-pms-simple-footer>
</div>
