export class Timetable {
  enabled: boolean;
  open: boolean;
  now: boolean;
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunday: any;
  next_booking_day: any;

  constructor(raw_json: any) {
    this.enabled = raw_json?.enabled;
    this.open = raw_json?.open;
    this.now = raw_json?.now;
    this.monday = raw_json?.monday;
    this.tuesday = raw_json?.tuesday;
    this.wednesday = raw_json?.wednesday;
    this.thursday = raw_json?.thursday;
    this.friday = raw_json?.friday;
    this.saturday = raw_json?.saturday;
    this.sunday = raw_json?.sunday;
    this.next_booking_day = raw_json?.next_booking_day;
  }
}
