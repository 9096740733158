import { Component, OnInit } from '@angular/core';
import { BusinessGroup } from 'models/business_group';
import { Module } from 'models/module';
import { BusinessGroupService } from 'services/business_group.service';
import { Globals } from 'base';
import { GroupPortfolioService } from '../group_portfolio.service';

@Component({
  selector: 'app-group-portfolio',
  templateUrl: './group_portfolio.component.html'
})
export class GroupPortfolioComponent implements OnInit {
  business_group: BusinessGroup;
  module: Module;
  items: string[];
  rightSide = false;
  showMap = false;
  showSocial = false;

  constructor(public globals: Globals,
              private portfolio: GroupPortfolioService,
              private businessGroupService: BusinessGroupService) { }

  ngOnInit() {
    this.businessGroupService.getBusinessGroup().subscribe(
      success => {
        this.business_group = new BusinessGroup(success['business_group']);
        this.globals.getModule('group_portfolio').then((mod) => {
          this.module = mod;
          this.module.name = this.business_group.name;
          this.rightSide = this.module.medias && this.module.medias.length > 0;
          this.showMap = this.module.settings.show_map;
          this.showSocial = this.module.settings.show_social;
        }).catch(() => {console.log('problem'); });
      }, () => {console.log('problem'); }
    );

    this.portfolio.getMenu().subscribe(
      success => {
        this.items = success['entries'];
      }, () => {}
    );
  }

}
