<h2 class="title main-text" [innerHTML]="ui_messages.terminal_pay_in_progress?.content"></h2>
<app-loader [included]="true" [text]="''"></app-loader>

<p class="text" translate>service.payment.terminal.ready</p>
<p class="text" translate>service.payment.terminal.accepted</p>

<ul>
  <li *ngFor="let kind of payment.available_methods"><img [src]="'assets/images/credit_card/' + kind + '.png'" [alt]="kind" /></li>
</ul>

<p class="text" *ngIf="apaleoPay" translate>service.payment.terminal.abort_disabled</p>
