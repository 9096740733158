import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { UserActionType } from 'pms_enums';
import { PmsCiCoService } from 'cico_service';
import { Globals } from 'base';

@Component({
  selector: 'app-pms-booking',
  templateUrl: './booking.component.html',
  styleUrls: []
})

export class PmsBookingComponent implements OnInit {

  bookingModule: Module;

  constructor(
    public cicoService: PmsCiCoService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.globals.getModule('booking', false).then(mod => {
      this.bookingModule = mod;
    });
  }

  back() {
    this.cicoService.setProcess(null);
    this.cicoService.navigate(UserActionType.cancel);
  }
}
