import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { PmsPaymentRoutingModule } from './payment-routing.module';
import { PmsPaymentComponent } from './payment.component';

@NgModule({
  imports: [
    CommonModule,
    PmsPaymentRoutingModule,
    TranslateModule,
    LoaderModule
  ],
  exports: [
    PmsPaymentComponent
  ],
  declarations: [PmsPaymentComponent],
})
export class PmsPaymentModule { }
