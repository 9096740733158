import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { TaxiRoutingModule } from './taxi-routing.module';
import { TaxiComponent } from './taxi.component';
import { LengthValidatorModule } from 'validators/length.validator.module';

@NgModule({
  imports: [
    CommonModule,
    TaxiRoutingModule,
    FormsModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    ModuleTitleModule,
    DatePickerModule,
    TranslateModule,
    FormSelectModule,
    FormFieldsModule,
    FormPlaceModule,
    LengthValidatorModule,
    EmailValidatorModule,
    FormPhoneFieldModule,
    SendCancelModule
  ],
  exports: [
    TaxiComponent
  ],
  declarations: [TaxiComponent]
})
export class TaxiModule { }
