import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';
import { InitComponent } from '../init/init.component';
import { LanguageService } from '../language/language.service';
import { SearchComponent } from '../search/search.component';
import { PmsCiCoService } from 'cico_service';
import { UserActionType } from 'pms_enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  component: ComponentRef<any>;
  multilingual = false;
  logo_upright = false;
  offline: boolean;
  openLanguages: boolean;

  constructor(private businessService: BusinessService,
              public globals: Globals,
              private cicoService:PmsCiCoService,
              public translate: TranslateService,
              public languageService: LanguageService,
              private resolver: ComponentFactoryResolver,
              private init: InitComponent,
              private offlineService: OfflineService) { }

  ngOnInit() {
    this.subscriptions.add(this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.logo_upright = this.business.logo_upright;
      this.multilingual = this.business.multilingual;
    }));
    this.subscriptions.add(this.offlineService.offline.subscribe(offline => {
      this.offline = offline;
    }));
  }

  showSearch() {
    if (this.component) {
      this.component.destroy();
      this.component = null;
    } else {
      const factory = this.resolver.resolveComponentFactory(SearchComponent);
      this.component = this.init.search.createComponent(factory);
      this.component.instance._ref = this.component;
    }
  }

  changeLang(data) {
    this.openLanguages = false;
    this.languageService.setLanguage(data.language).then(() => {})
  }

  resetProcess(){
   this.cicoService.navigate(UserActionType.cancel);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
