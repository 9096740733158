<div class="item">
  <div class="divider" [style.background-color]="positionColor.primary"></div>

  <div class="checkbox-wrapper">
    <input [id]="'address-checkbox_' + index" type="checkbox" class="visually-hidden" [ngModel]="checked">
    <div class="checkbox" (click)="onCheckboxClick()" [style.background-color]="positionColor.light">
      <i *ngIf="checked" class="fa-solid fa-check" [style.color]="positionColor.primary"></i>
    </div>
  </div>

  <label class="recipient-container" [for]="'address-checkbox_' + index" (click)="onCheckboxClick()">
    <span class="recipient">{{ address.company ? address.company : address.first_name + ' ' + address.last_name }}</span>
    <span class="address">
      {{ address.address ? address.address + ', ' : '' }}
      {{ address.zipcode ? address.zipcode + ' ' : '' }}
      {{ address.city ? address.city + ' ' : '' }}
      {{ address.country ? address.country : '' }}
    </span>
  </label>

  <button *ngIf="changeable" (click)="onDeleteClick()">
    <i class="fas fa-trash"></i>
  </button>

  <button *ngIf="changeable" (click)="onEditClick()" translate>misc.edit</button>

  <div *ngIf="changeable" class="meatballs-menu" (mousedown)="openMenu()" (clickOutside)="closeMenu()">
    <button class="options-button">
      <i class="fas fa-ellipsis-vertical main-text"></i>
    </button>
    <div class="dropdown" [class.visible]="menuOpen">
      <div class="dropdown-item" (click)="onDeleteClick()">
        <i class="fas fa-trash"></i> <span translate>misc.delete</span>
      </div>
      <div class="dropdown-item" (click)="onEditClick()">
        <span translate>misc.edit</span>
      </div>
    </div>
  </div>
</div>
