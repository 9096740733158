<div class="capture animation-in" [class.qr-code]="!imageUpload">
  <ng-container *ngIf="imageUpload; else qr">
    <div class="passport">
      <video [hidden]="hideVideo" (window:resize)="resize()" playsInline muted></video>
      <canvas [hidden]="hideCapture"></canvas>
    </div>
  </ng-container>

  <ng-template #qr>
    <ng-container *ngIf="kioskScanner; else camera">
      <div class="qr-code">
        <svg viewBox="0 0 100 100" fill="none">
          <path d="M12.498 12.5H24.998V25H12.498V12.5Z" class="main-fill" />
          <path d="M37.5 0V37.5H0V0H37.5ZM31.25 6.25H6.25V31.25H31.25V6.25ZM25 75H12.5V87.5H25V75Z" class="main-fill" />
          <path d="M37.5 62.5V100H0V62.5H37.5ZM6.25 68.75V93.75H31.25V68.75H6.25ZM75 12.5H87.5V25H75V12.5Z" class="main-fill" />
          <path d="M62.5 0V37.5H100V0H62.5ZM93.75 6.25V31.25H68.75V6.25H93.75ZM50 6.25V0H56.25V12.5H50V25H43.75V6.25H50ZM50 37.5V25H56.25V37.5H50ZM37.5 50V43.75H43.75V37.5H50V50H56.25V43.75H87.5V50H62.5V56.25H43.75V50H37.5ZM37.5 50V56.25H12.5V50H6.25V56.25H0V43.75H18.75V50H37.5ZM100 56.25H93.75V43.75H100V56.25ZM93.75 56.25H87.5V68.75H100V62.5H93.75V56.25ZM68.75 56.25H81.25V62.5H75V68.75H68.75V56.25ZM81.25 75V68.75H75V75H68.75V81.25H56.25V87.5H75V75H81.25ZM81.25 75H100V81.25H87.5V87.5H81.25V75ZM56.25 68.75V75H62.5V62.5H43.75V68.75H56.25Z" class="main-fill" />
          <path d="M43.7461 75H49.9961V93.75H74.9961V100H43.7461V75ZM99.9961 87.5V100H81.2461V93.75H93.7461V87.5H99.9961Z" class="main-fill" />
        </svg>
        <div class="laser"></div>
      </div>

      <h3 translate>service.check_in.terminal.qr_code.hardware_scanner</h3>
      <input type="text" (keydown)="barcodeScan($event)" #barcode />
    </ng-container>

    <ng-template #camera>
      <div [class.hidden]="!cameraSet">
        <zxing-scanner (scanSuccess)="onScanSuccess($event)" (scanError)="onScanError()" (camerasNotFound)="noCamera()" (autostarted)="useDefinedCamera()" (camerasFound)="onCamerasFound($event)" [(device)]="currentDevice"></zxing-scanner>
        <div class="info-text" *ngIf="started">{{'service.check_in.terminal.qr_code.help' | translate }}</div>
      </div>
      <app-loader *ngIf="!cameraSet" [text]="'service.check_in.terminal.webcam.loading'"></app-loader>

      <div id="marker" [hidden]="!cameraSet">
        <svg width="508" height="508" viewBox="0 0 859 859" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="843" y="801" width="16" height="58" fill="white" />
          <rect x="801" y="859" width="16" height="58" transform="rotate(-90 801 859)" fill="white" />
          <rect x="801" y="16" width="16" height="58" transform="rotate(-90 801 16)" fill="white" />
          <rect x="859" y="58" width="16" height="58" transform="rotate(-180 859 58)" fill="white" />
          <rect x="16" y="58" width="16" height="58" transform="rotate(-180 16 58)" fill="white" />
          <rect x="58" width="16" height="58" transform="rotate(90 58 0)" fill="white" />
          <rect x="58" y="843" width="16" height="58" transform="rotate(90 58 843)" fill="white" />
          <rect y="801" width="16" height="58" fill="white" />
        </svg>
      </div>
    </ng-template>
  </ng-template>

  <div class="button-row">
    <button class="btn-outline" (click)="scanAbort()" type="button"><i class="fa-solid fa-chevron-left"></i>{{'misc.back' | translate}}</button>
    <button *ngIf="availableDevices?.length > 1" class="btn-outline" (click)="switchCamera()" type="button" [disabled]="cameraChanging"><i class="fa-solid fa-rotate"></i>{{'service.check_in.terminal.webcam.rotate' | translate}}</button>
  </div>
</div>
