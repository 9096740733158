import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationService {
  subject: Subject<boolean> = new Subject<any>();
  navigation: Observable<boolean> = this.subject.asObservable();

  constructor() {
  }

  setNavigation(data: any) {
    this.subject.next(data);
  }
}
