import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { EventModuleService } from '../../../event.service';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-event-list-calendar-item',
  templateUrl: './calendar_item.component.html'
})

@Injectable()
export class EventListCalenderItemComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  entry: any;

  constructor(public globals: Globals,
              private eventService: EventModuleService,
              private calendarService: CalendarService,
              private modalService: ModalService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.calendarService.calendar.subscribe(entry => {
      this.entry = entry;
    }));
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
