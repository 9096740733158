import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { UserActionType, OverlayAction, Step } from 'pms_enums';
import { Subscription } from 'rxjs';
import { ComponentRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';
import { LanguageService } from 'common/language/language.service';

@Component({
  selector: 'app-stepper-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class WidgetHeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() firstPage: boolean;
  showOverlay = false;
  userActionType = UserActionType;
  business: Business;
  component: ComponentRef<any>;
  multilingual = false;
  logo_upright = false;
  offline: boolean;
  openLanguages: boolean = false;
  languageChooser: boolean = true;
  languages: any;
  selectedLanguage: any = null;
  action: UserActionType = UserActionType.cancel;

  constructor(public cicoService: PmsCiCoService,
              private businessService: BusinessService,
              public globals: Globals,
              public translate: TranslateService,
              public languageService: LanguageService,
              private offlineService: OfflineService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.cicoService.overlayOpen.subscribe((value) => {
      setTimeout(() => {
        this.showOverlay = value;
      });
    }));

    this.subscriptions.add(this.cicoService.inactivity.pipe(filter(Boolean)).subscribe(_state => {
      setTimeout(() => {
        this.openLanguages = false;
      });
    }));

    this.subscriptions.add(this.cicoService.headerAction.pipe(filter(Boolean)).subscribe((type: UserActionType) => {
      this.action = type;
    }));

    this.subscriptions.add(this.cicoService.loggedIn.subscribe((loggedIn: boolean) => {
      setTimeout(() => {
        this.languageChooser = !loggedIn;
      });
    }));

    this.subscriptions.add(this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.logo_upright = this.business.logo_upright;

      this.multilingual = this.business.multilingual;
    }));

    this.subscriptions.add(this.offlineService.offline.subscribe(offline => {
      this.offline = offline;
    }));

    this.languageService.getLanguages().then((languages: any) => {
      this.languages = languages;
      this.selectedLanguage = this.getLanguageObject(this.globals.locale());
    });

    this.subscriptions.add(this.businessService.currentLocale.subscribe(locale => {
      this.selectedLanguage = this.getLanguageObject(locale);
    }));
  }

  getLanguageObject(languageCode: string) {
    if (this.languages) {
      const selectedLanguage = this.languages.find(lang => lang.language === languageCode);
      return selectedLanguage || null;
    }
    return null;
  }

  navigate(type: UserActionType) {
    if (type === UserActionType.back) {
      this.cicoService.cardLost = null;
      if (this.showOverlay) {
        this.cicoService.closeOverlay(OverlayAction.close);
      } else {
        this.cicoService.navigate(type);
      }
    } else {
      this.cicoService.navigate(type);
    }
  }

  changeLang(data) {
    this.cicoService.loadInitialTrans = true;
    this.selectedLanguage = data;
    this.openLanguages = false;
    this.languageService.setLanguage(data.language).then(() => {});
    this.globals.clearAlert();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
