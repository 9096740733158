<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="tvChannels">
  <div class="container">
    <div class="container-right" *ngIf="rightSide">
      <app-swiper [items]="module.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">

      <div class="top-btn-container" *ngIf="schedule?.url">
        <a translate class="button top-btn" [class.ext-link]="schedule.url_target === '_blank'" (click)="globals.openUrl(schedule.url, schedule.url_target)">
          <i class="fas fa-tv"></i>info_folder.tv_schedule
        </a>
      </div>

      <div class="searchbox" *ngIf="module?.search && tvChannels?.length > 3">
        <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
        <div class="search-icon"><i class="fas fa-search"></i></div>
      </div>

      <div class="menu">
        <div class="head">
          <div><i class="fas fa-list-ol"></i></div>
          <div translate>info_folder.tv_station</div>
          <div translate *ngIf="showFlags">misc.language</div>
        </div>
        <a *ngFor="let item of tvChannels | filter : searchText">
          <div>{{ item.position }}</div>
          <div>{{ item.name }} </div>
          <div *ngIf="showFlags"><img *ngIf="item?.media?.flag" [src]="item.media.flag" [alt]="item.name" class="flag-icon"></div>
        </a>
      </div>

      <ng-container *ngIf="radioChannels?.length">
        <div class="page-headline">
          <h2 translate>info_folder.radio_station</h2>
        </div>

        <div class="searchbox" *ngIf="module?.search && radioChannels?.length > 3">
          <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
          <div class="search-icon"><i class="fas fa-search"></i></div>
        </div>

        <div class="menu">
          <div class="head">
            <div><i class="fas fa-list-ol"></i></div>
            <div translate>info_folder.radio_station</div>
            <div translate *ngIf="showFlags">misc.language</div>
          </div>
          <a *ngFor="let item of radioChannels | filter : searchText">
            <div>{{ item.position }}</div>
            <div>{{ item.name }} </div>
            <div *ngIf="showFlags"><img *ngIf="item?.media?.flag" [src]="item.media.flag" [alt]="item.name" class="flag-icon"></div>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
