import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmsWizardTerminalOptionComponent } from './option.component';


const routes: Routes = [
  { path: '', component: PmsWizardTerminalOptionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PmsWizardTerminalOptionRoutingModule { }
