import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputValidator } from './input.validator';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    InputValidator
  ],
  declarations: [InputValidator]
})
export class InputValidatorModule { }
