import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from 'models/module';
import { Globals } from 'base';
import { WifiService } from './wifi.service';

@Component({
  selector: 'app-wifi',
  templateUrl: './wifi.component.html'
})
export class WifiComponent implements OnInit {
  module: Module;
  entries: string[];
  rightSide: boolean;

  constructor(public globals: Globals,
              private router: Router,
              private wifiService: WifiService) { }

  ngOnInit() {
    this.globals.getModule('wifi').then((mod) => {
      this.module = mod;
      this.rightSide = this.module.medias && this.module.medias.length > 0;
      this.wifiService.getWifi('').subscribe(
        (success: any) => {
          this.entries = success.wifi;
          if (this.entries.length === 1) {
            this.globals.overrideBacklink = ['/g', this.globals.getCode()];
            this.router.navigate(['g', this.globals.getCode(), 'wifi', this.entries[0]['id']], {replaceUrl: true});
          }
        }, () => {}
      );
    }).catch(() => {});
  }
}
