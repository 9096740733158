<label class="input-container search-input-container">
  <svg class="search-input-icon" viewBox="0 0 20 20" fill="none">
    <path opacity="0.3" d="M11.914 13.9226C11.5886 13.5972 11.5886 13.0695 11.914 12.7441C12.2394 12.4186 12.7671 12.4186 13.0925 12.7441L16.4258 16.0774C16.7513 16.4028 16.7513 16.9305 16.4258 17.2559C16.1004 17.5814 15.5728 17.5814 15.2473 17.2559L11.914 13.9226Z" fill="#208293" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33496 9.16732C3.33496 12.389 5.94663 15.0007 9.16829 15.0007C12.39 15.0007 15.0016 12.389 15.0016 9.16732C15.0016 5.94566 12.39 3.33398 9.16829 3.33398C5.94663 3.33398 3.33496 5.94566 3.33496 9.16732ZM13.3402 9.16764C13.3402 11.4688 11.4747 13.3343 9.1735 13.3343C6.87232 13.3343 5.00684 11.4688 5.00684 9.16764C5.00684 6.86646 6.87232 5.00098 9.1735 5.00098C11.4747 5.00098 13.3402 6.86646 13.3402 9.16764Z" fill="#208293" />
  </svg>

  <div class="input-wrapper">
    <span class="text">{{ value ? value : placeholder }}</span>
    <input class="input" [id]="inputId" [name]="name" [ngModel]="value" (ngModelChange)="inputValueChange($event)" [placeholder]="placeholder" [autocomplete]="autocomplete" [attr.data-cy]="name" (focus)="changeKeyboardState(true)" (blur)="changeKeyboardState(false)">
  </div>
</label>
