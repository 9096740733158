import { Observable } from 'rxjs';
import { DomModalNewsletter } from './dom_modal_newsletter';
import { File } from './file';

export class DomModal {
  constructor(
    public title: string = '',
    public description: string = '',
    public addition: string = '',
    public attachments: File[] = [],
    public newsletter: DomModalNewsletter = {
      title: '',
      description: '',
      show: false
    },
    public notificationState: Observable<string> = null,
    public redirect: boolean = true,
    public component: any = null,
    public params: any = {},
    public update: boolean = false,
    public closeable: boolean = true,
    public small: boolean = false,
  ) { }
}
