export class Coordinates {
  lat: string;
  lng: string;
  latitude: number; // google maps need numbers as params
  longitude: number; // google maps need numbers as params
  found: boolean; // latitude && longitude is present
  distance: any;
  duration: any;
  icon: string;

  mod: any;

  constructor(raw_json: any) {
    this.lat = raw_json.lat;
    this.lng = raw_json.lng;
    this.latitude = parseFloat(raw_json.lat);
    this.longitude = parseFloat(raw_json.lng);

    this.found = raw_json.found;
    this.distance = raw_json.distance;
    this.duration = raw_json.duration;
    this.icon = raw_json.icon;
  }
}
