import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsPaymentService } from 'payment_service';
import { OverlayAction } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsFolio } from 'pms_models/pms_folio';
import { PmsService } from 'pms_service';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PmsPaymentComponent } from '../payment/payment.component';

@Component({
  selector: 'app-pms-credit-card',
  templateUrl: './credit_card.component.html',
  styleUrls: ['./credit_card.component.scss']
})

export class PmsCreditCardComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  card: any;
  loading = false;
  ui_messages: any;
  usable: boolean;
  isPreAuth: boolean;

  @Input() data: GenericData;
  @Input() folio: PmsFolio;
  @Input() payment: any;
  @Input() payButton: any;

  constructor(private globals: Globals,
              private cicoService: PmsCiCoService,
              private pmsService: PmsService,
              private paymentService: PmsPaymentService) {
  }

  ngOnInit() {
    this.isPreAuth = this.payment.kind === 'pre_auth'

    this.usable = !this.isPreAuth || this.payment.available_amount >= this.folio.payable_balance;
    this.card = this.payment.credit_card;
    this.ui_messages = this.cicoService.ui_messages();
  }

  action() {
    if (this.isPreAuth) {
      this.usable ? this.usePreAuth() : this.pay()
    } else {
      this.usePayment();
    }
  }

  usePreAuth() {
    this.loading = true;
    this.paymentService.usePreAuth(this.dataInformation()).subscribe((result: any) => {
      this.data.incident.reservation.payment_token = result.pms_token;
      this.checkPayment();
    }, () => {
      this.paymentError();
    });
  }

  usePayment() {
    this.loading = true;
    this.paymentService.useTokenizedCard(this.dataInformation()).subscribe((result: any) => {
      this.data.incident.reservation.payment_token = result.pms_token;
      this.checkPayment();
    }, () => {
      this.paymentError();
    });
  }

  pay() {
    this.close();
    setTimeout(() => {
      this.cicoService.openComponentOverlay(PmsPaymentComponent, {
        data: this.data,
        folio: this.folio,
        payButton: this.payButton
      });
    }, 800);
  }

  close() {
    setTimeout(() => {
      this.cicoService.closeOverlay(OverlayAction.close);
    });
    this.enablePayButton();
  }

  private checkPayment() {
    this.subscriptions.add(of(true).pipe(delay(2000)).subscribe(() => {
      this.paymentService.paymentSubj.next(this.folio);
      this.pmsService.getFolios(this.data.incident.reservation.uuid, this.data.incident.reservation.payment_token, this.data.module.type).subscribe((success: any) => {

        const folios = success.folios;
        this.paymentService.paymentSubj.next(folios);

        const folio = folios.find(_folio => _folio.number === this.folio.number);
        if (folio?.check === 'unpaid') {
          this.paymentError();
        } else {
          this.loading = false;
          this.globals.clearAlert();
          setTimeout(() => {
            this.cicoService.closeOverlay(OverlayAction.close);
          });
        }
      });
      this.enablePayButton();
    }));
  }

  enablePayButton() {
    this.cicoService.toggleInactivity(true);
    if (this.payButton) {
      this.payButton.disabled = false;
    }
  }

  private paymentError() {
    this.cicoService.closeOverlay(OverlayAction.close);
    this.loading = false;
    this.globals.alert('error', this.ui_messages?.payment_error?.content);
    this.enablePayButton();
  }

  dataInformation() {
    return {
      locale: this.globals.guest.locale,
      code: this.data.code,
      folios: this.data.incident.reservation.folios,
      guest: this.data.incident.reservation.primary_guest,
      resId: this.data.incident.reservation.reservation_id,
      folioId: this.folio?.number,
      origin: this.data.module.type,
      pms_token: this.payment.token
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
