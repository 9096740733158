import { Address } from '../address';
import { Business } from '../business';
import { v4 as uuidv4 } from 'uuid';

export class PmsGuest {
  id: any;
  uuid: string;
  first_name: string;
  last_name: string;
  group: string;
  date_of_birth: string;
  place_of_birth: string;
  gender: string;
  nationality: string;
  phone: string;
  email: string;
  profession: string;
  passport: string;
  passport_id: string;
  passport_date: string;
  passport_expire: string;
  passport_authority: string;
  internal_id: string;
  address: Address;
  helper: string = null;
  valid_documents: string[] = [];
  doc_type: string;
  passport_image: boolean;
  passport_upload_required: boolean;
  passport_data: boolean;
  visa_data: boolean;
  visa_number: string;
  visa_date: string;
  visa_expire: string;

  constructor(raw_json: any = null) {
    this.id = raw_json?.id;
    this.uuid = raw_json?.uuid || uuidv4();
    this.group = raw_json?.group;
    this.first_name = raw_json?.first_name;
    this.last_name = raw_json?.last_name;
    this.date_of_birth = raw_json?.date_of_birth;
    this.place_of_birth = raw_json?.place_of_birth;
    this.gender = raw_json?.gender;
    this.nationality = raw_json?.nationality;
    this.phone = raw_json?.phone;
    this.email = raw_json?.email;
    this.profession = raw_json?.profession;
    this.valid_documents = raw_json?.valid_documents;
    this.doc_type = raw_json?.doc_type;
    this.passport = raw_json?.passport;
    this.passport_id = raw_json?.passport_id;
    this.passport_date = raw_json?.passport_date;
    this.passport_expire = raw_json?.passport_expire;
    this.passport_authority = raw_json?.passport_authority;
    this.visa_number = raw_json?.visa_number;
    this.visa_date = raw_json?.visa_date;
    this.visa_expire = raw_json?.visa_expire;
    this.internal_id = raw_json?.internal_id;
    this.address = raw_json?.address ? new Address(raw_json.address) : new Address(null);
  }

  name(): string {
    if (this.first_name !== undefined && this.last_name !== undefined) {
      return this.first_name + ' ' + this.last_name;
    }
    return '';
  }

  adult(): boolean {
    return this.group === 'adult_fellows';
  }

  domestic(business: Business): boolean {
    return business?.listOfLocals()?.includes(this.nationality?.toLowerCase()) || false;
  }
}
