import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { Globals } from 'base';


@Component({
  selector: 'app-tac',
  templateUrl: './tac.component.html'
})
export class TacComponent implements OnInit {
  module: Module;
  menu_items: any;
  searchText: string;
  url: string;

  constructor(private api: ApiService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('spa', false).then((mod) => {this.module = mod; }).catch(() => {});
    this.api.get('api/tac/menu_items').subscribe((data: any) => {
      this.url = data.base_url + data.login;
      this.menu_items = data.menu_items;
      if (this.menu_items.length === 0) {
        this.open();
      }
    });
  }

  open(link = '') {
    this.globals.backSteps = 2;
    const url = (this.url + '&goto=' + link).replace('//account', '/account');
    this.globals.openUrl(url, '_self');
  }
}
