import { Injectable } from '@angular/core';
import { Guest } from 'models/guest';
import { Visit } from 'models/visit';
import { ApiService } from 'api_service';
import { GuestService } from './guest.service';

@Injectable({providedIn: 'root'})
export class VisitService {
  visit: Visit;
  guest: Guest;
  adverts: Array<any> = [];

  constructor(private guestService: GuestService,
              private api: ApiService) {
  }

  createVisit(raw_json) {
    this.visit = new Visit(raw_json);
    this.guest = this.guestService.guest;
    this.guest.visits.push(this.visit);
    this.sendVisit(this.visit);
  }

  sendVisit(visit) {
    visit['adverts'] = this.adverts.map(advert => advert.id);
    this.adverts = [];

    const data = btoa(JSON.stringify(visit));
    this.api.silentPost('v', {data: data}).subscribe(() => { }, () => { });
  }
}
