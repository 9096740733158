import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { ShopModuleService } from './shop.service';

@Component({
  selector: 'app-shop-module',
  templateUrl: './shop.component.html'
})

export class ShopComponent implements OnInit {
  module: Module;
  cart: string[];

  constructor(private menuService: ShopModuleService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('shop', false).then((mod) => {this.module = mod; }).catch(() => {});
  }

}
