import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  styleUrls: [
    '../input-styles.scss',
    './basic-input.component.scss'
  ]
})
export class BasicInputComponent implements OnInit {

  @ViewChild('input') input;
  @Input('ngModel') inputValue: string = '';
  @Output('ngModelChange') inputValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output('paste') paste: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string;
  @Input() type: 'text' | 'number' = 'text';
  @Input() required: boolean;
  @Input() description!: string;
  @Input() placeholder!: string;
  @Input() tooltip?: string;
  @Input() disabled: boolean;
  @Input() submited: boolean;
  @Input() errorMsg: string = 'validation.required';
  @Input() validation: 'email' | 'length' = 'length';
  @Input() min: number;
  @Input() max: number;
  @Input() otherEmail: string;
  @Input() duplicated : any;

  inputId: string
  autocomplete: string;
  errorArgs: any;

  constructor(
    private globals: Globals,
    private cicoService: PmsCiCoService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.autocomplete = this.globals.place?.wizard ? 'nope' : 'on';
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
    if (this.type === 'text') {
      this.min = 0;
      this.max = 254;
    }
  }

  ngAfterViewInit() {
    this.setErrorMessage();
    this.cdRef.detectChanges();
  }

  valueChange() {
    this.inputValueChange.emit(this.inputValue);
    this.setErrorMessage();
  }

  setErrorMessage() {
    const errors = this.input.control.errors;
    if (errors?.required) {
      this.errorMsg = 'validation.required';
    } else if (errors) {
      if (this.type === 'number') {
        this.errorMsg = 'validation.number';
      } else if (this.validation === 'length' && errors.LengthValidator?.min) {
        this.errorMsg = 'validation.minLength';
        this.errorArgs = { length: this.min };
      } else if (this.validation === 'length' && errors.LengthValidator?.max) {
        this.errorMsg = 'validation.maxLength';
        this.errorArgs = { length: this.max };
      } else if (this.validation === 'email' && errors.emailvalidator?.wrongFormat && !errors.required) {
        this.errorMsg = 'validation.email_wrong_format';
      } else if (this.validation === 'email' && errors.emailvalidator?.notEqual) {
        this.errorMsg = 'validation.email_not_match';
      }
    } else if (this.duplicated) {
      this.errorMsg = this.duplicated.errorMsg;
    }
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }
}
