import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from 'models/module';
import { Globals } from 'base';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html'
})
export class FilesComponent implements OnInit {
  files = [];
  title: string;

  @Input() module: Module;

  constructor(private globals: Globals,
              private router: Router) {
  }

  ngOnInit() {
    this.check_redirect();
    if (this.module !== undefined) {
      this.title = this.module.name;
      this.files = this.module.files;
      this.check_redirect();
    }
  }

  check_redirect() {
    if ( this.files?.length === 1) {
      this.router.navigate(['/g', this.globals.getCode(), 'file', this.files[0].attachment.id]);
      this.globals.backSteps = 2;
    }
  }
}
