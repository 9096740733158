<ngx-loading-bar [includeSpinner]="false" [color]="color"></ngx-loading-bar>
<ng-container #search></ng-container>
<div id="container" [class.wizard]="wizard">
  <ng-container *ngIf="guest">
    <ng-container *ngIf="!wizard; else wizard_view">
      <app-background></app-background>
      <app-header></app-header>
      <app-navbar></app-navbar>
      <app-content></app-content>
      <app-sidebar *ngIf="sidebar"></app-sidebar>
      <app-footer *ngIf="footer"></app-footer>
    </ng-container>
  </ng-container>

  <ng-template #wizard_view>
    <app-stepper-header [firstPage]="globals.start"></app-stepper-header>
    <app-content></app-content>
  </ng-template>
</div>
<app-modal></app-modal>
<ng-container *ngIf="!wizard">
  <img id="straivImgCache" src="" alt="cached" class="hidden"/>
</ng-container>
