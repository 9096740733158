import { Component } from '@angular/core';
import { WebsocketService } from '../../../../_services/websocket/websocket.service';

@Component({
  selector: 'app-conflict',
  templateUrl: './conflict.component.html',
  styleUrls: ['./conflict.component.scss']
})
export class ConflictComponent {

  constructor(public wsService: WebsocketService) {}

  abort() {
    this.wsService.showConflict = false;
    window.location.href = '/error/409';
  }
}
