import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmsPrivateAddressComponent } from './private_address.component';


const routes: Routes = [
  { path: '', component: PmsPrivateAddressComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PmsPrivateAddressRoutingModule { }
