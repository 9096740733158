<div class="page-headline">
  <h2 translate>push.title</h2>
</div>

<div class="container">
  <div class="container-left">
    <div *ngIf="showHint" class="info_box info">
      <i class="fas fa-info-circle"></i>
      <p translate>push.hint</p>
    </div>
    <div translate>push.description</div>
    <app-push-categories></app-push-categories>
  </div>
</div>
