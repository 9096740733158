import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { PortfolioListParentRoutingModule } from './parent-routing.module';
import { PortfolioListParentComponent } from './parent.component';
import { ServiceParentDirective } from 'shared/service/parent.directive';

@NgModule({
  imports: [
    CommonModule,
    PortfolioListParentRoutingModule,
    TranslateModule,
    FilterPipeModule,
    AttachmentsModule,
    NgPipesModule,
    WidgetAdvertModule,
    SharedSwiperModule,
    BacklinkModule,
    FormsModule
  ],
  exports: [
    PortfolioListParentComponent
  ],
  declarations: [PortfolioListParentComponent],
  providers: [
    ServiceParentDirective
  ]
})
export class PortfolioListParentModule { }
