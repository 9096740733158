import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { TeaserService } from 'services/websocket/teaser.service';
import { BackgroundService } from './background.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html'
})

export class BackgroundComponent implements OnInit, OnDestroy {
  @Input() medias: any;

  business: Business;
  imageTrigger: number;
  minPosition = 1;
  mainPage = false;

  private subscriptions: Subscription = new Subscription();

  constructor(private teaserService: TeaserService,
              private backgroundService: BackgroundService,
              private businessService: BusinessService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.imageTrigger = business.style.image_trigger;
      if (this.medias) {
        this.setBackground();
      } else {
        this.mainPage = true;
        this.subscribeTeaser();
      }
    }));
  }

  subscribeTeaser() {
    this.subscriptions.add(this.teaserService.teaser.pipe(filter(Boolean)).subscribe((teaser: any) => {
      if (JSON.stringify(this.medias) !== JSON.stringify(teaser.medias)) {
        this.medias = teaser.medias;
        this.setBackground();
      }
    }));
  }

  setBackground() {
    this.minPosition = Math.min(...this.medias.map((media: any) => media.media.position));
    this.backgroundService.loadBackground(this.medias);
  }

  encodeParentheses(str: string) {
    return str?.toString()?.replace(/\(/g, '%28')?.replace(/\)/g, '%29');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
