import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { WishModuleService } from '../../wish.service';

@Component({
  selector: 'app-wish-list-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})

export class WishListParentComponent extends ServiceParentDirective implements OnInit, OnDestroy {
  mod = 'wish';

  constructor(public globals: Globals,
              protected route: ActivatedRoute,
              protected businessService: BusinessService,
              protected sanitizer: DomSanitizer,
              private wishService: WishModuleService) {
    super(globals, route, businessService, sanitizer);
  }

  ngOnInit() {
    super.ngOnInit(this.wishService);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
