import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { CouponModuleService } from '../../coupon.service';

@Component({
  selector: 'app-coupon-list-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss']
})

export class CouponListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'coupon';

  constructor(public globals: Globals,
              protected businessService: BusinessService,
              private couponService: CouponModuleService) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.couponService);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
