import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GenericData } from 'pms_models/generic_data';
import { PmsReservation } from 'pms_models/pms_reservation';
import { OverlayAction } from 'pms_enums';
import { PmsService } from 'pms_service';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-pms-ci-pin-codes',
  templateUrl: './pin_codes.component.html',
  styleUrls: ['./pin_codes.component.scss']
})

export class PmsPinCodesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  reservation: PmsReservation;
  codeTitle: string;
  door: any;
  waiting = true;
  sag: boolean;

  @Input() id: any;
  @Input() terminal: boolean;
  @Input() hideFinishButton = false;

  constructor(private ciCoService: PmsCiCoService,
              private businessService: BusinessService,
              private pmsService: PmsService) {
  }

  ngOnInit(): void {
    this.businessService.current_business.pipe(filter(Boolean), take(1)).subscribe((business: Business) => {
      this.sag = business.tech.door_system.type === 'sag';
      this.codeTitle = `pms_door.pin_codes.${this.sag ? 'start_code' : 'title'}`

      if (this.id) {
        this.loadCodes(this.id);
      } else {
        this.ciCoService.disableButtons(true);
        this.subscriptions.add(this.ciCoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
          this.loadCodes(data.incident.reservation.id);
        }));
      }
    })
  }

  loadCodes(id) {
    this.pmsService.getReservationById(id).subscribe((success: any) => {
      this.reservation = new PmsReservation(success.reservation);
      this.door = success.reservation.door;
      this.waiting = false
    });
  }

  close(): void {
    this.ciCoService.closeOverlay(OverlayAction.cancel);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
