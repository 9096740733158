import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Module } from 'models/module';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-script',
  templateUrl: './script.component.html'
})

export class ScriptComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  content: any;
  uphotel: boolean;
  @Input() module: Module;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.uphotel = this.module.module_api.kind === 'uphotel';

    this.content = document.getElementById('external-script');
    const template = document.createElement('template');
    template.innerHTML = this.module.module_api.script;
    template.content.childNodes.forEach((node: any) => {
      if (node.tagName) {
        if (node.tagName === 'SCRIPT') {
          this.handleScript(node);
        } else {
          this.renderer.appendChild(this.content, node);
        }
      }
    });
  }

  handleScript(node) {
    if (node.src) {
      this.addJsToContent(node);
    } else {
      if (node.id === 'wait') {
        this.subscriptions.add(of(true).pipe(delay(1500)).subscribe(() => {
          eval(node.innerHTML.trim());
        }));
      } else {
        eval(node.innerHTML.trim());
      }
    }
  }

  addJsToContent(node) {
    const element = document.createElement('script');
    element.type = 'text/javascript';
    element.src = node.src;
    element.id = node.id;
    this.renderer.appendChild(this.content, element);
    if (this.uphotel) {
      console.log('start UpIBE');
      (<any>window).upIbe?.bootstrapEngine();
    }
  }

  ngOnDestroy() {
    if (this.uphotel) {
      console.log('destroy UpIBE');
      (<any>window).upIbe?.destroyEngine();
    }
    this.subscriptions.unsubscribe();
  }
}
