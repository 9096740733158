<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="items">
  <div class="container">
    <div class="container-right" *ngIf="rightSide">
      <app-swiper [items]="module.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">

      <div class="searchbox" *ngIf="module?.search && items">
        <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
        <div class="search-icon"><i class="fas fa-search"></i></div>
      </div>

      <div class="menu">
        <div class="head">
          <div translate>newspaper.title</div>
          <div translate>misc.language</div>
        </div>
        <a *ngFor="let item of items | filter : searchText" (click)="globals.openUrl(item.link.url, item.link.url_target)" class="link">
          <div><a [class.ext-link]="item.link.url_target === '_blank'">{{item.name}}</a></div>
          <div><img [src]="item.media.flag" [alt]="item['name']" class="flag-icon" *ngIf="item.media.flag"></div>
        </a>
      </div>
    </div>
  </div>
</ng-container>
