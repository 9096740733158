import { Component, Input } from '@angular/core';
import { Globals } from 'base';

@Component({
  selector: 'app-api-shared-newspaper',
  templateUrl: './parent.component.html'
})

export class SharedNewspaperParentComponent {
  @Input() categories;
  @Input() magazines;
  @Input() module;

  constructor(public globals: Globals) {
  }

  open(url) {
    this.globals.openUrl(url, '_self');
    this.globals.backSteps = 2;
  }
}
