import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss']
})

export class SuccessScreenComponent implements OnInit, AfterViewInit {
  @Input() msg = '';
  @Input() highlightedText = '';
  safeHtml: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    timer(0, 0).pipe(take(1)).subscribe(() => {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.msg);
    });
  }
}
