<div id="news-widget" *ngIf="news?.length">
  <div class="news-container clearfix slider news-slide" *ngIf="done">
    <mat-carousel timings="300ms ease-in" [autoplay]="slideConfig.autoplay" [interval]="slideConfig.delay" [loop]="slideConfig.loop" [hideArrows]="slideConfig.hideArrows" [maintainAspectRatio]="false" [useKeyboard]="true">
      <mat-carousel-slide #matCarouselSlide [hideOverlay]="true" *ngFor="let item of news">
        <div class="slider-content" (click)="openNews(item?.id)">
          <div class="text"><i class="ad-icon" *ngIf="item.advert"></i>{{ item.title }}</div>
          <a translate class="button-hollow-small">misc.more</a>
        </div>
      </mat-carousel-slide>
    </mat-carousel>
  </div>
</div>
