import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { SidebarService } from 'common/sidebar/sidebar.service';
import { interval, Subscription } from 'rxjs';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';

@Component({
  selector: 'app-widget-module',
  templateUrl: './module.component.html'
})
export class WidgetModuleComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  module: any;
  offline: boolean;

  @Input() widget: string;

  constructor(public globals: Globals,
              private businessService: BusinessService,
              private offlineService: OfflineService,
              private sidebarService: SidebarService) { }

  ngOnInit() {
    const subscription = interval(50).subscribe(() => {
      if (this.widget) {
        subscription.unsubscribe();

        this.subscriptions.add(this.businessService.currentLocale.subscribe(() => {
          this.subscriptions.add(this.sidebarService.moduleWidget(this.widget).subscribe((success: any) => {
            this.module = success;
          }, () => {}));
          this.subscriptions.add(this.offlineService.offline.subscribe(offline => {
            this.offline = offline;
          }));
        }));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
