import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MapProtectionModule } from 'shared/layout/map_protection/map_protection.module';
import { MapModule } from '../../shared/map/map.module';
import { GroupMapRoutingModule } from './map-routing.module';
import { GroupMapComponent } from './map.component';

@NgModule({
  imports: [
    CommonModule,
    GroupMapRoutingModule,
    MapProtectionModule,
    TranslateModule,
    MapModule
  ],
  exports: [
    GroupMapComponent
  ],
  declarations: [GroupMapComponent]
})
export class GroupMapModule { }
