<ng-container *ngIf="item">
  <div class="page-headline">
    <h2><i class="ad-icon" *ngIf="item.advert"></i>{{item.title || module?.name}}</h2>
  </div>
  <div class="container">
    <div class="container-right" *ngIf="showRight">
      <app-swiper [items]="item.medias" *ngIf="item.medias?.length"></app-swiper>

      <div *ngIf="hasContact">
        <h4 translate>poi.contact_details</h4>
        <ul class="icon-list">
          <li *ngIf="item.contact.email">
            <i class="far fa-envelope"></i>
            <a href="mailto:{{item.contact.email}}">{{item.contact.email}}</a>
          </li>
          <li *ngIf="item.contact.phone">
            <i class="fas fa-phone"></i>
            <a target="_blank" href="tel:{{item.contact.phone}}">{{item.contact.phone}}</a>
          </li>
          <li *ngIf="item.contact.url">
            <i class="fas fa-home"></i>
            <a [class.ext-link]="item.contact.url_target === '_blank'" translate (click)="globals.openUrl(item.contact.url, item.contact.url_target)">misc.open_website</a>
          </li>
        </ul>
      </div>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">
      <div [innerHtml]="content"></div>

      <app-attachments [files]="item.files" *ngIf="item.files?.length"></app-attachments>
    </div>
  </div>
</ng-container>

<app-backlink></app-backlink>
