import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class ListService {

  constructor(private api: ApiService) {}

  getEntry(id: string): Observable<Object> {
    return this.api.get('list/entries/' + id);
  }

  getCategories(id): Observable<Object> {
    return this.api.get('list/categories/' + id);
  }

  getMainCategory(id): Observable<Object> {
    return this.api.get('list/main/' + id);
  }
}
