import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { WidgetAddressComponent } from 'widgets/address/address.component';
import { WidgetAdvertComponent } from 'widgets/advert/advert.component';
import { WidgetGroupComponent } from 'widgets/group/group.component';
import { WidgetGroupBusinessComponent } from 'widgets/group_business/group_business.component';
import { WidgetGroupImageComponent } from 'widgets/group_image/group_image.component';
import { WidgetLegalComponent } from 'widgets/legal/legal.component';
import { WidgetLogoComponent } from 'widgets/logo/logo.component';
import { WidgetPushComponent } from 'widgets/push/push.component';
import { WidgetSocialComponent } from 'widgets/social/social.component';
import { WidgetWeatherComponent } from 'widgets/weather/weather.component';
import { WidgetA2hsComponent } from 'widgets/a2hs/a2hs_widget.component';
import { Globals } from '../../globals';
import { MenuComponent } from '../menu/menu.component';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})

export class NavbarComponent implements OnInit {
  no_hover: boolean;

  mapping = {
    address: WidgetAddressComponent,
    advert: WidgetAdvertComponent,
    group_logo: WidgetGroupComponent,
    group_business: WidgetGroupBusinessComponent,
    group_image: WidgetGroupImageComponent,
    legal: WidgetLegalComponent,
    logo: WidgetLogoComponent,
    menu: MenuComponent,
    push: WidgetPushComponent,
    homescreen: WidgetA2hsComponent,
    social: WidgetSocialComponent,
    weather: WidgetWeatherComponent
  };

  @ViewChild('navbar', {read: ViewContainerRef, static: true}) navbar: ViewContainerRef;

  constructor(private navbarService: NavbarService,
              private resolver: ComponentFactoryResolver,
              private globals: Globals) { }

  ngOnInit() {
    this.no_hover = this.globals.isMobile();
    this.navbarService.getNavbar().subscribe((success: any) => {
      success.navbar.forEach(widget => {
        const name = this.mapping[widget];
        const factory = this.resolver.resolveComponentFactory(name);
        this.navbar.createComponent(factory);
      });
    }, () => {});
  }
}
