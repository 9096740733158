<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>
    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left all" *ngIf="categories">
    <div class="top-btn-container clearfix" *ngIf="showMap">
      <a [routerLink]="['/g/', globals.getCode(), 'poi', 'map']" class="button top-btn">
        <i class="fas fa-map-marked-alt"></i>{{'poi.map' | translate}}
      </a>
    </div>

    <div class="searchbox" *ngIf="module?.search && categories">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <ng-container *ngFor="let item of categories">
      <em [innerHtml]="item.category.name"></em>
      <div [innerHtml]="item.category.description"></div>
      <app-attachments [files]="item.category.files" *ngIf="item.category.files?.length"></app-attachments>
      <div class="menu entries">
        <div class="head">
          <div *ngIf="distances"><i class="fas fa-route"></i></div>
          <div translate>misc.place_name</div>
          <div></div>
        </div>
        <a *ngFor="let entry of item.category.entries | filter : searchText" [routerLink]="['/g', globals.getCode(), 'poi', entry.id]">
          <div *ngIf="distances">{{entry.coordinates?.distance}}</div>
          <div><i class="ad-icon" *ngIf="entry.advert"></i>{{entry.name}}</div>
          <div><i class="fas fa-angle-right icon-next"></i></div>
        </a>
      </div>
    </ng-container>

  </div>
</div>
