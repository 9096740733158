import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { File } from 'models/file';
import { NewsEntry } from 'models/news_enty';
import { AwayService } from 'services/away.service';
import { BusinessService } from 'services/business.service';
import { VisitService } from 'services/visit.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { NewsService } from '../news.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news_item.component.html'
})

@Injectable()
export class NewsItemComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  news: NewsEntry;
  content: SafeHtml;
  link: any;
  timer: Object;
  business: Business;
  show_social: boolean;
  hasLink: boolean;
  files: File[] = [];
  @Input() modal: any;

  constructor(private globals: Globals,
              private newsService: NewsService,
              private businessService: BusinessService,
              private visitService: VisitService,
              private awayService: AwayService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private modalService: ModalService) {

  }

  ngOnInit(): void {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.show_social = this.business.style.social_in_modal;
      this.globals.setModule('news');
      this.globals.taskSubj.next(false);
    });

    this.subscriptions.add(this.newsService.newsEntry.subscribe((news: any) => {
        this.news = news.entry;
        this.content = this.sanitizer.bypassSecurityTrustHtml(this.news.body);

        this.files = this.news.attachments;
        this.timer = this.awayService.start_timer();

        this.link = this.news.link;
        this.hasLink = this.link.url || this.link.module;

        if (this.updateNews()) {
          this.show_social = false;
          this.hasLink = true;
        }
      }, (_error) => { }
    ));
  }

  action() {
    this.close();
    if (this.updateNews()) {
      this.globals.reload();
    } else if (this.news.link.module) {
      this.router.navigate(['/g', this.globals.getCode(), this.link.module]);
    } else if (this.news.link.url) {
      this.globals.openUrl(this.link.url, this.link.url_target);
    }
  }

  close() {
    this.createVisit();
    this.modalService.close();
  }

  createVisit() {
    if (!this.globals.testView()) {
      this.visitService.createVisit({
        module: 'news',
        page: this.news['gid'],
        session: this.awayService.stop_timer(this.timer)
      });
    }
  }

  updateNews(): boolean {
    return this.news.body?.includes('caches.delete');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
