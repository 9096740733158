import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Field } from 'models/field';
import { GenericData } from 'pms_models/generic_data';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-pms-private-address',
  templateUrl: './private_address.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  styleUrls: ['./private_address.component.scss'],
})

export class PmsPrivateAddressComponent implements OnInit {
  @Input() data: GenericData;
  @Input() model: any;
  @Input() field: Field;
  @Input() submited: boolean;
  @Output() fieldsChanged = new EventEmitter<void>();

  fields: any;
  id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6);
  states = [];

  constructor(public cicoService: PmsCiCoService) { }

  ngOnInit() {
    this.setFields();
    this.countryChanged(this.model.address?.country);
  }

  setFields() {
    this.fields = {
      address: this.field_for('address'),
      zipcode: this.field_for('zipcode'),
      city: this.field_for('city'),
      country: this.field_for('country'),
      state: this.field_for('state'),
    }
  }

  field_for(id): Field {
    return this.field.fields?.find(field => field.identifier === id);
  }

  countryChanged(value) {
    switch (value) {
      case 'US':
        this.states = this.data.statesUs;
        break;
      case 'CA':
        this.states = this.data.statesCa;
        break;
      default:
        this.states = [];
        this.model.state = null;
    }
  }

  checkFields() {
    this.fieldsChanged.emit();
    this.setFields();
  }
}
