<div class="input-container" [ngClass]="{ 'valid': inputValue?.length > 0 && input.valid, 'invalid': (input.dirty || (required && !inputValue && submitted) || inputValue?.length > 0 || submitted) && input.invalid, 'disabled': disabled }">
  <app-description-label [for]="inputId" [description]="description" [required]="required && !disabled" [tooltip]="tooltip"></app-description-label>
  <div class="input-wrapper">
    <input class="redesign" #dateNativeValue [id]="inputId" [name]="name" #input="ngModel" [min]="min" [max]="max" [datevalidator]="{ mask: mask?.momentMask, min: min, max: max, required: required, validation: validation }" [type]="type" autocomplete="no" [(ngModel)]="inputValue" (change)="onDateChange()" [placeholder]="mask?.momentMask" [inputMask]="inputMaskConfig" [class.has-value]="input.value?.length > 0" [required]="required" [attr.data-cy]="name" (focus)="changeKeyboardState(true)" (blur)="changeKeyboardState(false)">
    <i class="fas fa-calendar date-icon"></i>
    <i class="fas fa-lock locked-icon"></i>
    <i class="fas fa-check checkmark-icon"></i>
  </div>

  <label class="error-label" [class.visible]="((input.dirty || inputValue?.length > 0) && input.invalid) || (submitted && input.invalid)">
    <i class="fas fa-circle-xmark"></i>
    <span>{{ input.errors?.datevalidator?.text | translate }}</span>
  </label>
</div>
