import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { LabelModule } from 'shared/forms/label/label.module';
import { PmsPrivateAddressRoutingModule } from './private_address-routing.module';
import { PmsPrivateAddressComponent } from './private_address.component';
import { TypographyModule } from 'common/typography/typography.module';

@NgModule({
  imports: [
    CommonModule,
    LabelModule,
    PmsPrivateAddressRoutingModule,
    FormsModule,
    FormSelectModule,
    TranslateModule,
    TypographyModule
  ],
  exports: [
    PmsPrivateAddressComponent
  ],
  declarations: [PmsPrivateAddressComponent]
})
export class PmsPrivateAddressModule {}
