import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WidgetLegalRoutingModule } from './legal-routing.module';
import { WidgetLegalComponent } from './legal.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetLegalRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetLegalComponent
  ],
  declarations: [WidgetLegalComponent]
})
export class WidgetLegalModule { }
