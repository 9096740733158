import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternetAccessComponent } from './internet_access.component';


const routes: Routes = [
  { path: '', component: InternetAccessComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InternetAccessRoutingModule { }
