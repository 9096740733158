<form #form="ngForm" class="redesign" *ngIf="data?.module?.type === ciCoType.co">
  <fieldset>
    <ng-container *ngIf="data.incident?.reservation?.primary_guest">
      <app-date-input *ngIf="field_for('reservation_infos', 'arrival')" ngDefaultControl [name]="'arrival'" [(date)]="data.incident.reservation.arrival" [description]="field_for('reservation_infos', 'arrival').name" [tooltip]="field_for('reservation_infos', 'arrival').description" [disabled]="true"></app-date-input>

      <app-date-input *ngIf="field_for('reservation_infos', 'departure')" ngDefaultControl [name]="'departure'" [(date)]="data.incident.reservation.departure" [description]="field_for('reservation_infos', 'departure').name" [tooltip]="field_for('reservation_infos', 'departure').description" [disabled]="true"></app-date-input>

      <app-basic-input *ngIf="field_for('email')" ngDefaultControl [name]="'email'" [description]="field_for('email').name" [(ngModel)]="data.incident.reservation.primary_guest.email" [tooltip]="field_for('email').description" [required]="field_for('email').required"></app-basic-input>

      <app-pms-invoice-address-form [data]="data" *ngIf="ownInvoiceAddress"></app-pms-invoice-address-form>

      <app-form-fields [form]="data.incident" [submited]="submited" [module]="data.module" [redesign]="true" #fieldsComponent *ngIf="data.module.fields"></app-form-fields>
    </ng-container>
  </fieldset>
</form>
