import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss']
})
export class PhotoPreviewComponent implements OnInit {

  @Input() photoSrc: string;
  @Output() close = new EventEmitter();

  ngOnInit() {
  }

  closePreview() {
    this.close.emit();
  }
}
