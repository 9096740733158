import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class ElementNavigationService {

  constructor(private router: Router) {}

  next(element) {
    if (element['next']) {
      const url = this.router.url.split('/');
      url[0] = '/';
      url[url.length - 1] = element['next'];
      this.router.navigate(url, {replaceUrl: true});
    }
  }

  previous(element) {
    if (element['previous']) {
      const url = this.router.url.split('/');
      url[0] = '/';
      url[url.length - 1] = element['previous'];
      this.router.navigate(url, {replaceUrl: true});
    }
  }
}
