import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsPaymentService } from 'payment_service';
import { OverlayAction, PmsModType } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pms-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})

export class PmsTerminalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  payment: any;
  ui_messages: any;
  apaleoPay = false;

  @Input() data: GenericData;
  @Input() folio: any;
  @Input() payButton: any;

  constructor(public globals: Globals,
              private pmsService: PmsService,
              private paymentService: PmsPaymentService,
              private cicoService: PmsCiCoService) {}

  ngOnInit(): void {
    document.getElementById('container')?.classList?.add('overlay');
    this.payment = this.data.incident.reservation.payment_providers;
    this.apaleoPay = this.data.business.usePms('apaleo');
    this.ui_messages = this.cicoService.ui_messages();
    this.cicoService.toggleInactivity(false);
    this.paymentService.sendToTerminal(this.dataInformation()).subscribe((response: any) => {
      this.data.incident.reservation.payment_token = response.pms_token;
      response.success ? this.checkTransaction() : this.paymentError();
    });

    this.subscriptions.add(this.cicoService.overlayClose.subscribe(_val => {
      setTimeout(() => {
        this.paymentService.abortTerminal(this.dataInformation()).subscribe();
        this.enablePayButton();
      });
    }));
  }

  dataInformation() {
    return {
      locale: this.globals.guest.locale,
      folios: this.data.incident.reservation.folios,
      resId: this.data.incident.reservation.reservation_id,
      invoice_address: this.data.incident.reservation.address,
      folioId: this.folio?.number,
      origin: this.data.module.type,
      pms_token: this.data.incident.reservation.payment_token
    };
  }

  private checkTransaction() {
    setTimeout(() => {
      if (this.folio) {
        this.paymentService.paymentSubj.next(this.folio);
      }
      if (this.data.module.type === PmsModType.ci) {
        this.paymentService.checkPayment(this.data.incident.reservation.payment_token).subscribe((response: any) => {
          this.folio.check = response.state
          this.setScaValid(response.sca_valid);
          this.folio.existing_payments = response.existing_payments;
          this.checkPayment([this.folio])
        });
      } else {
        this.pmsService.getFolios(this.data.incident.reservation.uuid, this.data.incident.reservation.payment_token, this.data.module.type).subscribe((success: any) => {
          const folios = success.folios;
          this.paymentService.paymentSubj.next(folios);

          if (this.folio) {
            this.checkPayment(folios);
          }
        }, () => {
          this.paymentError();
        });
      }
      this.enablePayButton();
    }, 1500);
  }

  private checkPayment(folios) {
    const folio = folios.find(_folio => _folio.number === this.folio.number);
    if (folio?.check === 'unpaid') {
      this.paymentError();
    } else {
      this.cicoService.closeOverlay(OverlayAction.close);
    }
  }

  private enablePayButton() {
    this.cicoService.toggleInactivity(true);
    this.cicoService.disableNextButton(false);
    if (this.payButton) {
      this.payButton.disabled = false;
    }
  }

  private paymentError() {
    this.cicoService.closeOverlay(OverlayAction.close);
    this.globals.alert('error', this.ui_messages.terminal_payment_error?.content);
    this.paymentService.abortTerminal(this.dataInformation()).subscribe();
    this.enablePayButton();
  }

  private setScaValid(valid) {
    if (this.folio) {
      this.folio.sca_valid = valid;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
