import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetCallRoutingModule } from './call-routing.module';
import { WidgetCallComponent } from './call.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetCallRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetCallComponent
  ],
  declarations: [WidgetCallComponent]
})
export class WidgetCallModule { }
