import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { AddressPipe } from '../../../../../pipes/address.pipe';
import { PmsInvoiceAddressFormRoutingModule } from './form-routing.module';
import { PmsInvoiceAddressFormComponent } from './form.component';
import { AddressItemComponent } from '../address-item/address-item.component';
import { TypographyModule } from 'common/typography/typography.module';


@NgModule({
  imports: [
    CommonModule,
    PmsInvoiceAddressFormRoutingModule,
    FormSelectModule,
    FormsModule,
    TranslateModule,
    TypographyModule
  ],
  exports: [
    PmsInvoiceAddressFormComponent
  ],
  declarations: [PmsInvoiceAddressFormComponent,AddressItemComponent, AddressPipe]
})
export class PmsInvoiceAddressFormModule { }
