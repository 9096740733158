<div class="page-headline">
  <h1 [innerHtml]="title"></h1>
</div>
<div class="container">
  <div class="container-left">
    <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>
  </div>
</div>

<app-backlink></app-backlink>
