<ng-container *ngIf="business">
  <div class="page-headline">
    <h1 translate>network.title</h1>
  </div>

  <div class="container">
    <div class="container-right">
      <app-swiper [items]="business.medias"></app-swiper>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">
      <p translate>network.desc</p>
      <p translate>network.howto</p>
    </div>
  </div>
</ng-container>
