<main id="error-page">
  <div>
    <img src="/assets/images/logo_white.svg" width="202" alt="straiv.io" />

    <ng-container *ngIf="error">
      <h1>{{ error.headline }} <small [innerHTML]="error.small"></small></h1>
      <p [innerHTML]="error.description"></p>
    </ng-container>

    <div class="diagnose">
      <h3 *ngIf="code">Error: {{code}}</h3>
      <p *ngIf="diagnosis">Diagnosis: {{diagnosis}}</p>
    </div>
  </div>
</main>
