import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-camera-button',
  templateUrl: './camera-button.component.html',
  styleUrls: ['./camera-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CameraButtonComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CameraButtonComponent
    }
  ]
})

export class CameraButtonComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() guest: any;
  @Input() description: string;
  @Input() tooltip?: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Output('buttonClick') clickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
  }

  handleClick() {
    this.clickEvent.emit();
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.required ? {invalid: true} : null;
  }
}
