import { Component } from '@angular/core';

@Component({
  selector: 'app-content',
  template: `
    <section id="content">
      <router-outlet></router-outlet>
      <div class="clearfix"></div>
    </section>`
})

export class ContentComponent {

  constructor() {
  }
}
