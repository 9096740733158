<div class="title main-text normal-font-size" translate>cus.upgrades</div>

<div class="upsell-gallery-container">
  <app-upsell-item *ngFor="let item of upsellItems" [title]="item?.name" [imageUrl]="item?.medias[0]?.media?.url" [price]="item?.difference" [currentPrice]="currentPrice" [currency]="item?.currency" [selected]="item.unit_code === cicoService.cusSelectedProduct?.unit_code" (click)="selectItem(item)"></app-upsell-item>
</div>

<div class="offers smaller-font-size" *ngIf="cicoService.cusSelectedProduct?.offer_services?.length">
  <p class="smaller-font-size" translate>cus.service_included</p>
  <ul>
    <li class="smaller-font-size" *ngFor="let service of cicoService.cusSelectedProduct.offer_services"><i class="fas fa-circle-check main-text"></i>{{ service }}</li>
  </ul>
</div>
