import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleTitleRoutingModule } from './module_title-routing.module';
import { ModuleTitleComponent } from './module_title.component';

@NgModule({
  imports: [
    CommonModule,
    ModuleTitleRoutingModule,
  ],
  exports: [
    ModuleTitleComponent
  ],
  declarations: [ModuleTitleComponent]
})
export class ModuleTitleModule { }
