import { Socialmedia } from './socialmedia';

export class BusinessGroup {
  name: string;
  logo: string;
  logo_position: string;
  socialmedia: Socialmedia;

  constructor(raw_json: any) {
    this.name = raw_json.name;
    this.socialmedia = raw_json.socialmedia;

    // mapping needed, we store media directly without parent
    // if we save the business as json in the storage, the media parent is gone
    this.logo = raw_json.media ? raw_json.media.logo : raw_json.logo;
    this.logo_position = raw_json.media ? raw_json.media.logo_position : raw_json.logo_position;
  }
}


