export class CookieSetting {
  technical: boolean;
  analytics: boolean;
  timestamp: number;

  constructor(raw_json: any) {
    this.technical = true;
    this.analytics = raw_json?.analytics;
    this.timestamp = raw_json?.timestamp;
  }
}


