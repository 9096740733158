import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Globals } from 'base';
import { Subscription } from 'rxjs';
import { WebsocketService } from 'services/websocket/websocket.service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})

export class DebugComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(public globals: Globals,
              private wsService: WebsocketService) {
    this.browser = <any>navigator;
    this.browser.getUserMedia = (this.browser.getUserMedia || this.browser.webkitGetUserMedia || this.browser.mozGetUserMedia || this.browser.msGetUserMedia);
  }

  @ViewChild('debugContainer', {static: true}) debugContainer: ElementRef;

  webcamContent: any;
  browserContent: any;

  debugCount = 0;

  facingMode: string;
  browser: any;
  socketConnected: boolean;

  ngOnInit() {
    this.socketConnected = this.wsService.socketConnected && !this.wsService.socketConnected?.closed;
    this.subscriptions.add(this.wsService.online.subscribe((connected: boolean) => {
      this.socketConnected = connected;
    }));
  }

  debugTab() {
    this.debugCount++;
    if (this.debugCount === 5) {
      this.browserContent = {
        features: document.querySelector('html')['className']?.trim(),
        agent: window.navigator.userAgent,
        languages: window.navigator.languages,
        resolution: `${window.screen.availHeight}x${window.screen.availWidth}`
      };
      this.webcamContent = {devices: [], info: null};

      this.browser.mediaDevices.getUserMedia({video: true, audio: false}).then((_stream) => {
      }).catch((error) => {
        switch (error.name) {
          case 'AbortError':
            this.webcamContent.info = 'Access to media devices granted. A problem occurred which prevented the device from being used.';
            break;
          case 'NotAllowedError':
            this.webcamContent.info = 'The current browsing instance has not permitted access to the device, the user has denied access for the current session, or the user has denied all access to user media devices globally.';
            break;
          case 'NotFoundError':
            this.webcamContent.info = 'Access to media devices granted. No video devices found.';
            break;
          case 'NotReadableError':
            this.webcamContent.info = 'Access to media devices granted. A hardware error occurred at the operating system or browser which prevented access to the device.';
            break;
          default:
            this.webcamContent.info = 'An unidentified error has occurred. Please try again.';
        }
      });

      this.facingMode = this.globals.place?.facing_mode || 'environment';
      const lookup = this.facingMode === 'user' ? ['front', 'vorder', 'user'] : ['back', 'rück', 'environment'];
      let data = {};

      this.browser.mediaDevices.enumerateDevices().then(result => {
        result.filter(x => x.kind === 'videoinput' && x.label?.length).forEach(device => {
          let match = lookup.find(look => device.label.toLowerCase().includes(look))?.length;
          let info = {id: device.deviceId, name: device.label, match: !!match};
          try {
            const capas = device.getCapabilities();
            data = {
              rate: capas?.frameRate?.max,
              resolution: capas?.height?.max + 'x' + capas?.width?.max,
              side: capas?.facingMode?.length ? capas.facingMode[0] === 'user' ? 'front' : 'back' : null
            };
          } catch (e) {
            data = {error: e.message};
          }
          info = {...info, ...data};
          this.webcamContent.devices.push(info);
        });
      });

      this.debugContainer.nativeElement.classList.add('visible');
    }
  }

  closeDebug() {
    this.webcamContent = null;
    this.browserContent = null;
    this.debugCount = 0;
    this.debugContainer.nativeElement.classList.remove('visible');
    this.subscriptions.unsubscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
