import { Component, OnInit } from '@angular/core';
import { Module } from 'models/module';
import { Globals } from 'base';
import { CouponModuleService } from './coupon.service';

@Component({
  selector: 'app-coupon-module',
  templateUrl: './coupon.component.html'
})

export class CouponComponent implements OnInit {
  module: Module;
  cart: string[];

  constructor(private couponService: CouponModuleService,
              private globals: Globals) { }

  ngOnInit() {
    this.globals.getModule('coupon', false).then((mod) => {this.module = mod; }).catch(() => {});
  }

}
