<app-pms-login [mod]="ciCoType?.reservation" *ngIf="loginRequired; else process"></app-pms-login>

<ng-template #process>
  <ng-container *ngIf="loaded && res && data?.module?.type === ciCoType?.reservation; else loading">
    <app-module-title [module]="module"></app-module-title>
    <div class="container">
      <div class="container-left">
          <div label="general">
            <form>
              <div *ngIf="data && business && res && settings; else loading">
                <div id="reservations" *ngIf="all_reservations?.length > 1">
                  <swiper [config]="swiperConfig">
                    <ng-template swiperSlide *ngFor="let item of all_reservations">
                      <div class="item" (click)="reservationChanged(item.uuid)" [class.main-bg]="item.uuid === res.uuid">
                        {{item.arrival}} - {{item.departure}} <strong>{{item.reservation_id}}</strong>
                        <div class="dot" [class]="item.state_type"></div>
                        {{item.state}}
                      </div>
                    </ng-template>
                  </swiper>
                  <div *ngIf="!lastReservation"></div>
                </div>

                <div id="travel" class="form-box" *ngIf="settings.travel_dates || booking_data">
                  <div>
                    <img class="img-responsive" [src]="business.logo" [alt]="business.name" />
                  </div>
                  <div>
                    <small *ngIf="settings.travel_dates">{{business.address.city}} · {{booking_data.arrival}} - {{booking_data.departure}}</small> <small *ngIf="!settings.travel_dates">{{business.address.city}}</small>
                    <h2>{{business.name}}</h2>
                    {{'booking_data.reservation' | translate}}: {{res.reservation_id}} <small class="block" [class]="booking_data.state_type" *ngIf="booking_data.state_type !== 'no_show'">{{'booking_data.state.' + booking_data.state_type | translate}}</small>
                  </div>
                </div>

                <div class="form-box data">
                  <app-swiper [items]="medias" [lightbox]="false" [center]="true" *ngIf="medias?.length"></app-swiper>

                  <div class="half stacked seperated" *ngIf="settings.travel_dates || settings.cico_times">
                    <div>
                      <strong translate>booking_data.arrival</strong>
                      <ng-container *ngIf="settings.travel_dates">{{booking_data.arrival}}<br /></ng-container>
                      <ng-container *ngIf="settings.cico_times">
                        {{'misc.check_in' | translate}}:
                        <ng-container *ngIf="booking_data.open_for_check_in"> {{booking_data.check_in_from}} - {{booking_data.check_in_till}}</ng-container>
                        <ng-container *ngIf="!booking_data.open_for_check_in"> {{'service_time.no_service' | translate}}</ng-container>
                      </ng-container>
                    </div>
                    <div>
                      <strong translate>booking_data.departure</strong>
                      <ng-container *ngIf="settings.travel_dates">{{booking_data.departure}}<br /></ng-container>
                      <ng-container *ngIf="settings.cico_times">
                        {{'misc.check_out' | translate}}:
                        <ng-container *ngIf="booking_data.open_for_check_out"> {{booking_data.check_out_from}} - {{booking_data.check_out_till}}</ng-container>
                        <ng-container *ngIf="!booking_data.open_for_check_out"> {{'service_time.no_service' | translate}}</ng-container>
                      </ng-container>
                    </div>
                  </div>

                  <div *ngIf="settings.room && ['confirmed', 'pre_checked_in'].includes(booking_data.state_type)">
                    <ng-container *ngIf="res.unit_name || res.unit_description">
                      <strong *ngIf="res.unit_name">{{res.unit_name}}</strong>
                      <ng-container *ngIf="res.unit_description && res.unit_description !== res.unit_name">{{res.unit_description}}</ng-container>
                    </ng-container>
                  </div>

                  <div *ngIf="settings.room && booking_data.state_type === 'checked_in'">
                    <strong>{{res.room || 'booking_data.room.title' | translate}}</strong>
                    <ng-container *ngIf="res.room_description">{{res.room_description}}</ng-container>
                  </div>

                  <div *ngIf="settings.cancellation && ['confirmed', 'pre_checked_in'].includes(booking_data.state_type) && (res.cancel_name || res.cancel_description)">
                    <strong>{{res.cancel_name || 'booking_data.cancellation' | translate}}</strong>
                    <ng-container *ngIf="res.cancel_description">{{res.cancel_description}}</ng-container>
                  </div>

                  <div *ngIf="!hideActions && settings.actions && res.action?.name">
                    <button class="btn-filled" type="button" [routerLink]="res.action.url" [innerHTML]="res.action.name"></button>
                  </div>
                </div>

                <div class="form-box no-input-height static-position" *ngIf="settings.invoice && hasFolios">
                  <h3 translate>booking_data.invoice_details</h3>
                  <app-pms-folios [payment]="payment_possible" [business]="business" (noFolios)="hasFolios = false"></app-pms-folios>
                </div>

                <div *ngIf="!hideLogout" class="button ghost" (click)="logout()">Logout</div>
              </div>
            </form>
          </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-pms-cico-overlay [business]="globals.business" [content]="olContent" *ngIf="globals.business && showOverlay"></app-pms-cico-overlay>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
