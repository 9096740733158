import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PushCategoriesModule } from '../categories/categories.module';
import { PushFormComponent } from './form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PushCategoriesModule
  ],
  exports: [
    PushFormComponent,
  ],
  declarations: [PushFormComponent],
})
export class PushFormModule { }
