<h3 *ngIf="headline" translate>pms_door.name</h3>

<div class="info_box info" *ngIf="error">
  <i class="fas fa-info-circle"></i>
  <b [innerHTML]="error?.title"></b>
  <p [innerHTML]="error?.desc"></p>
</div>

<div #doors *ngIf="module && door_categories?.length; else loading">
  <h4 translate>{{only_fav_doors ? 'pms_door.doors' : 'pms_door.favorites'}}</h4>
  <div id="alert" class="alert alert-info" *ngIf="movingAlert?.length" translate>{{ movingAlert }}</div>
  <div class="menu favorites" cdkDropList id="list-of-favorites" [cdkDropListData]="favorites" [cdkDropListConnectedTo]="['list-of-availables']" (cdkDropListDropped)="drop($event)">
    <div class="alert alert-info drag-info" *ngIf="!favorites?.length" translate>pms_door.toFavorite</div>
    <div class="head" *ngIf="favorites?.length">
      <div></div>
      <div></div>
      <div translate>pms_door.unlock</div>
    </div>
    <ng-container *ngFor="let door of favorites | filter : searchText;">
      <a class="door" cdkDragLockAxis="y" cdkDrag (cdkDragEntered)="changeMovingAlert(door)">
        <div cdkDragHandle><i class="fas fa-grip-vertical"></i></div>
        <div [class.two-lines]="door.description?.length" cdkDragHandle>
          {{door.name}} <small [innerHTML]="door.description" *ngIf="door.description"></small>
        </div>
        <ng-container [ngTemplateOutlet]="doorItem" [ngTemplateOutletContext]="{$implicit:door}"></ng-container>
      </a>
    </ng-container>
  </div>

  <h4 *ngIf="!only_fav_doors" translate>pms_door.available</h4>
  <div *ngIf="!only_fav_doors" class="menu" cdkDropList id="list-of-availables" [cdkDropListData]="available" [cdkDropListConnectedTo]="['list-of-favorites']" (cdkDropListDropped)="drop($event)">
    <div class="alert alert-info drag-info" *ngIf="!available?.length" translate>pms_door.toAvailable</div>
    <div class="head" *ngIf="available?.length">
      <div></div>
      <div></div>
      <div translate>pms_door.unlock</div>
    </div>
    <ng-container *ngFor="let door of available | filter : searchText;">
      <a *ngIf="!isFav(door)" class="door" cdkDragLockAxis="y" cdkDrag>
        <div cdkDragHandle><i class="fas fa-grip-vertical"></i></div>
        <div [class.two-lines]="door.description?.length" cdkDragHandle>
          {{door.name}} <small [innerHTML]="door.description" *ngIf="door.description"></small>
        </div>
        <ng-container [ngTemplateOutlet]="doorItem" [ngTemplateOutletContext]="{$implicit:door}"></ng-container>
      </a>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <app-loader [small]="true" *ngIf="!error"></app-loader>
</ng-template>

<ng-template #doorItem let-door>
  <div>
    <div class="unlock" (mousedown)="$event.stopPropagation()">
      <div class="thumb" [attr.data-id]="door.ident || door.name"><i class="fas fa-arrow-right"></i></div>
      <span class="info" translate>pms_door.open.info</span>
      <span class="opening" translate>pms_door.open.opening</span>
      <span class="opened">{{'pms_door.open.' + (isAssaAbloy() ? 'unlocking' : 'success') | translate}}</span>
    </div>
  </div>
  <span *cdkDragPreview>{{ door.name }}</span>
</ng-template>
