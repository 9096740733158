export class TechStack {
  pms_system: any;
  payment_provider: any;
  door_system: any;

  constructor(raw_json: any) {
    this.pms_system = new Tech(raw_json?.pms_system);
    this.payment_provider = new Tech(raw_json?.payment_provider);
    this.door_system = new Tech(raw_json?.door_system);
  }
}

export class Tech {
  type: string;
  onPremise: boolean;
  haefele_api: boolean;

  constructor(raw_json: any) {
    this.type = raw_json?.type || null;
    this.onPremise = raw_json?.on_premise || false;
    this.haefele_api = raw_json?.haefele_api || false;
  }
}
