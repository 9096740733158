import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlaceValidator } from './place.validator';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    PlaceValidator
  ],
  declarations: [PlaceValidator]
})
export class PlaceValidatorModule { }
