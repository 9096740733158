import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FooterRoutingModule } from './footer-routing.module';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [
    CommonModule,
    FooterRoutingModule,
    TranslateModule
  ],
  exports: [
    FooterComponent
  ],
  declarations: [FooterComponent],
  providers: [
    TranslateService
  ]
})
export class FooterModule { }
