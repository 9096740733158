export class Address {

  address_type: string;
  company: string;
  first_name: string;
  last_name: string;
  address: string;
  po_box: string;
  vat_no: string;
  department: string;
  email: string;
  zipcode: string;
  city: string;
  state: string;
  country: any;
  country_name: string;
  eu_member: boolean;
  internal_id: string;
  internal_type: string;
  additional_id: string;
  manual = false;

  constructor(raw_json: any) {
    this.address_type = raw_json?.address_type;
    this.company = raw_json?.company;
    this.first_name = raw_json?.first_name;
    this.last_name = raw_json?.last_name;
    this.address = raw_json?.address;
    this.po_box = raw_json?.po_box;
    this.vat_no = raw_json?.vat_no;
    this.department = raw_json?.department;
    this.email = raw_json?.email;
    this.zipcode = raw_json?.zipcode;
    this.city = raw_json?.city;
    this.state = raw_json?.state;
    this.country = raw_json?.country;
    this.country_name = raw_json?.country_name;
    this.eu_member = raw_json?.eu_member;
    this.internal_id = raw_json?.internal_id;
    this.internal_type = raw_json?.internal_type;
    this.additional_id = raw_json?.additional_id;
    this.manual = raw_json?.manual;
  }

  isSame(other) {
    return other.company === this.company && other.address === this.address && other.city === this.city;
  }

  isEmpty() {
    return !this.company && !this.address && !this.zipcode && !this.city;
  }

  folioAddressInfoEmpty() {
    return this.isEmpty() && !this.first_name && !this.last_name;
  }

  htmlBlock(inline = false) {
    const separator = inline ? ', ' : '<br/>';

    let first_line = '<b>';
    if (this.company) {
      first_line += this.company;
    } else {
      first_line += (this.first_name || '') + ' ' + (this.last_name || '');
    }
    first_line += '</b>' + separator + '<br/>';
    const country = (this.country_name && !inline ? this.country_name : this.country) || '';

    return (first_line.replace(/<b><\/b>,\s*$/, '') +
      (this.address || '') + separator +
      (this.zipcode || '') + ' ' + (this.city || '') + separator +
      country).replace(/ ,/g, '').trim().replace(/,\s*$|^, /g, '');
  }

  valid(): boolean {
    return !this.manual || (((!!this.first_name && !!this.last_name) || !!this.company) && !!this.address && !!this.zipcode && !!this.city && !!this.country);
  }

  clear() {
    Object.keys(this).forEach(key => this[key] = (key === 'manual' ? false : null));
  }
}


