import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Advert } from 'models/advert';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Injectable({providedIn: 'root'})
export class WidgetAdvertService {
  module: Module;
  adverts: Array<Advert> = [];

  constructor(private api: ApiService,
              private globals: Globals) {}

  getModule() {
    return new Promise((resolve, reject) => {
      if (this.module) {
        resolve(this.module);
      } else {
        this.globals.getModule('advert', false).then((mod: Module) => {
          this.module = mod;
          resolve(this.module);
        }).catch(() => {reject()});
      }
    });
  }

  getAdverts() {
    return new Promise((resolve, reject) => {
      if (this.adverts.length) {
        resolve(this.adverts);
      } else {
        this.api.get('advert').subscribe((success: any) => {
          if (success?.adverts) {
            this.adverts = success.adverts.map(advert => new Advert(advert));
            resolve(this.adverts);
          } else {
            reject();
          }
        });
      }
    });
  }

  countClick(id): Observable<Object> {
    return this.api.get('advert/' + id);
  }
}
