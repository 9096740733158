import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConflictRoutingModule } from './conflict-routing.module';
import { ConflictComponent } from './conflict.component';

@NgModule({
  imports: [
    CommonModule,
    ConflictRoutingModule,
    TranslateModule
  ],
  exports: [
    ConflictComponent
  ],
  declarations: [ConflictComponent]
})
export class ConflictModule { }
