import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoPreviewModule } from '../photo-preview/photo-preview.module';
import { CameraButtonComponent } from './camera-button/camera-button.component';
import { PassportFormComponent } from './passport-form.component';
import { TypographyModule } from 'common/typography/typography.module';
import { PassportPhotoModule } from '../passport-photo/passport-photo.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '../datepicker/datepicker.module';

@NgModule({
  declarations: [
    PassportFormComponent,
    CameraButtonComponent
  ],
  imports: [
    CommonModule,
    TypographyModule,
    PhotoPreviewModule,
    PassportPhotoModule,
    FormsModule,
    TranslateModule,
    DatePickerModule
  ],
  exports: [
    PassportFormComponent
  ]
})
export class PassportFormModule {}
