import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PmsWizardActionsComponent } from './actions.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [PmsWizardActionsComponent],
  declarations: [PmsWizardActionsComponent]
})
export class PmsWizardActionsModule {}
