import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipeRoutingModule } from './safe-routing.pipe.module';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    SafePipeRoutingModule
  ],
  exports: [
    SafePipe
  ],
  declarations: [SafePipe]
})
export class SafePipeModule { }
