import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetLogoRoutingModule } from './logo-routing.module';
import { WidgetLogoComponent } from './logo.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetLogoRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetLogoComponent
  ],
  declarations: [WidgetLogoComponent]
})
export class WidgetLogoModule { }
