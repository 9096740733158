import { FieldValue } from './field_value';

export class GenericService {
  constructor(
    public place: string = '',
    public name: string = '',
    public email: string = '',
    public phone: string = '',
    public date: string = '',
    public dates: Array<string> = [],
    public time: string = '',
    public body: string = '',
    public image: string = null,
    public pay_method: string = '',
    public destination: string = '',
    public persons: number = 1,
    public quantity: number = 1,
    public price: number = 0,
    public selection: string = null,
    public spa_entry: string = '',
    public shop_entry: string = '',
    public complaint_entry: string = '',
    public wish_entry: string = '',
    public coupon_entry: string = '',
    public restaurant: string = '',
    public cart: string = '',
    public confirm: boolean = true,
    public field_values: FieldValue[] = [],
    public helper: string = null,
    public bool_helper: boolean = false
  ) {
  }
}
