import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { SuggestionsService } from 'widgets/suggestions/suggestions.service';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { MenuModuleService } from '../../menu.service';

@Component({
  selector: 'app-menu-list-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})

export class MenuListParentComponent extends ServiceParentDirective implements OnInit, OnDestroy {
  mod = 'menu';

  daylie: string[];
  cart: string[];

  constructor(public globals: Globals,
              protected route: ActivatedRoute,
              protected businessService: BusinessService,
              protected sanitizer: DomSanitizer,
              protected suggestionsService: SuggestionsService,
              private menuService: MenuModuleService) {
    super(globals, route, businessService, sanitizer, suggestionsService);
  }

  ngOnInit() {
    super.ngOnInit(this.menuService).then((success: any) => {
      this.daylie = success?.daylie;

      this.subscriptions.add(this.menuService.getBasket().subscribe((basket: any) => {
        if (basket.cart?.items?.length) {
          this.cart = basket.cart;
          this.rightSide = true;
        }
      }, () => {}));
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
