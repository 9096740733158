import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Business } from 'models/business';
import { DomModal } from 'models/dom_modal';
import { CookieInfoComponent } from 'common/cookie_info/cookie_info.component';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { BusinessService } from './business.service';
import { GuestService } from './guest.service';

@Injectable({providedIn: 'root'})
export class CookiesService {

  constructor(private businessService: BusinessService,
              private guestService: GuestService,
              private modalService: ModalService,
              private globals: Globals) { }

  open(force = false) {
    this.businessService.current_business.pipe(filter(Boolean), take(1)).subscribe((business: Business) => {
      const has_law = business.address?.eu_member;
      const cookies = this.guestService.getCookies();

      if (has_law && !this.globals.kiosk() && (force || !cookies?.timestamp)) {
        const body = new DomModal();
        body.title = this.globals.translate('cookie.title');
        body.component = CookieInfoComponent;
        body.closeable = false;
        body.redirect = false;
        this.modalService.open(body, true);
      }
    });
  }
}
