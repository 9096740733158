import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PmsCiCoService } from '../../../../modules/pms/cico.service';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: [
    '../input-styles.scss',
    './time-input.component.scss'
  ]
})
export class TimeInputComponent implements OnInit {

  @ViewChild('input') input;
  @Input('ngModel') inputValue = '';
  @Output('ngModelChange') inputValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() name: string;
  @Input() required: boolean;
  @Input() description: string;
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Input() submitted: boolean;
  @Input() errorMsg = 'validation.required';

  inputId: string;
  autocomplete: string;

  constructor(private cicoService: PmsCiCoService) {}

  ngOnInit(): void {
    this.autocomplete = window.screen.width >= 640 && window.screen.width <= 1320 ? 'nope' : 'on';
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
  }

  valueChange() {
    this.inputValueChange.emit(this.inputValue);
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }
}
