import { LoginProviderClass } from '../models/LoginProviderClass';
import { SocialUser } from '../models/SocialUser';
import { BaseLoginProvider } from './BaseLoginProvider';

declare let FB: any;

export class FacebookProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID = 'FACEBOOK';
  public isInitialize: boolean;
  public loginProviderObj: LoginProviderClass = new LoginProviderClass();

  constructor(private clientId: string) {
    super();
    this.loginProviderObj.id = clientId;
    this.loginProviderObj.name = 'FACEBOOK';
    this.loginProviderObj.url = 'https://connect.facebook.net/en_US/sdk.js';
  }

  initialize(): Promise<SocialUser> {
    return new Promise((reject) => {
      this.loadScript(this.loginProviderObj, () => {
        FB.init({
          appId: this.clientId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.1'
        });
        this.isInitialize = true;
        FB.AppEvents.logPageView();
      });
    });
  }

  drawUser(response: any): SocialUser {
    const user: SocialUser = new SocialUser();
    user.id = response.id;
    user.name = response.name;
    user.email = response.email;
    user.token = response.token;
    user.photoUrl = 'https://graph.facebook.com/' + response.id + '/picture?type=normal';
    return user;
  }

  signIn(): Promise<SocialUser> {
    const self = this;
    return new Promise((resolve, reject) => {
      FB.login((response: any) => {
        if (response.status === 'connected') {
          const accessToken = FB.getAuthResponse()['accessToken'];
          FB.api('/me?fields=name,email,picture', (res: any) => {
            resolve(self.drawUser(Object.assign({}, {token: accessToken}, res)));
          });
        }
      }, {scope: 'email,public_profile'});
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      FB.logout(() => {
        resolve();
      });
    });
  }

  shareMessage(url: string): Promise<void> {

    return new Promise((resolve, reject) => {
      FB.ui({
        method: 'feed',
        link: url ? url : 'https://straiv.io',
        hashtag: '#straiv'
      });
      resolve();
    });
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          resolve(true);
        }
        resolve(false);
      });
    });
  }

  getUser(): Promise<SocialUser> {
    const self = this;
    return new Promise<SocialUser>((resolve, reject) => {
      self.isLoggedIn().then(loggedIn => {
        if (loggedIn) {
          const accessToken = FB.getAuthResponse()['accessToken'];
          FB.api('/me?fields=name,email,picture', (res: any) => {
            resolve(self.drawUser(Object.assign({}, {token: accessToken}, res)));
          });
        }
      }).catch(() => {});
    });
  }
}
