import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { EventListParentRoutingModule } from './parent-routing.module';
import { EventListParentComponent } from './parent.component';
import { ServiceParentDirective } from 'shared/service/parent.directive';

@NgModule({
  imports: [
    CommonModule,
    EventListParentRoutingModule,
    FilterPipeModule,
    AttachmentsModule,
    BacklinkModule,
    SharedSwiperModule,
    WidgetAdvertModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    EventListParentComponent
  ],
  declarations: [EventListParentComponent],
  providers: [
    ServiceParentDirective
  ]
})
export class EventListParentModule { }
