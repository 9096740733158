import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: [
    '../input-styles.scss',
    './button-group.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonGroupComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ButtonGroupComponent
    }
  ]
})
export class ButtonGroupComponent implements ControlValueAccessor, Validator {
  @Input() required: boolean;
  @Input() disabled: boolean = false;
  @Input() validation: boolean = false;
  @Input() submitted: boolean = false;
  @Input() errorMsg: string;
  @Input() tooltip: string;
  @Input() description: string;
  @Input() options: any[];
  @Input('ngModel') selectedOption: string;
  @Output('ngModelChange') selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  isOptionSelected(option): boolean {
    if (this.selectedOption !== undefined) {
      return option === this.selectedOption;
    }
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.selectedOptionChange.emit(option);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.required && this.selectedOption === undefined ? {invalid: true} : null;
  }
}
