<div class="content" [class.centered-content]="encoderData.internalEncoder && !confirmation">
  <form #form="ngForm" class="redesign">
    <div *ngIf="!confirmation" class="room-number">
      <span translate>pms_door.encoder.room</span>&nbsp;<span class="main-text">{{ encoderData.room }}</span>
    </div>

    <ng-container *ngIf="!encoderData.internalEncoder">
      <h2 class="instruction" translate>pms_door.encoder.title</h2>
      <div class="entries-container">
        <div *ngFor="let step of steps let i = index" class="menu entries">
          <ng-container *ngIf="['card', 'wait'].includes(step); else general">
            <b class="numeral main-text">{{ i + 1 }}.&nbsp;</b>
            <p class="step-description">{{ 'pms_door.encoder.steps.' + systemType + '.' + step | translate }}</p>
          </ng-container>
          <ng-template #general>
            <b class="numeral main-text">{{ i + 1 }}.&nbsp;</b>
            <p class="step-description">{{ 'pms_door.encoder.steps.' + step | translate }}</p>
            <p *ngIf="step === 'encode' && remaining_keycards === 0" class="card-limit-info" translate>pms_door.encoder.error.limit</p>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </form>

  <p *ngIf="showLimitInfo" class="encoding-info card-limit-info">
    <span translate>pms_door.encoder.steps.additional_cards</span>&nbsp;<span *ngIf="remaining_keycards != null" [innerHTML]="('pms_door.encoder.steps.remaining_cards') | translate: {limit: remaining_keycards}"></span>
  </p>

  <div *ngIf="encoderData?.internalEncoder && encoding">
    <p class="encoding-info" translate>pms_door.encoder.encoding</p>
  </div>

  <div class="btn-grid" *ngIf="loaded">
    <div id="buttons">
      <button *ngIf="confirmation" [ngClass]="buttonClass" (click)="finish()" translate>service.check_in.general.finish</button>
      <button class="btn-filled" [class.small-width]="firstSuccess" (click)="generateKey()" [disabled]="encoding || !canEncode" *ngIf="!confirmation || (confirmation && canEncode)">
        {{ 'pms_door.encoder.' + encodeText | translate }}
      </button>
    </div>
  </div>
</div>
