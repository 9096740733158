import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternetAccessRoutingModule } from './internet_access-routing.module';
import { InternetAccessComponent } from './internet_access.component';

@NgModule({
  imports: [
    CommonModule,
    InternetAccessRoutingModule,
  ],
  exports: [
    InternetAccessComponent
  ],
  declarations: [InternetAccessComponent]
})
export class InternetAccessModule { }
