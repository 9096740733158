<div class="page-headline">
  <h1>{{title}}</h1>
</div>

<em [innerHtml]="description" *ngIf="description != null"></em>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>
    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">

    <div class="searchbox" *ngIf="module?.search && (categories || entries)">
      <input type="search" [(ngModel)]="searchText" placeholder="{{'misc.searchenter' | translate}}">
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>

    <div class="menu" *ngIf="categories?.length">
      <a *ngFor="let item of categories | filter : searchText" [routerLink]="['/g', globals.getCode(), 'coupon_category', item.category.id]">
        <div>{{item.category.name}}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <div class="menu entries" *ngIf="entries?.length">
      <div class="head" *ngIf="!searchText">
        <div translate>shopping_card.name</div>
        <div translate *ngIf="category && category.show_price">shopping_card.price</div>
        <div *ngIf="!category || category && !category.show_price"></div>
        <div></div>
      </div>
      <a *ngFor="let item of entries | filter : searchText" [routerLink]="['/g', globals.getCode(), 'coupon', item.entry.id]">
        <div class="two-lines">
          {{item.entry.name}}
          <small *ngIf="item.entry.description" [innerHtml]="item.entry.description | stripTags"></small>
        </div>
        <div *ngIf="!item.entry.price_range">{{item.entry.price | currency: business.currency}}</div>
        <div *ngIf="item.entry.price_range">{{item.entry.price_min | currency: business.currency}} - {{item.entry.price_max | currency: business.currency}}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
  </div>
</div>

<app-backlink [visibleMobile]="true" *ngIf="category"></app-backlink>
