import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetLogoModule } from 'widgets/logo/logo.module';
import { WidgetHeaderRoutingModule } from './header-routing.module';
import { WidgetHeaderComponent } from './header.component';
import { TypographyModule } from '../../typography/typography.module';

@NgModule({
  imports: [
    CommonModule,
    WidgetHeaderRoutingModule,
    WidgetLogoModule,
    TranslateModule,
    TypographyModule
  ],
  exports: [
    WidgetHeaderComponent
  ],
  declarations: [WidgetHeaderComponent]
})
export class WidgetHeaderModule {}
