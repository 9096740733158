import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

/**
 * NumberTypes
 * 0 PhoneNumberType.FIXED_LINE
 * 1 PhoneNumberType.MOBILE
 * 2 PhoneNumberType.FIXED_LINE_OR_MOBILE
 * 3 PhoneNumberType.TOLL_FREE
 * 4 PhoneNumberType.PREMIUM_RATE
 * 5 PhoneNumberType.SHARED_COST
 * 6 PhoneNumberType.VOIP
 * 7 PhoneNumberType.PERSONAL_NUMBER
 * 8 PhoneNumberType.PAGER
 * 9 PhoneNumberType.UAN
 * 10 PhoneNumberType.VOICEMAIL
 * 11 PhoneNumberType.UNKNOWN
 **/

@Directive({
  selector: '[phonevalidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidator,
      multi: true
    }
  ]
})
export class PhoneValidator implements Validator, OnChanges {
  validator: ValidatorFn;
  private control: FormControl;
  phoneUtil: any;
  type: any;
  required: boolean;
  countryCode: string;

  @Input('phonevalidator') options: any;

  constructor() {
    this.validator = this.phoneValidator();
    this.phoneUtil = PhoneNumberUtil.getInstance();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control?.updateValueAndValidity();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  phoneValidator(): ValidatorFn {
    return (c: FormControl) => {
      this.control = c;
      this.type = this.options.validation;
      this.required = this.options.required;
      this.countryCode = this.options.countryCode;
      const concatenatedNumber: string = this.countryCode ? this.countryCode + c.value : c.value;
      const regex: RegExp = this.countryCode ? /^\d+$|^$|\+$/ : /^\+?\d+$|^$|\+$/;

      if (c.value && !regex.test(c.value)) {
        return this.error('phone_chars');
      }
      if ((!this.required && !c.value) || !this.type || this.type === 'no_validation') {
        return null;
      } else {
        if (this.required && !c.value) {
          return this.error('required');
        }
        try {
          const parsed = this.phoneUtil.parseAndKeepRawInput(concatenatedNumber);
          const type = this.phoneUtil.getNumberType(parsed);
          if (this.type === 'mobile' && [1, 2].includes(type) || this.type === 'landline' && [0, 2].includes(type) || this.type === 'phone_number' && [0, 1, 2].includes(type)) {
            return null;
          } else {
            return this.error(this.type);
          }
        } catch (e) {
          const countryCode = parseInt(concatenatedNumber.replace('+', '').replace('00', ''), 10);
          if (!this.required && this.phoneUtil.getSupportedCallingCodes().includes(countryCode)) {
            return null;
          } else {
            return this.error(this.type);
          }
        }
      }
    };
  }

  error(error) {
    return {
      phonevalidator: {
        error: `validation.${error}`
      }
    };
  }
}
