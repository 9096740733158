export class Place {
  id: string;
  name: string;
  number: string;
  journey: string;
  cryptcode: string;
  manifest: boolean;
  test_view: boolean;
  network: string;
  has_number: boolean;
  remote_url: string;
  remote_short_url: string;
  view: string;
  wizard: boolean;
  kiosk_scanner: boolean;
  welcome: boolean;
  facing_mode: string;
  reservation_id: string;
  reservation_uuid: string;
  cico_login: boolean;
  internal_encoder: boolean;
  connected_at: any;

  constructor(raw_json: any) {
    this.id = raw_json?.id;
    this.name = raw_json?.name;
    this.number = raw_json?.number;
    this.journey = raw_json?.journey;
    this.cryptcode = raw_json?.cryptcode;
    this.manifest = raw_json?.manifest;
    this.test_view = raw_json?.test_view;
    this.network = raw_json?.network;
    this.has_number = raw_json?.has_number;
    this.remote_url = raw_json?.remote_url;
    this.remote_short_url = raw_json?.remote_short_url;
    this.view = raw_json?.view;
    this.wizard = raw_json?.wizard;
    this.kiosk_scanner = raw_json?.kiosk_scanner;
    this.welcome = raw_json?.welcome;
    this.facing_mode = raw_json?.facing_mode;
    this.reservation_id = raw_json?.reservation_id;
    this.reservation_uuid = raw_json?.reservation_uuid;
    this.cico_login = raw_json?.cico_login;
    this.internal_encoder = raw_json?.internal_encoder;
    this.connected_at = raw_json?.connected_at;
  }

  equal(place): boolean {
    const properties = ['id', 'number', 'journey', 'name', 'welcome', 'view', 'kiosk_scanner'];
    return properties.every(prop => this[prop] === place[prop]);
  }
}
