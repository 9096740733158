import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'base';
import { Guest } from 'models/guest';
import { of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';
import { ElementNavigationService } from './element_navigation.service';

@Component({
  selector: 'app-element-navigation',
  host: {'(window:keydown)': 'hotkeys($event)'},
  templateUrl: './element_navigation.component.html'
})
export class ElementNavigationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  guest: Guest;
  hideSwipeInfo: boolean;
  @Input() element: String[] = [];

  constructor(private router: Router,
              private elementNav: ElementNavigationService,
              public globals: Globals,
              private guestService: GuestService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
      this.guest = guest;
      if (this.guest.swipe) {
        this.subscriptions.add(of(true).pipe(delay(3000)).subscribe(() => {
          this.hideSwipeInfo = true;
          this.guest.swipe = false;
          this.guest.save();
        }));
      }
    }));
  }

  hotkeys(event) {
    // return if cursor is in an input signatureField
    if (event.target.tagName && (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'textarea')) {
      return;
    }

    // left arrow, navigate to previous element
    if (event.keyCode === 37) {
      this.previous();
    }
    // left arrow, navigate to next element
    if (event.keyCode === 39) {
      this.next();
    }
  }

  next() {
    this.elementNav.next(this.element);
  }

  previous() {
    this.elementNav.previous(this.element);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
