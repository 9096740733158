import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetModuleRoutingModule } from './module-routing.module';
import { WidgetModuleComponent } from './module.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetModuleRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetModuleComponent
  ],
  declarations: [WidgetModuleComponent]
})
export class WidgetModuleModule { }
