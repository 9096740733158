import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PmsCiCoService } from 'cico_service';
import { interval, Observable, Subject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { StorageService } from '../../storage.service';

@Injectable({providedIn: 'root'})
export class KioskService {
  private reservationSubj: Subject<any> = new Subject<any>();
  reservation: Observable<any> = this.reservationSubj.asObservable();

  constructor(public router: Router,
              private cicoService: PmsCiCoService,
              private storageService: StorageService) {
  }

  reject() {
    this.router.navigate(['error', 409]);
  }

  receiveData(data: any) {
    if (data && data.uuid) {
      this.cicoService.should_payment = data.should_payment;
      this.cicoService.late_reg_form = data.late_reg_form;
      this.reservationSubj.next({uuid: data.uuid, state: data.state, locale: data.locale});
    } else {
      this.cicoService.idleSubj.next(true);
    }
  }

  checkConflict(data: any, code: string) {
    const connected_at = String(data.connected_at);
    this.storageService.getItem('connected_at', code).then(storage => {
      if (String(storage.connected_at) === connected_at) {
        window.location.href = '/error/409';
      }
    }).catch((e) => {
      window.location.href = '/error/409';
    });
  }

  containerSetup() {
    document.getElementById('container')?.classList?.add('wizard', 'overlay');
    interval(10).pipe(takeWhile(() => document.getElementById('container') !== null), take(1)).subscribe(() => {
      document.getElementById('container').classList?.add('wizard', 'overlay');
    });
  }
}
