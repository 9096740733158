import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[inputValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputValidator,
      multi: true
    }
  ]
})
export class InputValidator implements Validator {

  validator: ValidatorFn;
  @Input('inputValidator') options: any;

  constructor() {
    this.validator = this.inputValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  inputValidator(): ValidatorFn {
    return (c: FormControl) => {
      return this.options.required && c.value?.length && !this.moreGoodChars(c.value) ? { chars: 'validation.chars' } : null;
    };
  }

  moreGoodChars(value: string): boolean {
    const bad = (value.match(/[^\p{L}\d]/gu) || []).length;
    const correct = value.length - bad;
    return correct > bad;
  }
}
