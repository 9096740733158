import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class CouponModuleService {

  constructor(private api: ApiService) {}

  getEntry(id: string): Observable<Object> {
    return this.api.get('coupon/entries/' + id);
  }

  getCategories(id: string): Observable<Object> {
    return this.api.get('coupon/categories/' + id);
  }

  getAll(): Observable<Object> {
    return this.api.get('coupon/all');
  }
}
