<app-description-label [description]="description" [tooltip]="tooltip" [required]="required"></app-description-label>

<fieldset class="input-container">
  <div class="radio-button-container" *ngFor="let option of options; let i = index" (click)="selectOption(option[1])">
    <input type="radio" [id]="inputId + '-option' + i" [name]="inputId" class="visually-hidden" [checked]="isOptionSelected(option[1])" (keyup.space)="selectOption(option[1])">
    <div *ngIf="!showCheckmark" class="radio" [ngClass]="{ 'selected': isOptionSelected(option[1]) }">
      <div class="fill"></div>
    </div>
    <div *ngIf="showCheckmark" class="checkmark-radio" [ngClass]="{ 'selected': isOptionSelected(option[1]) }">
      <i class="fa-solid fa-check"></i>
    </div>
    <label [for]="inputId + '-option' + i" translate>{{ option[0] }}</label>
  </div>

  <label *ngIf="required" class="error-label" [class.visible]="submitted && !selectedOption">
    <i class="fa-solid fa-circle-xmark"></i>
    <span translate>validation.required</span>
  </label>
</fieldset>
