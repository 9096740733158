import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Globals } from 'base';
import { GenericService } from 'models/generic_service';
import { Module } from 'models/module';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { OfflineService } from 'services/offline.service';
import { ServiceService } from 'services/service.service';

@Component({
  selector: 'app-taxi',
  templateUrl: './taxi.component.html'
})

export class TaxiComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericService;
  module: Module;
  rightSide = false;
  disabled = false;
  offline: boolean;
  maxDate: string;
  payments = [];
  pay_method_field: any;

  confirmName = 'send';
  error_text: String;

  @ViewChild('serviceForm', {static: true}) serviceForm: NgForm;

  constructor(public globals: Globals,
              private offlineService: OfflineService,
              public deviceService: DeviceDetectorService,
              private serviceService: ServiceService) {
    this.subscriptions.add(this.offlineService.offline.subscribe(offline => {
      this.offline = offline;
    }));
  }

  onSubmit() {
    this.serviceService.send_service(this, 'taxi');
  }

  ngOnInit() {
    this.globals.getModule('taxi').then((mod) => {
      this.module = mod;
      this.maxDate = this.module.settings.max_date;
      this.rightSide = this.module.medias && this.module.medias.length > 0;
      this.pay_method_field = this.module.field('pay_method');
      this.payments = this.pay_method_field.options;
      this.disabled = this.module.service_disabled();
      if (this.disabled) {
        this.serviceService.openTimetable();
      }
    }).catch(() => {});
    this.createForm();
  }

  createForm() {
    this.service = this.serviceService.newService();
    this.service.date = moment().add(1, 'days').format('YYYY-MM-DD');
    this.service.time = moment().set({'hour': 8, 'minutes': 0}).format('HH:mm');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
