import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetCallComponent } from './call.component';


const routes: Routes = [
  { path: '', component: WidgetCallComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetCallRoutingModule { }
