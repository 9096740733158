import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Article } from 'models/article';
import { Module } from 'models/module';
import { Globals } from 'base';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit {
  item: Article;
  showRight: boolean;
  hasContact: boolean;
  content: SafeHtml;

  @Input() module: Module;
  @Input() article: Article;

  constructor(public globals: Globals,
              private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.showRight = false;

    if (this.item === undefined) {
      this.item = this.module.article;
      this.globals.page = this.item['gid'];
    }
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.item['content']);
    this.hasContact = this.item.contact['email'] != null && this.item.contact['email'] !== '' ||
      this.item.contact['url'] != null && this.item.contact['url'] !== '' ||
      this.item.contact['phone'] != null && this.item.contact['phone'] !== '';
    this.showRight = (this.item.medias && this.item.medias.length > 0) || this.hasContact;
  }
}
