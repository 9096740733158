import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GenericFeedback } from 'models/generic_feedback';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { ServiceService } from 'services/service.service';
import { Globals } from 'base';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html'
})

export class FeedbackComponent implements OnInit {
  service: GenericFeedback;
  module: Module;
  questions: any;
  stars: number;
  rightSide = false;

  showAdditionalFields = false;
  requireAdditionalFields = false;
  showPlace = false;
  requirePlace = false;

  redirected = true;
  confirm_name = 'send';
  error_text: string;
  starsArray = [];
  feedbackSent = false;

  @ViewChild('serviceForm', {static: true}) serviceForm: NgForm;

  constructor(public globals: Globals,
              private feedbackService: FeedbackService,
              private serviceService: ServiceService,
              private route: ActivatedRoute,
              private api: ApiService) {

    this.route.fragment.subscribe((fragment: string) => {
      this.redirected = fragment === 'smart';
    });
  }

  ngOnInit() {
    this.globals.getModule('feedback').then((mod: Module) => {
      this.module = mod;
      this.globals.taskSubj.next(true);
      if (this.module.settings.replaced && !this.redirected) {
        this.globals.backSteps = 2;
        this.globals.navigate('smart_feedback', 'replaced');
        return;
      }

      this.stars = this.module.settings.scale;
      this.starsArray = Array.apply(null, {length: this.stars}).map(Function.call, Number);
      this.rightSide = this.module.medias && this.module.medias.length > 0;

      this.showPlace = this.module.settings.place_id;
      this.requirePlace = this.module.settings.require_place_id;
      if (!this.globals.validPmsGuest()) {
        this.showAdditionalFields = this.module.settings.show_additional_fields;
        this.requireAdditionalFields = this.module.settings.require_additional_fields;
      }

      this.feedbackService.getQuestions().subscribe((success: any) => {
        this.questions = success.feedback;
      }, () => {});

      this.createForm();
    }).catch(() => {});
  }

  createForm() {
    this.service = new GenericFeedback();
    this.service.email = this.globals.getEmail();
    this.service.name = this.globals.getName();
  }

  onSubmit() {
    this.globals.markAllControlsTouched(this.serviceForm);
    if (this.serviceForm.valid && this.sendFeedBack()) {
      this.globals.sending();
      this.api.post('feedback', {
        params: this.service
      }).subscribe(
        success => {
          this.globals.setEmail(this.serviceForm.value['email']);
          this.serviceService.openConfirmPage(success);
          this.globals.init_send_cancel_btn();
          this.feedbackSent = true;
        },
        error => {
          this.error_text = this.globals.formError(error);
          this.globals.init_send_cancel_btn();
        }
      );
    }
  }

  sendFeedBack(): boolean {
    return !this.feedbackSent && Object.keys(this.service.answers).length > 0 && !this.globals.send_cancel_disabled;
  }

  answer(evt, value = 0) {
    const id = evt.currentTarget.dataset['id'];

    if (evt.currentTarget.classList.contains('fa-star')) {
      this.service.answers[id] = value;
      this.starLeave(evt);
    }
  }

  starEnter(evt) {
    const id = evt.currentTarget.dataset['id'];
    const list = evt.target.parentElement.children;
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      if (!this.service.answers[id] || this.service.answers[id] < i + 1) {
        element.classList.remove('far');
        element.classList.add('fas');
      }
      if (element === evt.target) {
        return;
      }
    }
  }

  starLeave(evt) {
    const id = evt.currentTarget.dataset['id'];
    const list = evt.target.parentElement.children;
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      if (!this.service.answers[id] || this.service.answers[id] < i + 1) {
        element.classList.add('far');
        element.classList.remove('fas');
      }
    }
  }
}
