import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpaListAllModule } from './list/all/all.module';
import { SpaListParentModule } from './list/parent/parent.module';
import { SpaRoutingModule } from './spa-routing.module';
import { SpaComponent } from './spa.component';

@NgModule({
  imports: [
    CommonModule,
    SpaRoutingModule,
    SpaListAllModule,
    SpaListParentModule,
    TranslateModule
  ],
  exports: [
    SpaComponent
  ],
  declarations: [SpaComponent],
  providers: [
    TranslateService
  ]
})
export class SpaModule { }
