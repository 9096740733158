import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventListCalendarComponent } from './calendar.component';


const routes: Routes = [
  { path: '', component: EventListCalendarComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventListCalendarRoutingModule { }
