<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="module.medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <app-newsletter-form></app-newsletter-form>
  </div>
</div>
