import { Component, Injectable } from '@angular/core';
import { ModalService } from 'common/modal/modal.service';

@Component({
  selector: 'app-smart-feedback-modal',
  templateUrl: './smart_feedback_modal.component.html'
})

@Injectable()
export class SmartFeedbackModalComponent {
  constructor(private modalService: ModalService) { }

  close() {
    this.modalService.close();
  }
}
