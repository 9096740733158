import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { interval } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { StyleService } from './style.service';

declare var Dialogshift;

@Injectable({providedIn: 'root'})
export class ChatbotService {
  chat: any;
  kind: string;
  mod: Module;
  current_module: Module;

  constructor(private globals: Globals,
              private router: Router,
              private style: StyleService,
              private businessService: BusinessService,
              private api: ApiService) {
  }

  init() {
    this.businessService.current_business.pipe(filter(Boolean), take(1)).subscribe((business: Business) => {
      if (business.style.chatbot) {
        this.globals.getModule('chatbot', false).then(mod => {
          this.mod = mod;
          this.kind = this.mod.module_api?.kind;
          if (this.kind  === 'dialogshift') {
            this.initDialogshift();
          } else if (this.kind  === 'bookboost') {
            this.initBookboost();
          }
        }).catch(() => {});
      }
    });
  }

  destroy() {
    if (this.kind  === 'dialogshift' && this.chat) {
      this.chat.destroy()
    }
  }

  private initBookboost() {
    const link = 'https://app.bookboost.io/' + this.mod.module_api.credential + '/conversation.js';
    this.style.scriptToHead(link);

    interval(50).pipe(takeWhile(() => document.getElementById('web-messenger-container') !== null), take(1)).subscribe(() => {
      this.overserveContainer();
    });
  }

  private initDialogshift() {
    this.style.loadCSS('https://cdn.dialogshift.com/sdk/latest/dialogshift-webchat-sdk.min.css');
    this.style.scriptToHead('https://cdn.dialogshift.com/sdk/latest/dialogshift-webchat-sdk.umd.js');

    const dialogInterval = setInterval(() => {
      if ('Dialogshift' in window) {
        clearInterval(dialogInterval);
        const locale = this.globals.guest.locale;
        this.chat = Dialogshift.instance({
          id: this.mod.module_api.credential,
          locale: locale,
          isTeaserVisible: true,
          initialElement: {successor: 'fix-welcome-1'},
          context: this.mod.settings
        });

        this.chat.on('init', () => {
          this.customizeChat(locale);
          this.overserveContainer();
        });

        this.chat.on('command.receive', (event: any) => {
          const commandModel = event.data;
          if (commandModel.commandType === 'actionBroker' && commandModel.payload.target === 'code2order') {
            this.moduleAvailable().then((available: Boolean) => {
              if (available) {
                this.router.navigate(['g', this.globals.getCode(), commandModel.payload.modName]);
              } else {
                this.chat.triggerElement({successor: 'fix-livechat-startbyuser'});
              }
            }).catch();
          }
        });
      }
    }, 50);
  }

  private overserveContainer() {
    this.globals.module.pipe(filter(Boolean), filter((module: Module) => this.current_module?.type !== module.type)).subscribe((module: Module) => {
      this.current_module = module;
      let wrapper: any;
      if (this.kind === 'dialogshift') {
        wrapper = document.getElementsByClassName('ds-wrapper')[0];
      } else if (this.kind === 'bookboost') {
        wrapper = document.getElementById('web-messenger-container');
      }

      if (wrapper) {
        const hide = module.type.startsWith('pms_') && module.type !== 'pms_reservation'
        wrapper.style.display = hide ? 'none' : 'block';

        if (module.task_module) {
          wrapper.classList.add('elevated');
        } else if (module.type !== 'home') {
          wrapper.classList.add('elevated-mobile');
        } else {
          wrapper.classList.remove('elevated', 'elevated-mobile');
        }
      }
    });
  }

  private moduleAvailable() {
    return new Promise<boolean>((resolve, reject) => {
      this.api.put('module/chatbot').subscribe((response: any) => {
        resolve(response?.available);
      }, () => {
        reject();
      });
    });
  }

  private customizeChat(locale: string) {
    const config = this.chat.getConfig();
    const settings = ['c2oTeaserText', 'c2oButtonText'];

    settings.forEach(setting => {
      if (config[setting]) {
        const text = (config[setting][locale] || config[setting][config.defaultLocale]) || '';
        setting.includes('Teaser') ? this.chat.setTeaserText(text) : this.chat.setButtonText(text);
      }
    });

    if (config.c2oActionButtons) {
      this.chat.setActionButtons(config.c2oActionButtons);
    }

    if (config.c2oWebsiteElementCss) {
      const style = document.createElement('style');
      const head = document.querySelector('head');

      style.innerHTML = config.c2oWebsiteElementCss;
      head?.appendChild(style);
    }
  }
}
