import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { PortfolioModuleService } from '../../portfolio.service';

@Component({
  selector: 'app-portfolio-list-all',
  templateUrl: './all.component.html'
})

export class PortfolioListAllComponent extends ServiceAllDirective implements OnInit, OnDestroy {
  mod = 'portfolio';

  constructor(public globals: Globals,
              protected businessService: BusinessService,
              private portfolioService: PortfolioModuleService) {
    super(globals, businessService);
  }

  ngOnInit() {
    super.ngOnInit(this.portfolioService);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
