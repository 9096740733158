<div class="debug" (click)="debugTab()" #debugContainer>
  <div class="output" *ngIf="webcamContent || browserContent">
    <div class="close" (click)="closeDebug()"><i class="fas fa-times"></i></div>

    <div class="connection">
      <h3>Connection</h3>
      <span><div class="indicator" [class.success]="!globals.offline"></div>Online</span><br/>
      <span><div class="indicator" [class.success]="socketConnected"></div>Websocket</span>
    </div>

    <ng-container *ngIf="webcamContent?.info?.length || webcamContent.devices?.length">
      <h3>Webcam information</h3>
      <b *ngIf="webcamContent.info?.length">{{webcamContent.info}}</b>
      <b>Facingmode: {{facingMode}}</b>
      <ul *ngIf="webcamContent.devices?.length">
        <li *ngFor="let device of webcamContent.devices">
          <div *ngFor="let item of device | keyvalue"><span>{{item.key}}:</span>{{item.value}}</div>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="browserContent">
      <h3>Browser information</h3>
      <b>User-agent</b>
      <li>{{browserContent.agent}}</li>
      <b>Browser languages</b>
      <li>{{browserContent.languages}}</li>
      <b>Supported features</b>
      <li>{{browserContent.features}}</li>
      <b>Resolution</b>
      <li>{{browserContent.resolution}}</li>
    </ng-container>
  </div>
</div>
