<div class="content grid-container" *ngIf="!qrActive && !list">
  <div class="page-info">
    <app-main-title [mainTitle]="title | translate"></app-main-title>
  </div>

  <h2 *ngIf="defaultLogin" class="step-container instruction" translate>service.login.instruction</h2>
  <div class="required-text instructions step-container" translate>misc.required_fields</div>

  <form (ngSubmit)="onSubmit()" class="step-container" #serviceForm="ngForm" autocomplete="off" *ngIf="!doorActive">
    <ng-container *ngIf="defaultLogin">
      <div class="inputs-container">
        <app-basic-input [name]="resFiledName" ngDefaultControl #reservation_id="ngModel" [(ngModel)]="loginData.reservation_id" [description]="'booking_data.reservation' | translate" [placeholder]="'booking_data.reservation' | translate" required="true" [submited]="valid" [errorMsg]="'validation.reservation_id'" [tooltip]="'service.check_in.terminal.reservation_id' | translate"></app-basic-input>
        <app-basic-input [name]="nameFiledName" ngDefaultControl #last_name="ngModel" [(ngModel)]="loginData.last_name" [description]="'misc.last_name' | translate" [placeholder]="'misc.last_name' | translate" required="true" [submited]="valid" [errorMsg]="'validation.last_name'" [tooltip]="''"></app-basic-input>

        <div class="divider" *ngIf="scannerEnabled && (simplifiedLogin || terminal)">
          <div class="line"></div>
          <span class="main-text" translate>misc.or</span>
          <div class="line"></div>
        </div>

        <span *ngIf="simplifiedLogin || scannerEnabled" class="more-options redesign" translate>service.login.simplified_login</span>

        <div class="widgets-container">
          <div class="qr-code-box" (click)="simplifiedLoginPage()" *ngIf="simplifiedLogin">
            <i class="fa-solid fa-arrow-right-to-bracket main-text"></i>
            <span class="main-text" translate>service.login.login_here</span>
          </div>

          <div class="qr-code-box" (click)="useQrCode()" *ngIf="scannerEnabled">
            <svg viewBox="0 0 100 100" fill="none" class="qr-code">
              <path d="M12.498 12.5H24.998V25H12.498V12.5Z" class="main-fill" />
              <path d="M37.5 0V37.5H0V0H37.5ZM31.25 6.25H6.25V31.25H31.25V6.25ZM25 75H12.5V87.5H25V75Z" class="main-fill" />
              <path d="M37.5 62.5V100H0V62.5H37.5ZM6.25 68.75V93.75H31.25V68.75H6.25ZM75 12.5H87.5V25H75V12.5Z" class="main-fill" />
              <path d="M62.5 0V37.5H100V0H62.5ZM93.75 6.25V31.25H68.75V6.25H93.75ZM50 6.25V0H56.25V12.5H50V25H43.75V6.25H50ZM50 37.5V25H56.25V37.5H50ZM37.5 50V43.75H43.75V37.5H50V50H56.25V43.75H87.5V50H62.5V56.25H43.75V50H37.5ZM37.5 50V56.25H12.5V50H6.25V56.25H0V43.75H18.75V50H37.5ZM100 56.25H93.75V43.75H100V56.25ZM93.75 56.25H87.5V68.75H100V62.5H93.75V56.25ZM68.75 56.25H81.25V62.5H75V68.75H68.75V56.25ZM81.25 75V68.75H75V75H68.75V81.25H56.25V87.5H75V75H81.25ZM81.25 75H100V81.25H87.5V87.5H81.25V75ZM56.25 68.75V75H62.5V62.5H43.75V68.75H56.25Z" class="main-fill" />
              <path d="M43.7461 75H49.9961V93.75H74.9961V100H43.7461V75ZM99.9961 87.5V100H81.2461V93.75H93.7461V87.5H99.9961Z" class="main-fill" />
            </svg>
            <span class="main-text" translate>service.login.scan_qr</span>
          </div>
        </div>
      </div>
    </ng-container>

    <app-form-fields [form]="loginData" [module]="wizardModule" [useDefault]="true" [submited]="valid" *ngIf="!defaultLogin" [redesign]="true"></app-form-fields>

    <app-pms-simple-footer [nextBtnId]="'send_form'" [showPrivacyPolicy]="true" [showBack]="!defaultLogin" [showNext]="true" [disableNext]="globals.send_cancel_disabled" [disableBack]="false" (prevClicked)="back()" (nextClicked)="onSubmit()"></app-pms-simple-footer>
  </form>
</div>

<app-scanner *ngIf="qrActive" [mod]="mod" [kioskScanner]="kioskScanner" (success)="onScanSuccess($event)" (abort)="onScanAbort()" class="animation-in"></app-scanner>
<app-pms-cico-overlay [business]="globals.business" [content]="olContent" *ngIf="globals.business && showOverlay"></app-pms-cico-overlay>

<section *ngIf="list" class="content multiple-reservations grid-container">
  <p class="info-multi-reservations" translate>service.login.choose</p>

  <div class="reservations-container">
    <app-reservation-card *ngFor="let reservation of list" (click)="choose(reservation.id)" [reservation]="reservation"></app-reservation-card>
  </div>

  <footer *ngIf="!list">
    <div class="btn-grid">
      <button class="btn-outline" (click)="back()" translate>misc.back</button>
    </div>
  </footer>
</section>
