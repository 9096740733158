import { Directive, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appBooleanValidator][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: BooleanValidatorDirective, multi: true}
  ]
})
export class BooleanValidatorDirective implements Validator, OnChanges {
  @Input('appBooleanValidator') options: any;
  private validator: ValidatorFn;

  constructor() {
    this.validator = this.createValidator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      this.validator = this.createValidator();
    }
  }

  validate(c: FormControl) {
    return this.options && !this.options.disabled ? this.validator(c) : null;
  }

  private createValidator(): ValidatorFn {
    return (c: FormControl): { [key: string]: any } | null => {
      if (!this.options) {
        return null;
      }
      return !this.options.isValid ? {'booleanError': true} : null;
    };
  }
}
