import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PmsCiCoService } from '../../../../modules/pms/cico.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: [
    './search-input.component.scss'
  ]
})
export class SearchInputComponent implements OnInit {

  @Input('ngModel') value: string;
  @Output('ngModelChange') valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() name: string;
  @Input() placeholder!: string;

  inputId: string;
  autocomplete: string;

  constructor(
    private cicoService: PmsCiCoService
  ) {}

  ngOnInit(): void {
    this.autocomplete = window.screen.width >= 640 && window.screen.width <= 1320 ? 'nope' : 'on';
    this.placeholder = this.placeholder ? this.placeholder : this.name;
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
  }

  inputValueChange(value: string) {
    this.valueChange.emit(value);
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }
}
