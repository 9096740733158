import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: [
    '../input-styles.scss',
    './radio-group.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioGroupComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RadioGroupComponent
    }
  ]
})
export class RadioGroupComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() inputId: string;
  @Input() showCheckmark: boolean = false;
  @Input() options: any[];
  @Input() description: string;
  @Input() tooltip?: string;
  @Input() required: boolean = false;
  @Input() submitted: boolean;
  @Input('ngModel') selectedOption: any;
  @Output('ngModelChange') selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
  }

  isOptionSelected(option): boolean {
    if (this.selectedOption !== undefined) {
      return option === this.selectedOption;
    }
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.selectedOptionChange.emit(option);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.required && this.selectedOption === undefined ? {invalid: true} : null;
  }
}
