import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { Globals } from 'base';

@Component({
  selector: 'app-vcard',
  templateUrl: './vcard.component.html'
})
export class VcardComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  module: Module;
  medias: [];
  lat = 0;
  lng = 0;
  map: any;
  show_cico: boolean;
  check_in: string;
  check_out: string;


  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  constructor(public globals: Globals,
              private businessService: BusinessService,
              private guestService: GuestService) { }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.lat = parseFloat(this.business.coordinates.lat);
      this.lng = parseFloat(this.business.coordinates.lng);

      this.globals.getModule('vcard').then((mod: Module) => {
        this.module = mod;
        this.medias = this.module.medias.length > 0 ? this.module.medias : this.module.settings.images;
        this.show_cico = this.module.settings.show_cico;
        this.check_in = this.module.settings.check_in;
        this.check_out = this.module.settings.check_out;
      }).catch(() => {});
    });
  }

  showSocial() {
    return this.business.socialmedia && Object.keys(this.business.socialmedia).length > 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
