import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Guest } from 'models/guest';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';

@Injectable({providedIn: 'root'})
export class WeatherService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  showFahrenheitSubj: BehaviorSubject<boolean> = new BehaviorSubject(navigator.language === 'en-US');
  showFahrenheit: Observable<boolean> = this.showFahrenheitSubj.asObservable();

  constructor(private api: ApiService,
              private guestService: GuestService,
              private globals: Globals,
              private storageService: StorageService) {
    this.subscriptions.add(this.guestService.currentGuest.subscribe((guest: Guest) => {
      if (guest?.hasOwnProperty('showFahrenheit')) {
        this.updateUnit(guest.showFahrenheit);
      }
    }));
  }

  getWeather(): Observable<Object> {
    return this.api.get('weathers');
  }

  updateUnit(showFahrenheit: boolean): void {
    this.globals.guest.showFahrenheit = showFahrenheit;
    this.storageService.setItem('current_guest', this.globals.guest.toLocalStorage());
    this.showFahrenheitSubj.next(showFahrenheit);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
