<div class="input-container" [ngClass]="{ 'valid': inputValue?.length > 0 && input.valid, 'invalid': (input.dirty && input.invalid) || (submited && input.invalid), 'disabled': disabled}">
  <app-description-label [for]="inputId" [description]="description" [tooltip]="tooltip" [required]="required && !disabled"></app-description-label>

  <div class="input-wrapper">
    <input class="redesign" [id]="inputId" #input="ngModel" [name]="name" [type]="type" [(ngModel)]="inputValue" (ngModelChange)="valueChange()" [placeholder]="placeholder || description" [autocomplete]="autocomplete" [required]="required" [disabled]="disabled" (focus)="changeKeyboardState(true)" (blur)="changeKeyboardState(false)" (paste)="paste.emit($event)" [inputValidator]="{ required: required }" [emailvalidator]="{ disabled: validation !== 'email', other: otherEmail }" [lengthValidator]="{ disabled: validation !== 'length', min: min, max: max }" [numberValidator]="{ disabled: type !== 'number', min: min, max: max}" [appBooleanValidator]="{disabled: !duplicated || !duplicated.value, isValid: !duplicated?.value}">
    <i class="fa-solid fa-check checkmark-icon"></i>
    <i class="fa-solid fa-lock locked-icon"></i>
  </div>

  <label class="error-label" [class.visible]="input.dirty && input.invalid || (submited && input.invalid)">
    <i class="fa-solid fa-circle-xmark"></i>
    <span>{{ input.errors?.chars || errorMsg | translate: errorArgs}}</span>
  </label>
</div>
