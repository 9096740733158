import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapProtectionComponent } from './map_protection..component';


const routes: Routes = [
  { path: '', component: MapProtectionComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapProtectionRoutingModule { }
