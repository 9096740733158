import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetGroupBusinessItemRoutingModule } from './group_business_item-routing.module';
import { WidgetGroupBusinessItemComponent } from './group_business_item.component';

@NgModule({
  imports: [
    CommonModule,
    WidgetGroupBusinessItemRoutingModule,
    TranslateModule
  ],
  exports: [
    WidgetGroupBusinessItemComponent
  ],
  declarations: [WidgetGroupBusinessItemComponent]
})
export class WidgetGroupBusinessItemModule { }
