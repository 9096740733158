import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfoModule } from 'pms_modules/shared/info/info.module';
import { CookieInfoRoutingModule } from './cookie_info-routing.module';
import { CookieInfoComponent } from './cookie_info.component';

@NgModule({
  imports: [
    CommonModule,
    CookieInfoRoutingModule,
    InfoModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    CookieInfoComponent
  ],
  declarations: [CookieInfoComponent]
})
export class CookieInfoModule { }
