import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { PmsCiCoBaseDirective } from 'modules/pms/base/cico_base.directive';
import { PmsKioskComponent } from '../modules/pms/kiosk/kiosk.component';
import { AllocatorComponent } from 'modules/shared/allocator/allocator.component';
import { OverlayAction, OverlayType } from 'pms_enums';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CiCoGuard implements CanDeactivate<AllocatorComponent> {
  canDeactivate(component: AllocatorComponent): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const cicoComponent = <PmsCiCoBaseDirective | PmsKioskComponent>(component?.view?.instance || component);

    if (cicoComponent?.suppressGuard || !component) {
      return !!component;
    }

    return new Promise<boolean>((resolve, reject) => {
      if (!cicoComponent?.cicoService) {
        resolve(true);
      }
      cicoComponent.cicoService.openOverlay(OverlayType.cancel, null, null, true);
      cicoComponent.cicoService.overlayClose.subscribe((close: any) => {
        resolve(close.action === OverlayAction.cancel);
      }, () => {
        reject();
      });
    });
  }
}
