import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class TeaserService {
  private teaserSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  teaser: Observable<any> = this.teaserSubj.asObservable();

  constructor() {
  }

  setTeaser(data: any) {
    this.teaserSubj.next(data);
  }
}
