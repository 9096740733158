import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { LegalModule } from '../../../shared/forms/legal/legal.module';
import { PmsBaseDirective } from '../../base/base.directive';
import { PmsSignatureRoutingModule } from './signature-routing.module';
import { PmsSignatureComponent } from './signature.component';

@NgModule({
  imports: [
    CommonModule,
    PmsSignatureRoutingModule,
    SignaturePadModule,
    FormsModule,
    TranslateModule,
    LegalModule
  ],
  exports: [
    PmsSignatureComponent
  ],
  declarations: [PmsSignatureComponent],
  providers: [
    PmsBaseDirective
  ]
})
export class PmsSignatureModule { }
