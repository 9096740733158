import { Component, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PmsModType } from 'pms_enums';
import { GuestService } from 'services/guest.service';
import { OfflineService } from 'services/offline.service';
import { StorageService } from 'services/storage.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { PmsCiCoService } from 'cico_service';
import { PmsPaymentService } from 'payment_service';
import { fadeInAnimation } from 'app/route-animations';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-check-out',
  templateUrl: './check_out.component.html',
  styleUrls: ['../check_in/check_in.component.scss'],
  animations: [fadeInAnimation]
})

export class PmsCheckOutComponent extends PmsCiCoBaseDirective implements OnInit, OnDestroy {
  constructor(protected guestService: GuestService,
              protected differs: KeyValueDiffers,
              protected storageService: StorageService,
              protected offlineService: OfflineService,
              protected wsService: WebsocketService,
              protected paymentService: PmsPaymentService,
              public cicoService: PmsCiCoService,
              public globals: Globals,
              protected route: ActivatedRoute,
              protected readonly eventService: EventAggregatorService,
              protected stepperService: StepperService,
              protected modalService: ModalService) {
    super(cicoService, guestService, differs, storageService, globals, modalService, paymentService, route, offlineService, wsService, eventService, stepperService);
  }

  ngOnInit() {
    super.ngOnInit(PmsModType.co);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
