import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Magazine } from 'models/api/goldkey/magazine';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-goldkey-parent',
  templateUrl: './parent.component.html'
})
export class GoldkeyCategoryParentComponent implements OnInit {
  module: Module;
  categories: Array<string>;
  magazines: Magazine[] = [];
  searchText: string;

  constructor(public globals: Globals,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private api: ApiService) {
  }

  ngOnInit() {
    this.globals.getModule('newspaper').then((mod) => {
      this.module = mod;
      this.route.params.subscribe(params => {
        const category = params['id'] === undefined ? '' : params['id'];
        this.api.get('api/goldkey/categories/' + category).subscribe((data: any) => {
            if (category) {
              for (const mag of data) {
                this.magazines.push(new Magazine(mag, this.sanitizer));
              }
            } else {
              this.categories = data;
            }
          }, () => {}
        );
      });
    }).catch(() => {});
  }
}
