import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { LocationModule } from 'common/location/location.module';
import { httpInterceptorProviders } from '../../../http-interceptors';
import { AlarmModule } from '../../alarm/alarm.module';
import { GoldkeyModule } from '../../api/goldkey/goldkey.module';
import { TacModule } from '../../api/tac/tac.module';
import { BasketModule } from '../../basket/basket.module';
import { ComplaintModule } from '../../complaint/complaint.module';
import { CouponModule } from '../../coupon/coupon.module';
import { EventModule } from '../../event/event.module';
import { FeedbackModule } from '../../feedback/feedback.module';
import { GroupPortfolioModule } from '../../group_portfolio/overview/group_portfolio.module';
import { HousekeepingModule } from '../../housekeeping/housekeeping.module';
import { MenuModuleModule } from '../../menu/menu.module';
import { NewsletterModule } from '../../newsletter/newsletter.module';
import { NewspapersModule } from '../../newspapers/newspapers.module';
import { PmsCheckInModule } from '../../pms/check_in/check_in.module';
import { PmsCheckOutModule } from '../../pms/check_out/check_out.module';
import { PmsDoorModule } from '../../pms/door/door.module';
import { PoiModule } from '../../poi/poi.module';
import { PortfolioModuleModule } from '../../portfolio/portfolio.module';
import { ReservationModule } from '../../reservation/overview/reservation.module';
import { ShopModule } from '../../shop/shop.module';
import { SpaModule } from '../../spa/spa.module';
import { TaxiModule } from '../../taxi/taxi.module';
import { TvStationsModule } from '../../tv_stations/tv_stations.module';
import { VcardModule } from '../../vcard/vcard.module';
import { WeatherModule } from '../../weather/weather.module';
import { WifiModule } from '../../wifi/wifi.module';
import { WishModule } from '../../wish/wish.module';
import { ArticleModule } from '../article/article.module';
import { FilesModule } from '../files/files.module';
import { LinkModule } from '../link/link.module';
import { ListModule } from '../list/list.module';
import { ScriptModule } from '../script/script.module';
import { AllocatorRoutingModule } from './allocator-routing.module';
import { AllocatorComponent } from './allocator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    AllocatorRoutingModule,
    AlarmModule,
    ArticleModule,
    BasketModule,
    ComplaintModule,
    CouponModule,
    EventModule,
    FeedbackModule,
    FilesModule,
    GoldkeyModule,
    GroupPortfolioModule,
    HousekeepingModule,
    LinkModule,
    ListModule,
    LocationModule,
    MenuModuleModule,
    NewsletterModule,
    NewspapersModule,
    PmsCheckInModule,
    PmsCheckOutModule,
    PmsDoorModule,
    PoiModule,
    PortfolioModuleModule,
    ReservationModule,
    ScriptModule,
    ShopModule,
    SpaModule,
    TacModule,
    TaxiModule,
    TranslateModule,
    TvStationsModule,
    VcardModule,
    WeatherModule,
    WifiModule,
    WishModule
  ],
  exports: [
    AllocatorComponent
  ],
  declarations: [AllocatorComponent],
  providers: [
    CookieService,
    CurrencyPipe,
    httpInterceptorProviders,
    Meta,
    Title,
    TranslateService
  ]
})
export class AllocatorModule { }
