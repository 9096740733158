import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ReservationRoutingModule } from './reservation-routing.module';
import { ReservationComponent } from './reservation.component';

@NgModule({
  imports: [
    CommonModule,
    ReservationRoutingModule,
    WidgetAdvertModule,
    ModuleTitleModule,
    SharedSwiperModule
  ],
  exports: [
    ReservationComponent
  ],
  declarations: [ReservationComponent]
})
export class ReservationModule { }
