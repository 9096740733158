import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class PoiModuleService {

  constructor(private api: ApiService) {}

  getEntry(id: string): Observable<Object> {
    return this.api.get('poi/entries/' + id);
  }

  getCategories(id): Observable<Object> {
    return this.api.get('poi/categories/' + id);
  }

  getAll(): Observable<Object> {
    return this.api.get('poi/all');
  }
}
