import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { LengthValidatorModule } from 'validators/length.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackComponent } from './feedback.component';

@NgModule({
  imports: [
    CommonModule,
    FeedbackRoutingModule,
    SharedSwiperModule,
    ModuleTitleModule,
    WidgetAdvertModule,
    TranslateModule,
    FormPlaceModule,
    SendCancelModule,
    EmailValidatorModule,
    LengthValidatorModule,
    FormsModule
  ],
  exports: [
    FeedbackComponent
  ],
  declarations: [FeedbackComponent]
})
export class FeedbackModule { }
