import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BasketWidgetRoutingModule } from './widget-routing.module';
import { BasketWidgetComponent } from './widget.component';

@NgModule({
  imports: [
    CommonModule,
    BasketWidgetRoutingModule,
    TranslateModule
  ],
  exports: [
    BasketWidgetComponent
  ],
  declarations: [BasketWidgetComponent]
})
export class BasketWidgetModule { }
