import { Component, OnInit } from '@angular/core';
import { Link } from 'models/link';
import { Module } from 'models/module';
import { Globals } from 'base';
import { TvStationsService } from './tv_stations.service';

@Component({
  selector: 'app-tv-stations',
  templateUrl: './tv_stations.component.html',
  styleUrls: ['./tv_stations.component.scss']
})
export class TvStationsComponent implements OnInit {
  module: Module;
  schedule: Link;
  tvChannels: string[];
  radioChannels: string[];
  showFlags = false;
  rightSide = false;

  constructor(public globals: Globals,
              private tvstationsService: TvStationsService) { }

  ngOnInit() {
    this.globals.getModule('tv_stations').then((mod) => {
      this.module = mod;
      this.rightSide = this.module.medias && this.module.medias.length > 0;
      this.schedule = this.module.link;
      this.showFlags = this.module.settings.show_flags;
    }).catch(() => {});
    this.tvstationsService.getTvStations().subscribe(
      success => {
        this.tvChannels = success['tvstations'];
        this.radioChannels = success['radiostations'];
      }, () => {}
    );
  }

}
