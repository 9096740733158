import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  updateSubj: Subject<boolean> = new Subject<boolean>();
  update: Observable<boolean> = this.updateSubj.asObservable();

  constructor(private api: ApiService) {}

  getMenu(view): Observable<Object> {
    return this.api.silentGet('navigation/' + view);
  }

  getOfflineModules(): Observable<any> {
    return this.api.silentGet('offline/modules');
  }
}
