<ng-container *ngIf="entry">
  <div class="page-headline" (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
    <h2><i class="ad-icon" *ngIf="entry.advert"></i>{{entry.name}}</h2>
  </div>

  <em [innerHtml]="entry.description" *ngIf="entry.description?.length" (swipeleft)="swipe($event)" (swiperight)="swipe($event)"></em>

  <div class="container" (swipeleft)="swipe($event)" (swiperight)="swipe($event)">
    <div class="container-right">
      <app-swiper [items]="entry.medias" *ngIf="entry?.medias?.length && trigger"></app-swiper>

      <div class="article-address">
        <h4 translate>misc.address</h4>
        <p>
          <ng-container *ngIf="address.company">{{address.company}}<br /></ng-container>
          {{address.address}}<br />
          {{address.zipcode}} {{address.city}}<br />
          {{address.country}}
        </p>
      </div>

      <div class="article-address" *ngIf="coordinates && !entry.inhouse">
        <h4 translate>poi.distance</h4>
        <ul class="icon-list">
          <li *ngIf="coordinates.distance">
            <i class="fas fa-route fa-fw"></i> {{coordinates.distance}}
          </li>
          <li *ngIf="coordinates.duration">
            <i class="fas fa-fw" [class]="coordinates.icon"></i> {{coordinates.duration}}
          </li>
        </ul>
      </div>

      <div class="article-address" *ngIf="contact.url || contact.phone">
        <h4 translate>poi.contact_details</h4>
        <ul class="icon-list">
          <li *ngIf="contact.phone">
            <i class="fas fa-phone fa-fw"></i>
            <a target="_blank" href="tel:{{contact.phone}}">{{contact.phone}}</a>
          </li>
          <li *ngIf="contact.url">
            <i class="fas fa-home fa-fw"></i>
            <a translate [class.ext-link]="contact.url_target === '_blank'" (click)="globals.openUrl(contact.url, contact.url_target)">misc.open_website</a>
          </li>
        </ul>
      </div>

      <app-widget-advert></app-widget-advert>
    </div>
    <div class="container-left">

      <div class="tiles clearfix">
        <a translate target="_blank" class="tile" href="tel:{{contact.phone}}" *ngIf="contact.phone">
          <i class="fas fa-phone"></i>misc.call
        </a>
        <a translate class="tile" [href]="media.ical" *ngIf="coordinates?.found">
          <i class="fas fa-save"></i>misc.save
        </a>
        <a translate class="tile ext-link" (click)="globals.openUrl(address.route)">
          <i class="fas fa-route"></i>misc.route
        </a>
      </div>

      <b [innerHtml]="entry.event_category.name" *ngIf="entry.event_category"></b><br><br>
      <b translate>misc.from</b>: {{appointment.date_from}} <span *ngIf="appointment.time_from">- {{appointment.time_from}}</span><br>
      <b translate>misc.till</b>: {{appointment.date_till}} <span *ngIf="appointment.time_till">- {{appointment.time_till}}</span><br>

      <div [innerHtml]="content" *ngIf="entry.content"></div>
      <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>

      <ng-container *ngIf="!globals.offline && coordinates?.found">
        <h4 translate>poi.map</h4>
        <app-map [latitude]="coordinates.latitude" [longitude]="coordinates.longitude" [background]="entry.media?.map"></app-map>
      </ng-container>
    </div>
  </div>

  <app-element-navigation [element]="entry"></app-element-navigation>
</ng-container>
