import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';

@Injectable({providedIn: 'root'})
export class TvStationsService {

  constructor(private api: ApiService) {}

  getTvStations(): Observable<Object> {
    return this.api.get('tv_stations');
  }

}
