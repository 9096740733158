import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';
import { WidgetAdvertComponent } from 'widgets/advert/advert.component';
import { WidgetCallComponent } from 'widgets/call/call.component';
import { WidgetClockComponent } from 'widgets/clock/clock.component';
import { WidgetGroupComponent } from 'widgets/group/group.component';
import { WidgetGroupBusinessComponent } from 'widgets/group_business/group_business.component';
import { WidgetGroupImageComponent } from 'widgets/group_image/group_image.component';
import { WidgetModuleComponent } from 'widgets/module/module.component';
import { WidgetQrCodeComponent } from 'widgets/qr_code/qr_code.component';
import { WidgetSearchComponent } from 'widgets/search/search.component';
import { WidgetSocialComponent } from 'widgets/social/social.component';
import { WidgetSuggestionsComponent } from 'widgets/suggestions/suggestions.component';
import { WidgetWeatherComponent } from 'widgets/weather/weather.component';
import { WidgetA2hsComponent } from 'widgets/a2hs/a2hs_widget.component';
import { A2hsService } from 'services/a2hs.service';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  loaded: Array<any> = [];

  mapping = {
    homescreen: WidgetA2hsComponent,
    advert: WidgetAdvertComponent,
    module: WidgetModuleComponent,
    pms_door: WidgetModuleComponent,
    call: WidgetCallComponent,
    clock: WidgetClockComponent,
    group_logo: WidgetGroupComponent,
    group_business: WidgetGroupBusinessComponent,
    image: WidgetGroupImageComponent,
    qr_code: WidgetQrCodeComponent,
    search: WidgetSearchComponent,
    social: WidgetSocialComponent,
    suggestions: WidgetSuggestionsComponent,
    weather: WidgetWeatherComponent
  };

  @ViewChild('widgets', {read: ViewContainerRef, static: true}) widgets: ViewContainerRef;
  @ViewChild('container', {static: true}) container: ElementRef;

  constructor(private sidebarService: SidebarService,
              private guestService: GuestService,
              private resolver: ComponentFactoryResolver,
              private a2hsService: A2hsService) {
  }

  ngAfterViewInit() {
    this.loadWidgets();
    this.subscriptions.add(this.guestService.currentPlace.pipe(filter(Boolean)).subscribe(() => {
      this.loadWidgets();
    }, () => { }));
  }

  loadWidgets() {
    const sidebar = this.container.nativeElement;
    this.subscriptions.add(this.sidebarService.getWidgets().subscribe((success: any) => {
      this.loaded.forEach((widget: any) => { widget.destroy(); });
      this.loaded = [];
      success.widgets.forEach((widget: any) => {
          const component = this.mapping[widget.type];
          if (component) {
            const factory = this.resolver.resolveComponentFactory(component);
            const wid = <any>this.widgets.createComponent(factory);
            wid.instance.widget = widget.settings?.widget;
            this.loaded.push(wid);

            const classes = widget.css_class ? widget.css_class.split(' ') : [];
            for (const klass of classes) { wid.location?.nativeElement?.classList?.add(klass); }
          }
      });

      sidebar.addEventListener('click', (e: any) => {
        if (e?.path?.find(elem => elem.tagName === 'A')) {
          this.toggle();
        }
      });
    }, () => { }));
  }

  toggle() {
    this.sidebarService.toggleSidebar();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
