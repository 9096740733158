import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedNewspaperParentModule } from '../shared/parent.module';
import { GoldkeyRoutingModule } from './goldkey-routing.module';
import { GoldkeyComponent } from './goldkey.component';
import { GoldkeyCategoryParentComponent } from './list/parent/parent.component';

@NgModule({
  imports: [
    CommonModule,
    GoldkeyRoutingModule,
    SharedNewspaperParentModule
  ],
  declarations: [GoldkeyComponent, GoldkeyCategoryParentComponent]
})
export class GoldkeyModule { }
