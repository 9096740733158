<app-pms-terminal-timer></app-pms-terminal-timer>

<ng-container *ngIf="cicoService.process || showAvailableActions; else start">
  <app-pms-login *ngIf="!reservation && cicoService.process !== PmsProcess.booking" (reservation_data)="setReservation($event)" [wizardModule]="module" [mod]="modType"></app-pms-login>
  <ng-container *ngIf="reservation">
    <app-pms-terminal-option *ngIf="showAvailableActions" (reset_terminal)="reset()" [reservation]="reservation" [business]="business" [options]="options" (selOptionChange)="handleAction($event)"></app-pms-terminal-option>
    <app-pms-terminal-door *ngIf="cicoService.process === PmsProcess.door" [reservation]="reservation" [type]="doorType"></app-pms-terminal-door>
    <app-pms-kiosk-reservation *ngIf="cicoService.process === PmsProcess.reservation" [reservation]="reservation"></app-pms-kiosk-reservation>
    <app-check-in *ngIf="cicoService.process === PmsProcess.check_in" [res]="reservation"></app-check-in>
    <app-check-out *ngIf="cicoService.process === PmsProcess.check_out" [res]="reservation"></app-check-out>
  </ng-container>
  <app-pms-booking *ngIf="cicoService.process === PmsProcess.booking"></app-pms-booking>
</ng-container>

<ng-template #start>
  <div id="welcome" *ngIf="business && module && place">
    <div class="content" [style.background-image]="'url(' + imageUrl + ')'">
      <div class="welcome-section welcome-page-msg" *ngIf="place.welcome">
        <p class="welcome-text welcome-page-msg-outlined" translate>misc.welcome</p>
        <p class="hotel-name overview-text-container">{{business.name}}</p>
      </div>

      <app-pms-wizard-actions *ngIf="terminal && kiosk_actions" [actions]="kiosk_actions" (actionClick)="handleAction($event)"></app-pms-wizard-actions>
      <span *ngIf="showDeviceName" class="terminal-info">{{place.name}}</span>
    </div>

    <app-debug></app-debug>
  </div>
</ng-template>

<app-conflict *ngIf="wsService.showConflict"></app-conflict>
