<div class="input-container" [ngClass]="{ 'valid': inputValue?.length > 0 && input.valid, 'invalid': (input.dirty && input.invalid) || (submited && input.invalid), 'disabled': disabled }">

  <app-description-label [for]="inputId" [description]="description" [required]="required && !disabled" [tooltip]="tooltip"></app-description-label>

  <div class="input-wrapper">
    <textarea [id]="inputId" [name]="name" #input="ngModel" [(ngModel)]="inputValue" (ngModelChange)="valueChange()" [placeholder]="placeholder" [required]="required" [disabled]="disabled" [attr.data-cy]="name" (focus)="changeKeyboardState(true)" (blur)="changeKeyboardState(false)" [inputValidator]="{ required: required }"></textarea>
    <i class="fa-solid fa-check checkmark-icon"></i>
    <i class="fa-solid fa-lock locked-icon"></i>
  </div>

  <label class="error-label" [class.visible]="(input.dirty && input.invalid || (submited && input.invalid))">
    <i class="fa-solid fa-circle-xmark"></i>
    <span>{{ input.errors?.chars || 'validation.required' | translate}}</span>
  </label>
</div>
