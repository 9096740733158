<app-swiper [items]="news.medias" [lightbox]="false" *ngIf="news?.medias?.length"></app-swiper>

<div class="popup-text">
  <div [innerHtml]="content"></div>

  <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>
  <app-widget-social *ngIf="show_social"></app-widget-social>
</div>

<div class="button-grid">
  <a class="button main-bg" *ngIf="hasLink" (click)="action()">
    {{news.link.name != null ? news.link.name : 'misc.more' | translate }}
  </a>
  <a translate class="button light close" (click)="close()" *ngIf="!updateNews()">misc.close</a>
</div>
