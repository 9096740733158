import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenericSmartFeedback } from 'models/generic_smart_feedback';
import { Guest } from 'models/guest';
import { Link } from 'models/link';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { GuestService } from 'services/guest.service';
import { ServiceService } from 'services/service.service';
import { Globals } from 'base';
import { ModalService } from 'common/modal/modal.service';

@Component({
  selector: 'app-smart-feedback',
  templateUrl: './smart_feedback.component.html',
  styleUrls: ['./smart_feedback.component.scss']
})

export class SmartFeedbackComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  service: GenericSmartFeedback;
  link: Link;
  guest: Guest;
  moodable = true;
  comment = false;
  mode: String;
  text = false;
  response: any;
  module: Module;
  replaced = false;
  moods: Array<String> = [];

  confirm_name = 'misc.send';

  @Input() modal: any;
  @ViewChild('serviceForm', {static: true}) serviceForm: NgForm;

  constructor(public globals: Globals,
              private serviceService: ServiceService,
              private guestService: GuestService,
              private modalService: ModalService,
              private api: ApiService,
              private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      this.replaced = fragment === 'replaced';
    });
  }

  ngOnInit() {
    this.globals.getModule('smart_feedback', false).then((module: Module) => {
      this.globals.taskSubj.next(false);

      this.module = module;
      this.modalService.setTitle(this.module.settings.question);
      this.moods = this.module.settings.moods;
      this.service = new GenericSmartFeedback;
      this.guest = this.guestService.guest;

      if (this.guest.mood) {
        this.answer(this.guest.mood, null);
      }
    }).catch((_error) => {
      this.close();
    });
  }

  answer(mood: string, evt: any) {
    if (this.moodable) {
      this.moodable = false;

      if (evt) {
        const emojis = Array.from(document.getElementsByClassName('emoji'));
        emojis.filter((emoji) => emoji !== evt.target).forEach((emoji) => emoji.classList.add('grayscale'));

        this.guest.mood = mood;
        this.service.mood = mood;
      }

      this.api.post('smart_feedback', {mood: mood}).subscribe((success: any) => {
        this.response = success.mood;
        this.text = this.response.show_text;
        this.mode = this.response.redirect;

        if (this.globals.isJourney()) {
          this.guestService.setSmartFeedback();
        }

        if (this.mode === 'external') {
          this.link = new Link(this.response.link);
        }
        if (this.mode === 'comment') {
          this.comment = true;
          this.service.email = this.globals.getEmail();
          this.service.name = this.globals.getName();
        } else {
          this.confirm_name = 'misc.next';
          if (!this.text) {
            this.action();
          }
        }
      }, () => {});
    }
  }

  action() {
    if (this.serviceForm.valid) {
      if (this.mode === 'internal') {
        this.globals.navigate(this.response['contract_module'], 'smart');
      }
      if (this.mode === 'external' && this.link.url?.length) {
        if (this.link.url.startsWith('mailto:')) {
          window.location.href = this.link.url;
        } else {
          this.globals.openUrl(this.link.url, this.link.url_target);
        }
      }
      if (this.mode === 'comment') {
        this.sendFeedback();
      }
      this.close();
      if (this.globals.isJourney()) {
        this.guest.smart_feedback = null;
        this.guest.save();
      }
    }
  }

  sendFeedback() {
    this.api.put('smart_feedback/new', {params: this.service}).subscribe(success => {
      this.globals.setEmail(this.service.email);
      this.serviceService.openConfirmPage(success, false);
    }, () => {});
  }

  close() {
    this.modal?.close();
  }

  ngOnDestroy(): void {
    this.guest = this.guestService.guest;
    if (this.guest) {
      this.guest.mood = null;
      this.guest.save();
    }
    this.subscriptions.unsubscribe();
  }
}
