import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-description-label',
  templateUrl: './description-label.component.html',
  styleUrls: [
    '../../input-styles.scss',
    './description-label.component.scss'
  ]
})
export class DescriptionLabelComponent implements OnInit {

  @Input('for') inputId: string;
  @Input() description: string;
  @Input() required: boolean;
  @Input() tooltip?: string;

  constructor() {}

  ngOnInit(): void {
  }

}
