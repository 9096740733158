<ng-container *ngIf="mapProtection">
  <ng-container  *ngIf="unprotected; else protection">
    <google-map #googleMap [class.overview]="large" [options]="options" [center]="center">
      <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options" (mapClick)="openInfoWindow(markerElem, marker)"></map-marker>
      <map-info-window *ngIf="activeMarker">
        <ng-template [ngTemplateOutlet]="boundType == 'poi' ? poi : group" [ngTemplateOutletContext]="{$implicit:activeMarker}"></ng-template>
      </map-info-window>
    </google-map>
  </ng-container>
  <ng-template #protection>
    <app-map-protection [protection]="mapProtection" (unprotected)="loadMap()"></app-map-protection>
  </ng-template>
</ng-container>

<ng-template #poi let-entry>
  <small [innerHtml]="entry.poi_category.name" *ngIf="entry.poi_category != null"></small>
  <h4><i class="ad-icon" *ngIf="entry.advert"></i>{{entry.name}}</h4>
  <p [innerHtml]="entry.content ? entry.content.substring(0, 200) + '...' : ''"></p>
  <ul class="icon-list" *ngIf="entry.coordinates">
    <li *ngIf="entry.coordinates.distance">
      <i class="fas fa-route"></i> {{entry.coordinates.distance}}
    </li>
    <li *ngIf="entry.coordinates.duration">
      <i class="fas fa-car"></i> {{entry.coordinates.duration}}
    </li>
  </ul>
  <p class="text-right">
    <a translate [routerLink]="['/g', globals.getCode(), 'poi', entry.id]">misc.more</a>
  </p>
</ng-template>

<ng-template #group let-entry>
  <h4 [innerHtml]="entry.name"></h4>
  <div class="group">
    <img [src]="entry.media.image" [alt]="entry.name" *ngIf="entry.media.image" />
    <div>
      <p>
        {{entry.address.address}}<br/>
        {{entry.address.zipcode}} {{entry.address.city}}<br/>
        {{entry.address.country}}<br/>
      </p>
      <a class="ext-link" translate (click)="openUrl(entry.contact.url)" *ngIf="entry.contact.url">misc.open_website</a>
      <a class="ext-link" (click)="openUrl(entry.contact.booking_url)" *ngIf="entry.contact.booking_url" [innerHtml]="entry.contact.book_direct"></a>
    </div>
  </div>
</ng-template>

