import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { PmsDoorApiRoutingModule } from './api-routing,module';
import { PmsDoorApiComponent } from './api.component';

@NgModule({
  imports: [
    CommonModule,
    PmsDoorApiRoutingModule,
    FilterPipeModule,
    TranslateModule,
    LoaderModule,
    DragDropModule
  ],
  exports: [
    PmsDoorApiComponent
  ],
  declarations: [PmsDoorApiComponent]
})
export class PmsDoorApiModule { }
