export class Field {
  id: number;
  name: string;
  description: string;
  kind: string;
  active: boolean;
  required: boolean;
  group: string;
  identifier: string;
  value: string;
  check_out_if: string;
  validation: string;
  min: any;
  max: any;
  options: any;
  settings: any;
  fields: Field[] = [];

  constructor(raw_json: any) {
    this.id = raw_json?.id;
    this.name = raw_json?.name;
    this.description = raw_json?.description;
    this.kind = raw_json?.kind;
    this.active = raw_json?.active;
    this.required = raw_json?.required;
    this.group = raw_json?.group;
    this.identifier = raw_json?.identifier;
    this.validation = raw_json?.validation;
    this.min = raw_json?.min;
    this.max = raw_json?.max;
    this.check_out_if = raw_json?.check_out_if;
    this.options = raw_json?.options;
    this.settings = raw_json?.settings;

    for (const field of raw_json?.fields || []) {
      this.fields.push(new Field(field.field));
    }
  }

  setting(ident): any {
    return this.settings.find(setting => setting.identifier === ident)?.value;
  }

  subField(ident): Field {
    return this.fields.find(field => field.identifier === ident);
  }
}
