<section id="header">
  <a class="icon" [routerLink]="['/g/', globals.getCode(), 'language']" *ngIf="multilingual; else phone" [ngClass]="{'disabled_state': offline}">
    <ng-container *ngIf="languageService.language?.language && languageService.language?.flag; else fallback_globe">
      <img [src]="languageService.language.flag" [alt]="languageService.language.language" class="flag-icon">
      {{languageService.language.language}}
    </ng-container>
  </a>

  <ng-template #fallback_globe>
    <i class="fas fa-globe-americas"></i>Language
  </ng-template>

  <ng-template #phone>
    <a class="icon" href="tel:{{business.contact.phone}}" *ngIf="business">
      <i class="fas fa-phone"></i>{{'misc.call' | translate }}
    </a>
  </ng-template>

  <a class="icon" [routerLink]="['/g/', globals.getCode()]" (click)="resetProcess()">
    <i class="fas fa-home"></i>{{'footer.home' | translate }}
  </a>

  <app-widget-logo></app-widget-logo>

  <span class="icon" (click)="showSearch()" [ngClass]="{'disabled_state': offline}">
    <i class="fas fa-search"></i>{{'misc.search' | translate }}
  </span>
</section>
