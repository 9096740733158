import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.scss']
})
export class IconBoxComponent implements OnInit {

  @Input('ngModel') selected: boolean = Boolean(false);
  @Output('ngModelChange') selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() cutText: boolean;
  @Input() biggerSize: boolean;
  @Input() text: string;

  maxLength = 20

  constructor() { }

  ngOnInit(): void {
    if (this.cutText && this.text.length > this.maxLength) {
      this.text = `${this.text.slice(0, this.maxLength - 3)}...`;
    }
  }

  handleClick() {
    this.selected = true;
    this.selectedChange.emit(this.selected);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
