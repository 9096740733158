import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Business } from 'models/business';
import { ApiService } from 'api_service';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';

@Injectable({providedIn: 'root'})
export class BusinessService {

  private business: Business;

  private locale = new BehaviorSubject<string>('de');
  currentLocale = this.locale.asObservable();

  private businessSubj: BehaviorSubject<Business> = new BehaviorSubject<Business>(null);
  current_business: Observable<Business> = this.businessSubj.asObservable();

  constructor(private api: ApiService,
              private routerService: RouterService,
              private storageService: StorageService,
              private _business: Business) {
    // get business from local storage
    this.routerService.getCurrentCode().subscribe(code => {
      if (code) {
        this.storageService.getItem('current_business', code).then(business => {
          this.business = this._business;
          this.business.setBusiness(business);
          this.businessSubj.next(this.business);
        }).catch(() => {});
      }
    });
  }

  setCurrentBusiness(business: Business) {
    this.business = business;
    this.businessSubj.next(this.business);
  }

  getCurrentBusinessFromApi(): Observable<Object> {
    return this.api.get('business');
  }

  reloadBusiness() {
    this.getCurrentBusinessFromApi().subscribe((data: any) => {
      const business = this.business.setBusiness(data.business);
      this.setCurrentBusiness(business);
    }, () => {});
  }

  setLanguageTo(locale: string) {
    this.locale.next(locale);
  }
}
