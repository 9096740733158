export class ListCategory {
  gid: string;
  id: string;
  category: string;
  name: string;
  description: string;
  module: string;
  media: Object;
  medias: any;

  constructor(raw_json: any) {
    this.gid = raw_json.gid;
    this.id = raw_json.id;
    this.category = raw_json.list_category_id;
    this.name = raw_json.name;
    this.description = raw_json.description;
    this.module = raw_json.mod;
    this.media = raw_json.media;
    this.medias = raw_json.medias;
  }
}


