import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PlaceValidator } from 'validators/place.validator';
import { PlaceValidatorModule } from 'validators/place.validator.module';
import { LengthValidatorModule } from 'validators/length.validator.module';
import { FormSelectModule } from '../select/select.module';
import { FormPlaceRoutingModule } from './place-routing.module';
import { FormPlaceComponent } from './place.component';

@NgModule({
  imports: [
    CommonModule,
    FormPlaceRoutingModule,
    TranslateModule,
    FormsModule,
    FormSelectModule,
    PlaceValidatorModule,
    LengthValidatorModule
  ],
  exports: [
    FormPlaceComponent
  ],
  declarations: [FormPlaceComponent],
  providers: [
    PlaceValidator
  ]
})
export class FormPlaceModule {}
