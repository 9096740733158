import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { interval, of, Subject, Subscription } from 'rxjs';
import { delay, take, takeWhile } from 'rxjs/operators';
import { PassportPhotoService } from 'services/passport-photo.service';
import { Globals } from 'base';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnDestroy {

  @Input() imageUpload: boolean = false;
  @Input() data: string;
  @Input() mod: string;
  @Input() lostKey: boolean;
  @Input() recaptureTrigger$: Subject<boolean> = new Subject();
  @Output() success = new EventEmitter<any>();
  @Output() abort = new EventEmitter();
  @Output('captured') capturedChange = new EventEmitter<any>();

  LOOKUP = {user: ['front', 'vorder', 'user'], environment: ['back', 'rück', 'environment']};

  subscriptions: Subscription = new Subscription();
  hideVideo: boolean;
  hideCapture: boolean;
  cameraSet: boolean;
  private deviceIdx: number;
  private savedDeviceIdx: number;
  isLoading = false;
  timeout: number;

  browser: any;
  width: null;
  height: null;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  facingMode: string;
  started: boolean;
  captured: boolean = false;

  stream: any;
  video: HTMLVideoElement;
  loadingFinished: boolean = false;
  cameraChanging: boolean = false;

  constructor(private passportPhotoService: PassportPhotoService, private globals: Globals) {
    this.facingMode = this.globals.place?.facing_mode || 'environment';

    this.browser = <any>navigator;
    this.browser.getUserMedia = (this.browser.getUserMedia || this.browser.webkitGetUserMedia || this.browser.mozGetUserMedia || this.browser.msGetUserMedia);

    this.browser.mediaDevices.enumerateDevices().then(test => {
      this.browser.mediaDevices.enumerateDevices().then(result => {
        this.onCamerasFound(result.filter(x => x.kind === 'videoinput'));
        if (this.imageUpload) {
          this.initCapture();
        }
      });
    });

    ZXingScannerComponent.prototype.reset = function () {
      this._reset();
      setTimeout(() => {
        this.deviceChange.emit(null);
      });
    };
  }

  private static canvas() {
    return <any>document.querySelector('app-passport-photo canvas');
  }

  private static video() {
    return <any>document.querySelector('app-passport-photo video');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.recaptureTrigger$.subscribe(() => this.reCapture());
  }

  async initCapture(change = false) {
    this.hideVideo = false;
    this.hideCapture = true;
    this.video = CameraComponent.video();

    const constraints = {video: {deviceId: this.currentDevice.deviceId}, audio: false};

    this.stream?.getVideoTracks().forEach(track => track.stop());

    await this.browser.mediaDevices.getUserMedia(constraints).then(async stream => {
      this.stream = stream;
      this.video.srcObject = stream;
      this.cameraChanging = false;
      await this.browser.mediaDevices.enumerateDevices().then(async result => {
        await this.onCamerasFound(result.filter(x => x.kind === 'videoinput'));
      });
      await this.video.play().then(() => this.loadingFinished = true);
    }).catch(() => {
      change ? this.changeError() : this.noCamera();
    });
  }

  capture() {
    if (this.captured) {
      return this.reCapture();
    }

    this.hideVideo = true;
    this.hideCapture = false;

    const canvas = CameraComponent.canvas();
    const video = CameraComponent.video();

    video.pause();

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    this.width = video.videoWidth;
    this.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0);

    video.srcObject.getTracks()[0].stop();

    this.captured = true;
    this.capturedChange.emit(this.captured);

    this.captureSuccess();
  }

  reCapture() {
    this.captured = false;
    this.capturedChange.emit(this.captured);
    this.data = null;
    this.initCapture();
  }

  captureSuccess() {
    this.isLoading = true;
    this.success.emit(CameraComponent.canvas().toDataURL('image/jpeg'));
  }

  onScanSuccess(data) {
    const payload = {data: data, mod: this.mod};
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    if (this.deviceIdx === undefined) {
      this.availableDevices = devices;

      const lookup = this.LOOKUP[this.facingMode];
      this.deviceIdx = this.availableDevices.indexOf(this.availableDevices.find(d => lookup.find(look => d.label.toLowerCase().includes(look))));
      if (this.deviceIdx === -1 && this.availableDevices.length > 0) {
        this.deviceIdx = 0;
      }
      this.savedDeviceIdx = this.deviceIdx;
      this.currentDevice = devices[this.deviceIdx];
      this.onStarted();
    }
  }

  onStarted() {
    const self = this;

    this.subscriptions.add(interval(50).pipe(takeWhile(() => CameraComponent.video()), take(1)).subscribe(() => {
      const video = CameraComponent.video();
      video.addEventListener('playing', () => {
        self.started = true;
      }, false);
    }));
  }

  switchCamera() {
    this.cameraChanging = true;
    this.deviceIdx = ++this.deviceIdx < this.availableDevices.length ? this.deviceIdx : 0;
    this.currentDevice = this.availableDevices[this.deviceIdx];

    if (this.imageUpload) {
      const current = this.facingMode;
      this.facingMode = current === 'environment' ? 'user' : 'environment';
      this.video.pause();
      this.initCapture(true).finally(() => {
        this.cameraChanging = false;
      });
    } else {
      of(true).pipe(delay(1200)).subscribe(() => {
        this.cameraChanging = false;
      }).unsubscribe();
    }
  }

  useDefinedCamera() {
    this.subscriptions.add(of(true).pipe(delay(400)).subscribe(() => {
      if (this.availableDevices) {
        const correct = this.availableDevices[this.savedDeviceIdx];
        if (correct && this.currentDevice !== correct) {
          this.currentDevice = this.availableDevices[this.savedDeviceIdx];
        }
        this.cameraSet = true;
      }
    }));
  }

  scanAbort() {
    this.passportPhotoService.changeCameraVisible(false);
    this.abort.emit();
  }

  noCamera() {
    this.subscriptions.add(of(true).pipe(delay(5000)).subscribe(() => {
      this.scanAbort();
    }));
  }

  changeError() {
    this.switchCamera();
    this.subscriptions.add(of(true).pipe(delay(5000)).subscribe(() => {}));
  }

  ngOnDestroy(): void {
    const tracks = (this.video?.srcObject as MediaStream)?.getTracks()
    if (tracks) {
      tracks[0].stop();
    }
    this.subscriptions.unsubscribe();
  }
}
