import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { Address } from 'models/address';

@Component({
  selector: 'app-address-item',
  templateUrl: './address-item.component.html',
  styleUrls: ['./address-item.component.scss']
})
export class AddressItemComponent implements OnChanges, OnInit {

  @Input() address: Address;
  @Input() index: number;
  @Input() changeable: boolean;
  @Input('checked') checked: boolean;

  @Output() editClick = new EventEmitter<number>();
  @Output() deleteClick = new EventEmitter<number>();

  @Output('checkedChange') checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  menuOpen: boolean;
  positionColor: { primary: string, light: string } = { primary: '#F64E60', light: '#FFE2E5' };

  POSITION_COLORS: { primary: string, light: string }[] = [
    { primary: '#F64E60', light: '#FFE2E5' },
    { primary: '#8950FC', light: '#EEE5FF' },
    { primary: '#FFA800', light: '#FFF4DE' },
    { primary: '#3699FF', light: '#E1F0FF' },
    { primary: '#4ce201', light: '#d7f5cd' }
  ];

  constructor(private _cdr: ChangeDetectorRef,
              private cicoService: PmsCiCoService) {}

  ngOnInit() {
    this.positionColor = this.POSITION_COLORS[this.index] || this.POSITION_COLORS[0];
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this._cdr.detectChanges();
  }

  openMenu() {
    this.menuOpen = true;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  onCheckboxClick() {
    this.checked = !this.checked;
    if (this.checked) {
      this.cicoService.setFolioAddressStep('folio');
      this.cicoService.displayedFolioIndex = -1;
      this.cicoService.addressChange = false;
    }

    this.checkedChange.emit(this.checked);
  }

  onEditClick() {
    this.editClick.emit(this.index);
    this.closeMenu();
  }

  onDeleteClick() {
    this.deleteClick.emit(this.index);
    this.closeMenu();
  }

}
