import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'base';
import { environment } from 'environments/environment';
import { LogType } from 'global_enums';
import * as moment from 'moment';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatMap, delay, filter, retryWhen, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';
import { KioskService } from 'services/websocket/wizard/kiosk.service';
import { Guest } from 'models/guest';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  subscriptions: Subscription = new Subscription();
  taskModule = false;
  retries = 0;

  retry = {
    delay: 500,
    status: [0, 408, 500, 502, 503, 504]
  };

  constructor(private router: Router,
              private guestService: GuestService,
              private kioskService: KioskService,
              private storageService: StorageService,
              private globals: Globals) {
    moment.locale(this.guestService.getLocale());
    this.subscriptions.add(this.globals.taskObservable.subscribe(taskModule => {
      this.taskModule = taskModule;
    }));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and set the new header in one step.
    return fromPromise(this.token(req)).pipe(switchMap(token => {
      const authReq = req.clone({
        setHeaders: {
          'X-Token': token,
          'X-Code': this.code(),
          'X-Version': environment.version
        }
      });

      // send cloned request with header to the next handler.
      // if the precondition failed, redirect to beginning of the app
      return next.handle(authReq).pipe(filter((evt) => evt instanceof HttpResponse || evt instanceof HttpErrorResponse), retryWhen(error =>
          error.pipe(concatMap((resp, count) => this.retryContent(resp, count)), delay(this.retry.delay), takeWhile(() => this.retries <= 4))), tap((event: any) => {
          if (event.headers.get('X-App') && this.code()) {
            this.storageService.getItem('current_version', this.code()).then(savedVersion => {
              if (moment().diff(moment.unix(savedVersion.reloaded), 'minutes')) {
                if (event.headers.get('X-App') !== savedVersion.version) {
                  this.writeVersion(event);
                  if (!this.taskModule) {
                    of(true).pipe(delay(500)).subscribe(() => {
                      this.globals.log('new version', LogType.info, true);
                      location.reload();
                    });
                  }
                }
              }
            }).catch(() => {
              this.writeVersion(event);
            });
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.globals.error = err;
          if (err.status === 412 && err.error['code']) {
            let cryptcode = err.error['code'];
            this.router.routerState.root.queryParams.subscribe(params => {
              if (token) {
                this.router.navigate(['/', cryptcode], {queryParams: params});
              } else {
                this.guestService.redirectFallback(this.router.url, this.globals.queryString(params), cryptcode);
              }
            });
          } else if (err.status === 401 || err.status === 429) {
            this.router.navigate(['/error', err.status]);
          }
          return throwError(err);
        })
      );
    }));
  }

  writeVersion(event) {
    this.storageService.setItem('current_version', {
      code: this.code(),
      version: event.headers.get('X-App'),
      reloaded: moment().unix()
    });
  }

  code() {
    if (this.guestService.getCode()) {
      return this.guestService.getCode();
    } else {
      const path = location.pathname.split('/');
      return path[path.indexOf('g') + 1];
    }
  }

  token(req) {
    return new Promise<any>((resolve, reject) => {
      const cryptcode = req?.body?.cryptcode || true;
      if (this.guestService.getToken() || cryptcode) {
        resolve(this.guestService.getToken() || '');
      } else {
        this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
          resolve(guest.token);
        }, () => {
          reject();
        });
      }
    });
  }

  retryContent(error, count): Observable<any> {
    if (this.retry.status.includes(error.status) && this.retries < 4) {
      this.retries += 1;
      return of(error);
    }
    return throwError(error);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
