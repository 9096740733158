import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from 'shared/link/link.module';
import { PmsBookingComponent } from './booking.component';
import { PmsSimpleFooterModule } from '../../shared/simple-footer/simple-footer.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    LinkModule,
    PmsSimpleFooterModule
  ],
  exports: [
    PmsBookingComponent
  ],
  declarations: [PmsBookingComponent]
})
export class PmsBookingModule {}
