import { Component, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { PmsModType } from 'pms_enums';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';
import { Globals } from 'base';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-pms-door',
  templateUrl: './door.component.html'
})
export class PmsDoorComponent extends PmsCiCoBaseDirective implements OnInit, OnDestroy {
  business: Business;
  module: Module;
  type: string;

  searchText: string;

  constructor(public globals: Globals,
              public cicoService: PmsCiCoService,
              private businessService: BusinessService,
              protected guestService: GuestService,
              protected differs: KeyValueDiffers,
              protected storageService: StorageService) {
    super(cicoService, guestService, differs, storageService, globals);
  }

  ngOnInit() {
    super.ngOnInit(PmsModType.door);

    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });

    this.subscriptions.add(this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((_guest: Guest) => {
      if (this.loginRequired) {
        this.subscriptions.add(this.guestService.loginRequired.subscribe((loginRequired: boolean) => {
          this.loginRequired = loginRequired;
        }, () => {}));
      }
    }, () => {}));

    this.globals.getModule(PmsModType.door).then((mod) => {
      this.module = mod;
    }).catch(() => {});
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
