import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponRoutingModule } from './coupon-routing.module';
import { CouponComponent } from './coupon.component';
import { CouponListAllModule } from './list/all/all.module';
import { CouponListParentModule } from './list/parent/parent.module';

@NgModule({
  imports: [
    CommonModule,
    CouponRoutingModule,
    CouponListParentModule,
    CouponListAllModule
  ],
  exports: [
    CouponComponent
  ],
  declarations: [CouponComponent]
})
export class CouponModule { }
