import { Component, Injectable, OnInit } from '@angular/core';
import { Globals } from 'base';
import { ModalService } from 'common/modal/modal.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-pms-version',
  templateUrl: './version.component.html'
})

@Injectable()
export class PmsVersionComponent implements OnInit {
  status: any;
  error: string;
  headline: any;
  content: any;

  constructor(private globals: Globals,
              private modalService: ModalService) { }

  ngOnInit(): void {
    const subscription = interval(50).subscribe(() => {
      if (this.status) {
        if ([423, 426].includes(this.status)) {
          this.error = this.status === 423 ? 'maintenance' : 'outdated';
          this.headline = this.globals.translate('errors.' + this.error + '.title');
          this.content = this.globals.translate('errors.' + this.error + '.description');
        } else {
          this.content = this.globals.translate('validation.error');
        }
        subscription.unsubscribe();
      }
    });
  }

  close() {
    this.modalService.close();
    if (this.error !== 'outdated' && !this.globals.kiosk()) {
      this.globals.navigate('home');
    } else {
      this.globals.reload();
    }
  }
}
