<div class="animation-out" *ngIf="fields" [hidden]="showCamera">
  <div #passportForm class="form-grid">

    <div class="passport-row" *ngIf="guest.passport_data || (fields.passport_image && guest.passport_image)">
      <app-select *ngIf="guest.valid_documents" ngDefaultControl name="doc_type" [items]="docTypes" [(ngModel)]="guest.doc_type" [required]="requiredDocType" [description]="'service.check_in.documents.title' | translate" [placeholder]="'validation.selection' | translate" [submited]="submited" [showIcons]="false" [triggerKeyboard]="true"></app-select>

      <ng-container *ngIf="fields.passport_image">
        <app-camera-button *ngIf="guest.valid_documents && !guest.passport" ngModel name="passport-camera-button" [guest]="guest" (buttonClick)="addPassportDoc()" [description]="fields.passport_image.name" [tooltip]="fields.passport_image.description" [submitted]="submited" [required]="guest.passport_upload_required"></app-camera-button>
        <app-file-upload *ngIf="guest.valid_documents && !guest.passport" name="file_upload" [trigger$]="fileUploadTrigger$" [pdf]="true" (success)="onUploadSuccess($event)" [required]="false"></app-file-upload>

        <div class="photos-container" *ngIf="guest.passport">
          <div class="photo-container">
            <div *ngIf="!photoIsPdf" class="photo-wrapper" (contextmenu)="preventContextMenu($event)" (click)="photoClick(guest.passport)">
              <img [src]="passportImg" alt="">
            </div>
            <img *ngIf="photoIsPdf" class="pdf-icon" src="assets/images/pdf.svg" alt="PDF">
            <button type="button" class="delete-button main-bg" (click)="removePhoto()" (press)="$event.stopPropagation()" [attr.data-cy]="'delete-image'"><i class="fa-solid fa-xmark"></i></button>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="guest.passport_data && guest.valid_documents">
      <app-basic-input *ngIf="fields.passport_id" ngDefaultControl [name]="'passport_id'+guestIndex" [(ngModel)]="guest.passport_id" [duplicated]="checkDuplicate(passDuplicateIndex,'passport')" validation="length" min="6" max="254" [required]="fields.passport_id.required" [description]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_id.name" [tooltip]="fields.passport_id.description" (ngModelChange)="setRequiredDocTyp(); handleDocumentChange('passport_id');" [submited]="submited"></app-basic-input>
      <app-basic-input *ngIf="fields.passport_authority" ngDefaultControl name="passport_authority" [(ngModel)]="guest.passport_authority" [required]="fields.passport_authority.required" [description]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_authority.name" [tooltip]="fields.passport_authority.description" (ngModelChange)="setRequiredDocTyp()" [submited]="submited"></app-basic-input>
      <app-date-input *ngIf="fields.passport_date" ngDefaultControl name="passport_date" [(date)]="guest.passport_date" [description]="fields.passport_date.name + (guest.doc_type ? (' (' + ('service.check_in.documents.' + guest.doc_type | translate) + ')') : '')" [required]="fields.passport_date.required" [tooltip]="fields.passport_date.description" [errorMsg]="'validation.date_future'" [validation]="fields.passport_date.validation" (dateChange)="setRequiredDocTyp()" [submitted]="submited"></app-date-input>
      <app-date-input *ngIf="fields.passport_expire" ngDefaultControl name="passport_expire" [(date)]="guest.passport_expire" [description]="fields.passport_expire.name + (guest.doc_type ? (' (' + ('service.check_in.documents.' + guest.doc_type | translate) + ')') : '')" [required]="fields.passport_expire.required" [tooltip]="fields.passport_expire.description" [errorMsg]="'validation.date_past'" [validation]="fields.passport_expire.validation" (dateChange)="setRequiredDocTyp()" [submitted]="submited"></app-date-input>
    </ng-container>

    <ng-container *ngIf="guest.visa_data">
      <app-basic-input *ngIf="fields.visa_number" ngDefaultControl [name]="'visa_number'+guestIndex" [(ngModel)]="guest.visa_number" [duplicated]="checkDuplicate(visaDuplicateIndex,'visa')" [required]="fields.visa_number.required" [description]="fields.visa_number.name" [tooltip]="fields.visa_number.description" [errorMsg]="'validation.visa_number'" [submited]="submited" (ngModelChange)="handleDocumentChange('visa_number');"></app-basic-input>
      <app-date-input *ngIf="fields.visa_date" ngDefaultControl name="visa_date" [(date)]="guest.visa_date" [description]="fields.visa_date.name" [required]="fields.visa_date.required" [tooltip]="fields.visa_date.description" [validation]="fields.visa_date.validation" [errorMsg]="'validation.visa_date'" [submitted]="submited"></app-date-input>
      <app-date-input *ngIf="fields.visa_expire" ngDefaultControl name="visa_expire" [(date)]="guest.visa_expire" [description]="fields.visa_expire.name" [required]="fields.visa_expire.required" [tooltip]="fields.visa_expire.description" [validation]="fields.visa_expire.validation" [errorMsg]="'validation.visa_expire'" [submitted]="submited"></app-date-input>
    </ng-container>
  </div>
  <app-photo-preview class="animation-out" *ngIf="guest.passport_image && showPreview" [photoSrc]="passportImg" (close)="showPreview = false"></app-photo-preview>
</div>

<app-passport-photo class="animation-out" [type]="guest.doc_type" (success)="valueChange()" *ngIf="showCamera"></app-passport-photo>
