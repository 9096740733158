import { Injectable } from '@angular/core';
import { StorageService } from 'services/storage.service';
import { CookieSetting } from './cookie_setting';
import { Place } from './place';
import { Visit } from './visit';

@Injectable({providedIn: 'root'})
export class Guest {
  id: string;
  token: string;
  lat: string;
  lng: string;
  locale: string;
  mood: string;
  smart_feedback: any;
  newsletter: boolean;
  can_subscribe: boolean;
  browser: string;
  platform: string;
  version: string;
  swipe: boolean;
  place: Place;
  email: string;
  name: string;
  steps = 0;
  visits: Visit[] = [];
  push_categories: Number[] = [];
  subscription_id: Number;
  cookies: CookieSetting;
  code: string;
  showFahrenheit: boolean;
  reservation: any;
  test_mode: string;
  updated: Number; // vor storage

  constructor(private storageService: StorageService) {
  }

  setGuest(guest: any) {
    this.id = guest.id;
    this.token = guest.token;
    this.locale = guest.locale;
    this.lat = guest.lat;
    this.lng = guest.lng;
    this.steps = guest.steps || 0;
    this.mood = guest.mood;
    this.swipe = guest.swipe;
    this.browser = guest.browser ? guest.browser.toLowerCase() : null;
    this.platform = guest.platform;
    this.version = guest.version;
    this.email = guest.email || '';
    this.name = guest.name || '';
    this.newsletter = guest.newsletter;
    this.can_subscribe = guest.can_subscribe;
    this.smart_feedback = guest.smart_feedback;
    this.place = guest.place ? new Place(guest.place) : null;
    this.code = guest.code;
    this.cookies = guest.cookies ? new CookieSetting(guest.cookies) : new CookieSetting({});
    if (guest.push_categories) {
      this.push_categories = guest.push_categories;
    }
    if (guest.subscription_id) {
      this.subscription_id = guest.subscription_id;
    }
    if (guest.showFahrenheit !== undefined) {
      this.showFahrenheit = guest.showFahrenheit;
    }
    this.reservation = guest.reservation || null;
  }

  popups(): boolean {
    return this.test_mode !== 'no_popups'
  }

  validPmsGuest() {
    return this.place?.view === 'pms' && this.email;
  }

  save() {
    this.storageService.setItem('current_guest', this.toLocalStorage());
  }

  toLocalStorage() {
    const ls_guest = {};
    Object.keys(this).filter(k => k !== 'storageService' && k !== 'cookieService').forEach(k => ls_guest[k] = this[k]);
    return ls_guest;
  }

  hasValidSubscription(): boolean {
    return this.subscription_id && this.subscription_id !== -1;
  }
}
