import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormSelectComponent } from './select.component';

const routes: Routes = [
  { path: '', component: FormSelectComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormSelectRoutingModule { }
