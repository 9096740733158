import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetQrCodeComponent } from './qr_code.component';


const routes: Routes = [
  { path: '', component: WidgetQrCodeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetQrCodeRoutingModule { }
