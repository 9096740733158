export class Icon {
  size_16: string;
  size_32: string;
  size_36: string;
  size_60: string;
  size_72: string;
  size_76: string;
  size_96: string;
  size_114: string;
  size_120: string;
  size_144: string;
  size_152: string;
  size_180: string;
  size_192: string;
  size_512: string;

  constructor(raw_json: any) {
    this.size_16 = raw_json?.size_16;
    this.size_32 = raw_json?.size_32;
    this.size_36 = raw_json?.size_36;
    this.size_60 = raw_json?.size_60;
    this.size_72 = raw_json?.size_72;
    this.size_76 = raw_json?.size_76;
    this.size_96 = raw_json?.size_96;
    this.size_114 = raw_json?.size_114;
    this.size_120 = raw_json?.size_120;
    this.size_144 = raw_json?.size_144;
    this.size_152 = raw_json?.size_152;
    this.size_180 = raw_json?.size_180;
    this.size_192 = raw_json?.size_192;
    this.size_512 = raw_json?.size_512;
  }
}
