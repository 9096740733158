<section id="modal" class="popup-ui active" *ngIf="open" [ngClass.update]="modalService.update" [ngClass.small]="modalService.a2hs">
  <div class="content clearfix">
    <div class="close-icon" (click)="close()" *ngIf="modalService.closeable"><i class="fas fa-times"></i></div>
    <div class="page-headline" *ngIf="modalService.title">
      <h2 [innerHtml]="modalService.title | translate"></h2>
    </div>

    <div class="inner">
      <div *ngIf="modalService.notificationState && modalService.showHint" class="info_box info">
        <i class="fas fa-info-circle"></i>
        <p translate>push.hint</p>
      </div>
      <div class="popup-text" *ngIf="modalService.message || modalService.files?.length">
        <ng-container *ngIf="modalService.addition">
          <h3 class="m-t-32" translate>{{ 'legal.additional' | translate:business }}</h3>
          <div [innerHtml]="modalService.addition"></div>
        </ng-container>

        <p [innerHtml]="modalService.message | translate"></p>

        <app-attachments [files]="modalService.files" [blank]="true" *ngIf="modalService.files?.length"></app-attachments>
        <app-widget-social *ngIf="show_social"></app-widget-social>
      </div>

      <div *ngIf="!modalService.component">
        <ng-container *ngIf="modalService.newsletter; else no_newsletter">
          <hr>
          <h3>{{modalService.title_newsletter}}</h3>
          <p [innerHtml]="modalService.description_newsletter"></p>
          <app-newsletter-form [modal]="modal"></app-newsletter-form>
        </ng-container>

        <ng-template #no_newsletter>
          <div class="button-grid" *ngIf="modalService.closeable">
            <a translate class="button light close" (click)="close()">misc.close</a>
          </div>
        </ng-template>
      </div>
      <ng-container #dynamicContent></ng-container>
    </div>
  </div>
</section>
<div id="curtain" (click)="close()" *ngIf="open"></div>

