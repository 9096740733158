import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { SuggestionsService } from './suggestions.service';

@Component({
  selector: 'app-widget-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class WidgetSuggestionsComponent implements OnInit {
  widget: any;
  length: Number = 0;
  type: String = '';

  @Input() origin: string;

  constructor(public globals: Globals,
              private api: ApiService,
              private suggestionsService: SuggestionsService) { }

  ngOnInit() {
    this.suggestionsService.getWidget().subscribe((success: any) => {
      this.widget = success.widget;
      if (this.widget?.length) {
        this.type = this.widget[0].type;
      }
    });
  }

}
